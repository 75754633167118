import React, { useCallback, useEffect, useState } from "react";
import {
  DropEvent,
  FileRejection,
  useDropzone
} from "react-dropzone";
import { pdfjs } from "react-pdf";
import { usePDF } from 'react-to-pdf';
import { KTIcon, toAbsoluteUrl } from "../../../_metronic/helpers";
import { getResumeInfo, processResume, resumeApproval, resumeAssignTo, resumeDocxDownload, resumeFeedback, savePdfPreview, uploadResume } from "../../api";
import { useAuth } from "../../modules/auth";
import ButtonRow from "../../modules/resumeformatting/ButtonRow";
import Header from "../../modules/resumeformatting/HeaderSection";
import ResumeLibrary from "../../modules/resumeformatting/ResumeLibrary";
import { UploadResumeForm } from "../../modules/resumeformatting/UploadResumeForm";
import ResumeTemplateFormatting from "../../modules/resumeformatting/resumeDownload/ResumeTemplateFormatting";
import FilePreview from "../../modules/resumeformatting/resumeValidate/FilePreview";
import ResultUI from "../../modules/resumeformatting/resumeValidate/ResultUI";
import { Spinner } from 'react-bootstrap'
import { useNavigate } from "react-router-dom";
import { resumeFileInfo } from "../../data/resumeDocumentLibrary";
import { ResumeDetails } from "../../modules/resumeformatting/ResumeFormattingModel";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

type Props = {};

type fileType = Array<string>;

const bgList = ["bg-success", "bg-primary", "bg-warning"];

type MyObject = {
  [key: string]: string[];
};

type StyleObject = {
  headingFontStyle: string,
  headingFontSize: string,
  contentFontStyle: string,
  contentFontSize: string
}

type ResumeDataObject = {
  file_original_name: string,
  process_date: string
  file_url: string,
  process_time: string,
  processed: number,
  processed_file_name: string,
  tag: string,
  upload_date: string,
  uploaded_file_url: string,
  useremail: string,
  assign_to: string,
  feedback: string,
  approval: string
}

const resumes = [
  {
    id: "forum_new",
    img: toAbsoluteUrl("/media/resume/foremresume.png")
  },
  {
    id: "thirdeye",
    img: toAbsoluteUrl("/media/resume/thirdeyeresume.png")
  },
  {
    id: "twocolumnresume",
    img: toAbsoluteUrl("/media/resume/vpresume.png")
  },
];

const fontSizes = ["10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23", "24"]
const fontStyles = ["Oswald", "Arial", "Lexend", "Lora", "Times New Roman", "Roboto", "Calibri", "EB Garamond", "Montesrrat", "Lato"]
interface TeamMemberObject {
  username: string;
  email: string;
  password: string;
  organizationId: number;
  type: string;
}

type CronJobReqObject = {
  scheduledTime: string;
  scheduleFreq: string;
  year?: number | null;
  month?: number | null;
  day?: number | null;
  dayOfWeek?: number | null;
  hours?: number | null;
  minutes?: number | null;
  seconds?: number | null;
  milliseconds?: number | null;
};

export default function ResumeFormat({ }: Props) {
  const { currentUser } = useAuth();
  const [loading, setLoading] = useState<boolean>(false);
  const [formatting, setFormatting] = useState<boolean>(false);
  const [fetching, setFetching] = useState<boolean>(false);
  const [preview, setPreview] = useState<string>('table');
  const [closeModal, setCloseModal] = useState<boolean>(false);
  const [selectedFilesUpload, setSelectedFilesUpload] = useState<File[]>([]);
  const [selectedFiles, setSelectedFiles] = useState<string[]>([]);
  const [pdfFile, setPdfFile] = useState<File>();
  const [file, setFile] = useState<File | null>(null);
  const [url, setUrl] = useState("");
  const [styleData, setStyleData] = useState<StyleObject>({
    headingFontStyle: 'Lato', // Initial value for headingFontStyle
    headingFontSize: '14',    // Initial value for headingFontSize
    contentFontStyle: 'Roboto', // Initial value for contentFontStyle
    contentFontSize: '11',    // Initial value for contentFontSize
  })
  const [resumeData, setResumeData] = useState<ResumeDetails[]>([])
  const [unResumeData, setUnResumeData] = useState<ResumeDetails[]>([])
  const [result, setResult] = useState<Array<MyObject>>([]);
  const [fileName, setFileName] = useState<Array<string>>([]);
  const [formatedResume, setFormatedResume] = useState<string>("")
  const [fileLink, setFileLink] = useState<string>()
  const [uploadedFileLink, setUploadedFileLink] = useState<string>()
  const [processedFileLink, setProcessedFileLink] = useState<string>()
  const [tab, setTab] = useState("processed");
  const [refresh, setRefresh] = useState<boolean>(false)
  const [processAction, setProcessAction] = useState<string | null>("")
  const [teams, setTeams] = useState<string[]>()
  const [feedback, setFeedback] = useState<string>()
  const [teamMember, setTeamMember] = useState<string>()
  const [pdfFileName, setPdfFileName] = useState<string>()
  const [tempDocLink, setTempDocLink] = useState<string>()
  const [template, setTemplate] = useState<string>('thirdeye')
  const [isApproval, setIsApproval] = useState<string>()
  const [charCount, setCharCount] = useState<number>(0);
  const [fileNameDownload, setFileNameDownload] = useState<string>('')
  const [tabButton, setTabButton] = useState<string>("processed")
  const [bulkTab, setBulkTab] = useState<boolean>(false)
  const [files, setFiles] = useState<string[]>([])
  const { toPDF, targetRef } = usePDF({ filename: `${fileNameDownload}_optira_generated_resume.pdf` });
  const [docs, setDocx] = useState(
    [
      {
        uri: "",
        fileType: "docx",
      }
    ]
  )
  const [resumeJson, setResumeJson] = useState({})
  const [docxDownloading, setDocxDownloading] = useState<boolean>(false);
  const [isPdfFile, setIsPdfFile] = useState<boolean>(true)
  const maxChars = 100;

  useEffect(() => {
    console.log("inside Resume Format", selectedFiles)
  }, [selectedFiles])



  const handleResumeProcess = (link: string) => {


    let fileList: Array<Object> = [];
    // console.log("inside handleprrecess",selectedFiles)
    if (link == '' && selectedFiles.length <= 0) {
      alert("please select any file to processed ")
      return;
    }
    if (link == '' && selectedFiles) {
      setBulkTab(true);

      selectedFiles.forEach((selectedFile) => {
        let fileObj = {
          fileLink: selectedFile
        };
        fileList.push(fileObj);
      });
    }

    setSelectedFiles(() => []); // Clear selected files


    if (link) {
      setFormatting(true);
      let tempSelectedFiles = selectedFiles;
      tempSelectedFiles.push(link);
      setSelectedFiles(tempSelectedFiles);
      let fileObj = {
        fileLink: link
      };
      fileList.push(fileObj);
    }

    console.log(fileList);
    processResume({
      files: fileList,
      username: currentUser?.username,
      email: currentUser?.email,
      formatType: "thirdeye",
      tag: "resume",
      // subscribed: currentUser?.subscribed
    }).then((res) => {
      if (res.status === 400) {
        // Check the response data and display it in an alert
        if (res.data && res.data.message) {
          alert(`Status 426 received: ${res.data.message}`);
        } else {
          alert('Status 426 received.');
        }
      } else {
        // If the status code is not 426, proceed with the usual logic
        // setResumeJson(res.data[0].data);
        // setProcessedFileLink(res.data[0].file_url);
        setSelectedFiles(() => [])
        setBulkTab(false);
        alert("Your Documents has been processed successfully")
        getResumeInfo({ email: currentUser?.email, username: currentUser?.username, tag: "resume" }).then((resProcessInfo) => {
          console.log(resProcessInfo.data.filter((item: any) => item.processed == 1));
          setResumeData(resProcessInfo.data.filter((item: any) => item.processed === 1));
          getResumeInfo({ email: currentUser?.email, username: currentUser?.username, tag: "resume" }).then((resUnProcessInfo) => {
            setUnResumeData(resUnProcessInfo.data.filter((item: any) => item.processed === 0));
            setFormatting(false);
            setFetching(false);
            setTab('processed');
          });
        });
        setFormatting(false);
      }
    }).catch((error) => {
      if (error.response) { // Check if error.response exists
        if (error.response.status === 426) {
          alert(error.response.data.data);
          setFormatting(false);
          setBulkTab(false);
        } else if (error.response.status === 413) {
          alert("Resume data limit exceeded. Please try another resume.");
          setFormatting(false);
          setBulkTab(false);
        } else if (error.response.status === 500) {
          alert(error.response.data.message);
          setFormatting(false);
          setBulkTab(false);
        } else {
          alert("Facing Network Issue. Please Try again.");
          setFormatting(false);
          setBulkTab(false);
        }
      } else {
        alert("Oops! Something went wrong.\n\nWe encountered an unexpected issue while processing this file. Please try again with different file. If the problem persists, contact our support team for assistance.");
        setFormatting(false);
        setBulkTab(false);
      }
    });
  }

  const handleBulkProcess = (link: string[]) => {

  }

  console.log(selectedFiles)
  // const handleUpload = (event: React.FormEvent) => {
  //   setLoading(true);
  //   setCloseModal(true)
  //   event.preventDefault();

  //   if (selectedFilesUpload.length > 0) {
  //     const formData = new FormData();
  //     let fileArr = [];
  //     selectedFilesUpload.forEach((file, index) => {
  //       formData.append("files", file, `${file.name}`);
  //     });
  //     formData.append(
  //       "username",
  //       currentUser ? currentUser.username : "Demo"
  //     );
  //     formData.append(
  //       "email",
  //       currentUser ? currentUser.email : "demo@thirdeyedata.ai"
  //     );
  //     formData.append("tag", "resume")

  //     // Use the formData object as needed, such as sending it via AJAX or API request

  //     uploadResume(formData)
  //       .then((response: any) => {
  //         setLoading(false);
  //         setUploadedFileLink(response.data[0].file_url)
  //         setFileLink(response.data[0].file_url)
  //         getResumeInfo({ email: currentUser?.email, username: currentUser?.username, tag: "resume" }).then((res) => {
  //           setUnResumeData(res.data.filter((item: any) => item.processed === 0))
  //           setFormatting(false)
  //           setFileName([])
  //           setRefresh(true)
  //           setSelectedFilesUpload([])
  //           setTab('unprocessed')
  //           setCloseModal(false)
  //         })
  //       })
  //       .then((data) => { })
  //       .catch((error) => {
  //         setLoading(false)
  //         setCloseModal(false)
  //         // Handle error
  //       });
  //     setCloseModal(false)
  //   }
  //   setCloseModal(false)

  // }
  //handle file change

  const handleUpload = (event: React.FormEvent) => {
    alert("Feature is not available for Guest User")
  }
  
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;

    if (files) {
      const fileList = Array.from(files);

      // Filter for supported types: PDF, DOC, and DOCX
      const supportedFiles = fileList.filter(file =>
        ['application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'].includes(file.type)
      );

      // Check for maximum number of files (up to 10)
      if (selectedFilesUpload.length + supportedFiles.length > 10) {
        alert("You can only upload up to 10 files.");
        return;
      }

      // Only proceed if there are supported files selected
      if (supportedFiles.length > 0) {
        // Get new files only if they aren't already in selected files
        const newFiles = supportedFiles.filter(file =>
          !selectedFilesUpload.some(selectedFile => selectedFile.name === file.name)
        );

        if (newFiles.length > 0) {
          const newFileNames = newFiles.map(file => file.name);

          // Append new files to existing selected files
          setFileName(prevFileNames => [...prevFileNames, ...newFileNames]);
          setSelectedFilesUpload(prevSelectedFiles => [...prevSelectedFiles, ...newFiles]);

          // Optionally set the first supported file as a separate value if needed
          setSelectedFilesUpload(prevSupportedFile => prevSupportedFile || newFiles[0] || null);
        } else {
          console.log('No new supported files were selected');
        }
      } else {
        console.log('No supported files were selected');
      }
      event.target.value = '';
    }
  };

  const handleRemoveFile = (fileToRemove: string) => {
    // Remove the file from the selected files and file names
    setFileName(prevFiles => prevFiles.filter(file => file !== fileToRemove));
    setSelectedFilesUpload(prevFiles => prevFiles.filter(file => file.name !== fileToRemove));
  };
  const handleReset = () => {
    setFileName([]); // Reset the file names
    // setSupportedFiles('');
    setSelectedFilesUpload([]);
    const fileInput = document.getElementById('file-upload') as HTMLInputElement; // Type assertion
    if (fileInput) {
      fileInput.value = ''; // Clear the file input
    }
  };

  const handleDocumentApproval = (approval: string) => {
    console.log(selectedFiles)
    if (selectedFiles.length > 0) {
      setProcessAction(approval)
      let files: Array<Object> = [];
      let fileUrl:string=''
      selectedFiles.forEach((selectedFile) => {
        fileUrl=selectedFile
        let fileObj = {
          file_url: selectedFile
          
        };
        files.push(fileObj);
      });
        const updatedFolder = resumeData?.map((file) => {
          // Check if the file matches the given URL
          if (file.file_url === fileUrl) {
            // Update the approval and folder properties based on approval value
            return {
              ...file,
              approval: approval,
              folder: approval === "accepted" ? "accepted" : "rejected",
            };
          }
          return file; // Return unchanged file for non-matching items
        });
    
        setResumeData(updatedFolder); // Update the state with modified data
        setTimeout(() => {
          setProcessAction(null); // Reset process action
          alert(`Your document have been ${approval}.`); // Show alert after the delay
        }, 800); 
      
    } else {
      alert("No file selected")
    }
  }

  const handleChange = (e: any) => {
    const currentCharCount = e.target.value.length;

    if (currentCharCount <= maxChars) {
      setFeedback(e.target.value);
      setCharCount(currentCharCount);
    } else {
      alert('Only ' + maxChars + ' characters are allowed.');
    }
  }


  const handleAssingMember = (e: any) => {
    e.preventDefault()
    if (selectedFiles.length > 0) {
      setProcessAction("assinging")
      let files: Array<Object> = [];
      selectedFiles.forEach((selectedFile) => {
        let fileObj = {
          file_url: selectedFile
        };
        files.push(fileObj);
      });
      resumeAssignTo({
        useremail: currentUser?.email,
        files: files,
        assignTo: teamMember
      }).then((res) => {
        setProcessAction(null)
        alert(res.message)
        setRefresh(true)
      })
    } else {
      alert("No file selected")
    }
  }

  const handleSubmitFeedback = (e: any) => {
    setFeedback(e.target.value);
    setIsApproval('');
    if (selectedFiles.length > 0) {
      setProcessAction('rejected')
      let files: Array<Object> = [];
      selectedFiles.forEach((selectedFile) => {
        let fileObj = {
          file_url: selectedFile
        };
        files.push(fileObj);
      });
      // resumeApproval({
      //   useremail: currentUser?.email,
      //   files: files,
      //   approval: 'rejected',
      //   feedback: feedback ? feedback : "",
      //   userName: currentUser?.username,
      //   tag: "resume",
      // }).then((res) => {
      //   setProcessAction(null)
      //   alert(res.message)
      //   setRefresh(true)
      //   setIsApproval('rejected')
      // })
      handleDocumentApproval("rejected")
    } else {
      alert("No file selected")
    }
  }

  const handlePreview = async () => {
    setUploadedFileLink("")
    setProcessedFileLink("")
    setSelectedFiles([])
    setPreview("table");
  };


  const onDrop = useCallback(
    (
      acceptedFiles: File[],
      fileRejections: FileRejection[],
      event: DropEvent
    ) => {
      // Define supported file types
      const supportedTypes = [
        'application/pdf',
        'application/msword', // DOC files
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document' // DOCX files
      ];

      // Filter accepted files to include only supported types
      const validFiles = acceptedFiles.filter(file =>
        supportedTypes.includes(file.type)
      );

      if (validFiles.length > 10) {
        alert('You can only upload up to 10 files.')
        return
      }
      if (validFiles.length > 0) {
        // Handle valid files here
        const tempFileNames: string[] = validFiles.map(file => file.name);
        setFileName([...tempFileNames]);

        // Set the first valid file
        const selectedFile = validFiles[0];
        setPdfFile(selectedFile);
        setFile(selectedFile); // This may be used for any specific file handling

        // Set all selected valid files
        setSelectedFilesUpload(validFiles);
      } else {
        console.log('No supported files were uploaded.');
      }

      // Optional: Handle file rejections if necessary
      if (fileRejections.length > 0) {
        fileRejections.forEach(({ file, errors }) => {
          console.error(`File ${file.name} was rejected: ${errors.map(e => e.message).join(', ')}`);
        });
      }
    },
    [] // Include dependencies if necessary
  );
  const compare = (originalFileName: string, uploadedFileLink: string, processedFileLink: string, output: JSON, approval: string) => {

    setIsApproval(approval)

    let trimmedFileName = originalFileName.replace(/\.[^/.]+$/, '')
    setFileNameDownload(trimmedFileName)
    setUploadedFileLink(uploadedFileLink);
    setProcessedFileLink(processedFileLink);
    let tempSelectedFiles = [];
    tempSelectedFiles.push(uploadedFileLink)
    setSelectedFiles(tempSelectedFiles)
    console.log(originalFileName)

    if (originalFileName.toLowerCase().endsWith('.pdf')) {
      savePdfPreview(
        {
          "original_file_name": originalFileName,
          "uploaded_file_url": uploadedFileLink,
          "useremail": currentUser?.email,
          "username": currentUser?.username,
          "tag": "resume",
        }
      ).then((res) => {
        setPdfFileName(originalFileName)
        // setPdfFileName(res.filename)
        console.log(res.file_url)
        // setTempDocLink(res.file_url)
      })
    }
    setResumeJson(output)
    setPreview("compare")
  }


  const download = (output: JSON, uploadedFileUrl: string, originalFileName: string) => {
    let trimmedFileName = originalFileName.replace(/\.[^/.]+$/, '');
    console.log(trimmedFileName)
    setFileNameDownload(trimmedFileName)
    setSelectedFiles([uploadedFileUrl])
    setUploadedFileLink(uploadedFileUrl);
    setResumeJson(output)
    setPreview("download")
  }

  const handledownloadDocx = () => {

    console.log({ output: resumeJson, template: template, fileUrl: uploadedFileLink });
    setDocxDownloading(true);
    resumeDocxDownload({ username: currentUser?.username, output: resumeJson, template: template, fileUrl: uploadedFileLink })
      .then((res) => {
        if (res.data.docx_url) {
          setDocxDownloading(false);
          window.open(res.data.docx_url, '_blank'); // Open the URL in a new tab
        } else {
          console.error('No document URL received.');
        }
        console.log(res);
      })
      .catch((error) => {
        console.error('Failed to download document:', error);
      });
  }

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      'application/pdf': [],    // Accept PDF files
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document': [], // Accept DOCX files
      'application/msword': [],  // Accept DOC files
    },
    noKeyboard: true,
    noClick: true,
  });

  const handleSetLink = (link: string) => {
    setFileLink(link)
  }

  useEffect(() => {
    setFileNameDownload('')
    if (url != "") {
      setPdfFile(undefined);

      setFileName([]);
      setResult([]);
      let fileInput = window.document.getElementById("file-input");
    }

    if (pdfFile) {
      setUrl("");
    }
  }, [url, pdfFile]);

  useEffect(() => {
    setFetching(true)
    // getResumeInfo({ email: currentUser?.email, username: currentUser?.username, tag: "resume" }).then((res) => {
    //   setResumeData(res.data.filter((item: any) => item.processed === 1))
    //   // setFormatting(false)
    //   getResumeInfo({ email: currentUser?.email, username: currentUser?.username, tag: "resume" }).then((res) => {
    //     setUnResumeData(res.data.filter((item: any) => item.processed === 0))
    //     setFormatting(false)
    //     setFetching(false)
    //   })
    // })
    setResumeData(resumeFileInfo.data)
    setFormatting(false);
    setFetching(false);
  }, [])

  useEffect(() => {
    if (refresh) {
      setFetching(true)
      setResumeData([])
      setUnResumeData([])
      // getResumeInfo({ email: currentUser?.email, username: currentUser?.username, tag: "resume" }).then((res) => {
      //   setResumeData(res.data.filter((item: any) => item.processed === 1))
      //   getResumeInfo({ email: currentUser?.email, username: currentUser?.username, tag: "resume" }).then((res) => {
      //     setUnResumeData(res.data.filter((item: any) => item.processed === 0))
      //     setFormatting(false)
      //     setFetching(false)
      //     setRefresh(false)
      //   })
      // })
      setResumeData(resumeFileInfo.data)
      setFormatting(false);
      setFetching(false);
      setRefresh(false)
    }
  }, [refresh])

  // useEffect(() => {
  //   resumeMove({ "useremail": currentUser?.email, "username": currentUser?.username, "tag": "resume" }).then((res) => {
  //     // alert("Resume uploaded to Azure") 
  //   })
  // })

  useEffect(() => {
    if (closeModal) {
      let tempArr: string[] = []
      setFileName([])
      setSelectedFilesUpload([])
    }
  }, [closeModal])

  // useEffect(() => {
  //   getAllMember({ organizationId: currentUser?.organizationId }).then((res) => {
  //     //  Filtering out emails of objects with type !== 'superadmin'
  //     const emails = res.data
  //       .filter((user: TeamMemberObject) => user.type !== 'superadmin')
  //       .map((user: TeamMemberObject) => user.email);
  //     setTeams(emails)
  //   });
  // }, [])

  useEffect(() => {
    if (fileLink?.toLowerCase().endsWith(".pdf")) {
      savePdfPreview(
        {
          "original_file_name": "TempFile.pdf",
          "uploaded_file_url": fileLink,
          "useremail": currentUser?.email,
        }
      ).then((res) => {
        setPdfFileName(res.fileName)
      })
    }
  }, [fileLink])

  return (
    <div>
      {/* Upload Document */}
      <div
        className="modal fade"
        id="kt_modal_assosiate_student"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered mw-650px">
          <div className="modal-content">
            <div className="modal-header">
              <h2 className="fw-bolder">Upload Resume</h2>
              <div
                className="btn btn-sm btn-primary text-dark"
                data-bs-dismiss="modal"
                onClick={(e) => { setCloseModal(true) }}
              >
                <KTIcon iconName="cross" className="fs-1" />
              </div>
            </div>
            <div className="modal-body scroll-y mx-5 mx-xl-15 my-7">
              {currentUser?.usertype == 'superadmin' ?
                <>
                  <form
                    className="form w-100 fv-plugins-bootstrap5 fv-plugins-framework mb-5"
                    noValidate
                    id="kt_login_signup_form"
                  >
                    <div
                      className={loading ? 'd-flex align-items-center justify-content-center' : 'd-none'}
                      style={{
                        width: '100vw',
                        height: '100vh',
                        zIndex: 100,
                        backgroundColor: '#5e627861',
                        position: 'fixed',
                        top: 0,
                        left: 0,
                      }}
                    >
                      <Spinner animation="border" role="status" variant="light" style={{ width: '3rem', height: '3rem' }}>
                        <span className="visually-hidden">Loading...</span>
                      </Spinner>
                    </div>
                    <div
                      {...getRootProps()}
                      style={{
                        border: '2px dashed',
                        padding: '40px 20px', // Adjust padding for a more flexible design
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        minHeight: '150px', // Set a minimum height for flexibility
                        width: '100%', // Full width to accommodate more files
                        marginBottom: '20px',
                        overflow: 'hidden',
                        maxHeight: '375px', // Set a maximum height for vertical scrolling
                        maxWidth: '100%',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                      }}
                    >
                      <input {...getInputProps()} />
                      {isDragActive ? (
                        <p>Drop the files here...</p>
                      ) : (
                        <p>
                          {fileName.length <= 0 ? (
                            <>
                              <span className="fw-bold text-dark">

                                Please upload your file here. (Only PDF,Docs,Docx files are supported.)</span>
                              <input
                                type='file'
                                accept='.pdf, .doc, .docx'
                                onChange={handleFileChange}
                                style={{ display: 'none' }} // Hide the default file input
                                id='file-upload'
                                multiple // Allow multiple file selection
                              />
                              <div className="d-flex justify-content-center">
                                <label htmlFor='file-upload' className='btn btn-primary btn-sm'>
                                  Browse
                                </label>
                              </div>
                            </>
                          ) : (
                            <>
                              <div className="d-flex flex-column">
                                {fileName.map((file: string) => (
                                  <div className="badge badge-primary m-1">
                                    <KTIcon iconName="file" className="fs-3" /> &nbsp; {file.length > 20 ? `${file.slice(0, 20)}...` : file}

                                    <button type='button' onClick={() => handleRemoveFile(file)} className='btn-close'>
                                      <KTIcon iconName='close' className='fs-5' />
                                    </button>
                                  </div>
                                ))}
                              </div>
                              <input
                                type='file'
                                accept='.pdf, .doc,.docx'
                                onChange={handleFileChange}
                                style={{ display: 'none' }} // Hide the default file input
                                id='file-upload'
                                disabled={loading}
                                multiple // Allow multiple file selection
                              />
                              <div className="d-flex justify-content-center">
                                <label htmlFor="file-upload" className='btn btn-primary btn-sm' >
                                  Browse
                                </label>
                              </div>
                            </>
                          )}
                        </p>
                      )}
                    </div>
                  </form>
                  {/* <div className='separator separator-content my-14'> */}
                  {/* <span className='w-125px text-gray-500 fw-semibold fs-7'>Or</span> */}
                  {/* </div> */}
                  {/* <div className='row g-3 mb-9'> */}
                  {/* <div className='col-md-12'>
                      <UploadResumeForm text={"Connect to External Data Source..."} source={true} />
                    </div>  */}
                  {/* </div> */}
                  {/* begin::Form group */}
                  <div className="text-center mt-5">
                    <button
                      type="submit"
                      id="kt_sign_up_submit"
                      className="btn btn-lg btn-success w-100 mb-5"
                      data-bs-dismiss="modal"
                      onClick={handleUpload}
                      disabled={selectedFilesUpload.length > 0 ? false : true}
                    >
                      {!loading && <span className="indicator-label">Submit</span>}
                      {loading && (
                        <span className="indicator-progress" style={{ display: "block" }}>
                          Please wait...{" "}
                          <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                        </span>
                      )}
                    </button>
                    <button type='button' className='btn btn-lg btn-secondary text-white w-100 mb-5 ' onClick={handleReset}>
                      Reset
                    </button>
                  </div>
                  {/* end::Form group */}
                </> :
                <p className="fs-1 fw-bold text-center">Feature Disabled</p>
              }
            </div>
          </div>
        </div>
      </div>

      {/* Assign Team */}
      <div
        className="modal fade"
        id="kt_modal_assign"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered mw-650px">
          <div className="modal-content">
            <div className="modal-header">
              <h2 className="fw-bolder">Assign Document to Team Member</h2>
              <div
                className="btn btn-sm btn-icon btn-active-color-primary"
                data-bs-dismiss="modal"
              >
                <KTIcon iconName="cross" className="fs-1" />
              </div>
            </div>
            <div className="modal-body scroll-y p-2">
              <div
                className={`card card-xl-stretch mb-2 px-2`}
                style={{ maxHeight: "60vh", overflowY: "scroll" }}
              >
                <div className="row">
                  <div className="col-12">
                    <div className="row mb-6 align-items-center">
                      <label className="col-lg-4 col-form-label fw-bold fs-4 mt-5">
                        <span className="">Team Member</span>
                      </label>

                      <div className="col-lg-8 fv-row">
                        <small className="px-2 fs-8">Select team member who will process or validate the document</small>

                        {
                          teams &&
                          <select
                            className="form-select form-select-solid"
                            data-kt-select2="true"
                            data-placeholder="Select option"
                            data-allow-clear="true"
                            value={teamMember}
                            onChange={(e) => { setTeamMember(e.target.value) }}
                          >
                            <option value="">---Assign Team Member---</option>
                            {teams.map((team) => (
                              <option value={team}>{team}</option>
                            ))}
                          </select>
                        }
                      </div>
                    </div>
                    {/* <div className="row mb-6">
                      <label className="col-lg-4 col-form-label fw-bold fs-6">
                        <span className="">Notify Users After</span>
                      </label>

                      <div className="col-lg-8 fv-row">
                        <input
                          type="text"
                          // className="form-select form-select-solid"
                          className='form-control'
                          placeholder='Time'
                        />
                        <small className="px-2 fs-8">Send notification email after specified reviewing hours</small>
                      </div>
                    </div> */}
                  </div>
                </div>

              </div>
              <div className="my-2 px-2 text-center d-flex justify-content-end">
                <button
                  className="btn btn-lg btn-success"
                  data-bs-dismiss="modal"
                  onClick={(e) => handleAssingMember(e)}
                >
                  Assign
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Provide Feedback */}
      <div
        className="modal fade"
        id="kt_modal_feedback"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered mw-650px">
          <div className="modal-content">
            <div className="modal-header">
              <h2 className="fw-bolder">Please Provide Feedback about the Processed Document</h2>
              <div
                className="btn btn-sm btn-icon btn-active-color-primary"
                data-bs-dismiss="modal"
              >
                <KTIcon iconName="cross" className="fs-1" />
              </div>
            </div>
            <div className="modal-body scroll-y p-2">
              <div
                className={`card card-xl-stretch mb-2 px-2`}
                style={{ maxHeight: "60vh", overflowY: "scroll" }}
              >
                <div className="row">
                  <div className="col-12">
                    <div className="row">
                      {/* <label className="col-12 col-form-label fw-bold fs-2">
                        <span className="px-1">Feedback</span>
                      </label> */}

                      <div className="col-12 fv-row">
                        {/* <small className="px-1 fs-8 mb-1">Based on the extracted data provide feedback</small> */}
                        <textarea
                          value={feedback}
                          onChange={(e) => handleChange(e)}
                          className='form-control form-control-solid mb-2'
                          rows={3}
                          placeholder='Type your comments here' />
                        <h5>{maxChars - charCount} Characters remaining</h5>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
              <div className="my-2 px-2 text-center d-flex justify-content-end">
                <button
                  className="btn btn-lg btn-success"
                  data-bs-dismiss="modal"
                  onClick={(e) => handleSubmitFeedback(e)}
                >
                  Give Feedback
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="mx-2 mb-xl-8" style={{ borderRadius: '0px' }}>
        <Header title={"Resumes Library"} />

        {/* begin: Btn Row */}
        <ButtonRow
          toPDF={toPDF}
          preview={preview}
          loading={loading}
          currentUserEmail={currentUser?.email}
          fileLink={uploadedFileLink} // Assuming fileLink is available in your main component
          template={template}
          handlePreview={handlePreview}
          handleResumeProcess={handleResumeProcess}
          handledownloadDocx={handledownloadDocx}
          docxDownloading={docxDownloading}
          tabButton={tabButton}
          formating={formatting}
          selectedFiles={selectedFiles}
          bulkTab={bulkTab}



        />

        {/* end :: Btn Row */}

        {
          (() => {
            switch (preview) {
              case 'table':
                return <ResumeLibrary
                  targetRef={targetRef}
                  toPDF={toPDF}
                  tabProp={tab}
                  resumeData={resumeData}
                  unResumeData={unResumeData}
                  handleSetLink={handleSetLink}
                  fetching={fetching}
                  formatting={formatting}
                  processAction={processAction}
                  handleResumeProcess={handleResumeProcess}
                  compare={compare}
                  download={download}
                  setRefresh={setRefresh}
                  handleDocumentApproval={handleDocumentApproval}
                  setSelectedFiles={setSelectedFiles}
                  setTabButton={setTabButton}
                  bulkTab={bulkTab}
                  setTab={setTab}

                />
              case 'compare':
                return <div className="card-body pt-5">
                  <div className="row d-flex justify-content-between position-relative">

                    {/* File Preview UI */}
                    <FilePreview
                      docLink={tempDocLink}
                      uploadedFileLink={uploadedFileLink}
                      pdfFileName={pdfFileName}
                      loading={loading}
                    />

                    {/* Result UI */}
                    <ResultUI
                      preview={preview}
                      selectedFiles={selectedFiles}
                      processAction={processAction}
                      toPDF={toPDF}
                      targetRef={targetRef}
                      resumeJson={resumeJson}
                      formatting={formatting}
                      processedFileLink={processedFileLink}
                      isApproval={isApproval}
                      handleDocumentApproval={handleDocumentApproval} // Pass handleDocumentApproval prop
                    />
                  </div>
                </div>
              case 'download':
                return <div className="card-body pt-5">
                  <div className="row d-flex justify-content-between position-relative">

                    <ResumeTemplateFormatting
                      template={template}
                      setTemplate={setTemplate}
                      preview={preview}
                      selectedFiles={selectedFiles}
                      processAction={processAction}
                      toPDF={toPDF}
                      targetRef={targetRef}
                      resumeJson={resumeJson}
                      formatting={formatting}
                      processedFileLink={processedFileLink}
                      handleDocumentApproval={handleDocumentApproval}
                    />

                  </div>
                </div>
            }
          })()
        }
      </div>
    </div >
  );
}
