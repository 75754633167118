/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from "clsx";
import { ToastContainer, toast } from 'react-toastify';
// import 'talkify-tts';
// @ts-ignore
import Speech from 'react-speech';
import 'react-toastify/dist/ReactToastify.css';
import { FC, useEffect, useState } from "react";
import { custom_chat_gpt_demo, updateFeedback } from "../../../app/api";
import { useAuth } from "../../../app/modules/auth";
import { invoiceQuestionAnswer,
  bankStatementQuestionsAndAnswers,
  handwrittenFileQuestionsAndAnswers,
  voucherFileQuestionsAndAnswers,
  receiptQuestionsAndAnsers}
from "./QuestionAndAnswer";
import {
  MessageModel,
  UserInfoModel,
  defaultMessages,
  defaultUserInfos,
  toAbsoluteUrl,
  KTIcon
} from "../../helpers";
import { PiMicrophone, PiStopCircleFill } from "react-icons/pi";
import { HiSpeakerWave } from "react-icons/hi2";
import { createSpeechlySpeechRecognition } from '@speechly/speech-recognition-polyfill';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';

type Props = {
  isDrawer?: boolean;
  datasource?: string;
  clearButton?: boolean;
  setClear?: React.Dispatch<React.SetStateAction<boolean>>;
};



const style = {
  play: {
    button: {
      width: '28',
      height: '28',
      cursor: 'pointer',
      pointerEvents: 'none',
      outline: 'none',
      backgroundColor: 'yellow',
      border: 'solid 1px rgba(255,255,255,1)',
      borderRadius: 6
    },
  }
};

let bufferMessages = defaultMessages;

const ChatInner: FC<Props> = ({ isDrawer = false, datasource, clearButton, setClear }) => {
  const { currentUser } = useAuth();
  const [chatUpdateFlag, toggleChatUpdateFlat] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");
  const [messages, setMessages] = useState<MessageModel[]>(bufferMessages);
  const [userInfos] = useState<UserInfoModel[]>(defaultUserInfos);
  const [aiResponse, setAiResponse] = useState("");
  const [processing, setProcessing] = useState(false)
  const [regenrate, setRegenrate] = useState(false)
  const [dislikeReason, setDislikeReason] = useState(false)
  const [currMessage, setCurrMessage] = useState<number>()
  const [currentSpeech, setCurrentSpeech] = useState<SpeechSynthesisUtterance | null>(null);
  const [currentReadingId, setCurrentReadingId] = useState<number | null>(null);
  const [showFirstSuggestion, setShowFirstSuggestion] = useState(true);

  console.log(datasource)
 

  const [randomQuestions, setRandomQuestions] = useState<any[]>([]);
  const [previousQuestions, setPreviousQuestions] = useState<any[]>([]);

  // Function to get 3 random questions
  const getRandomQuestions = () => {

    console.log("called")
    if (!datasource || datasource == "") {
      setRandomQuestions([]);
      setPreviousQuestions([]);
      return
    }
    // Filter out the previously selected questions
    let availableQuestions: any[] = [];
    if (datasource == "invoice") {
      availableQuestions = invoiceQuestionAnswer.filter(
        (qa) => !previousQuestions.some((prevQa) => prevQa.id === qa.id) // Compare by ID
      );
    } else if (datasource == "bankstatement") {
      availableQuestions = bankStatementQuestionsAndAnswers.filter(
        (qa) => !previousQuestions.some((prevQa) => prevQa.id === qa.id) // Compare by ID
      );
    }else if (datasource == "handwritten") {
      availableQuestions = handwrittenFileQuestionsAndAnswers.filter(
        (qa) => !previousQuestions.some((prevQa) => prevQa.id === qa.id) // Compare by ID
      );
    }else if (datasource=="voucher"){
      availableQuestions=voucherFileQuestionsAndAnswers.filter(
        (qa)=> !previousQuestions.some((prevQa)=>prevQa.id===qa.id)
      )
    }else if(datasource=="receipt"){
      availableQuestions=receiptQuestionsAndAnsers.filter(
        (qa)=> !previousQuestions.some((prevQa)=>prevQa.id===qa.id)
      )
    }

    console.log("available Questions", availableQuestions)
    if (availableQuestions.length === 0) {
      // If there are no available questions left (i.e., all questions were previously shown),
      // reset the previous questions or handle it as you prefer.
      setPreviousQuestions([]);
      return; // Exit to prevent running the rest of the logic
    }

    // Shuffle and take 3 new random questions
    const shuffledQuestions = [...availableQuestions]
      .sort(() => 0.5 - Math.random()) // Randomly shuffle the available questions
      .slice(0, 3); // Take the first 3 after shuffle

    console.log("shuffuled question", shuffledQuestions)
    // Update the state for random questions
    setRandomQuestions(shuffledQuestions);

    console.log(randomQuestions)
    // Add these questions to the previous questions to avoid showing them again immediately
    setPreviousQuestions((prev) => [
      ...prev,
      ...shuffledQuestions, // Store full objects instead of just the question text
    ]);
  };

  useEffect(() => {
    // Reset chat to default messages
    setMessages([...defaultMessages]);
    bufferMessages = [...defaultMessages];

    // Clear suggestions and reset previous questions
    setRandomQuestions([]);
    setPreviousQuestions([]);

    console.log(messages)
    // Fetch new random questions after resetting
    if (datasource) {
      setTimeout(() => getRandomQuestions(), 0); // Delay ensures state updates propagate
    }
  }, [datasource]);

  useEffect(() => {
    if (clearButton) {
      // Reset chat to default messages
      setMessages([...defaultMessages]);
      bufferMessages = [...defaultMessages];

      // Clear suggestions and reset previous questions
      setRandomQuestions([]);
      setPreviousQuestions([]);


      if (setClear) {
        setClear(false)
        getRandomQuestions();
      }
    }
  }, [clearButton])

  let messageCounter = 2;
  const handleQuestionClick = (id: number, question: string, answer: string) => {
    // Add the user's question to the chat
    const userMessage: MessageModel = {
      id: id,
      user: 2,
      type: "out",
      text: question,
      time: ""
    };

    bufferMessages.push(userMessage);
    setMessages([...bufferMessages]);
    setMessage("");

    // Add a "Processing..." placeholder
    const processingMessage: MessageModel = {
      id: 101,
      user: 0,
      type: "in",
      text: '<img src="https://www.kentuckypower.com/lib/images/company/loading_inline_dots.gif" height="12" />',
      time: ""
    };

    bufferMessages.push(processingMessage);
    setMessages([...bufferMessages]);

    // Replace "Processing..." with the actual bot response after 2 seconds
    setTimeout(() => {
      // Remove the "Processing..." message
      bufferMessages.pop();

      // Add the bot's actual response
      const responseMessage: MessageModel = {
        id: id,
        user: 0,
        type: "in",
        text: answer,
        time: ""
      };

      bufferMessages.push(responseMessage);
      setMessages([...bufferMessages]);

      // Fetch new random questions after one is answered
      getRandomQuestions();
      messageCounter++;
    }, 2000); // 2-second delay
  };




  const handleDislike = (id: number) => {
    setDislikeReason(true)
    console.log(id)
    setCurrMessage(id)
  }

  const handleFeedback = (feedback: string) => {
    setDislikeReason(false)
    updateFeedback({
      "chat_log_id": currMessage,
      "feedback": feedback
    }).then((res) => {
      alert("Thanks for your feedback");
    }).catch((e) => {
      alert("Network error")
    })
  }

  const stripHtmlTags = (html: string): string => {
    const tempElement = document.createElement('div');
    tempElement.innerHTML = html;
    return tempElement.textContent || tempElement.innerText || '';
  };

  const handleCopy = (id: number) => {

    const textToCopy: string = messages.reduce((accumulator, message) => {
      if (message.type === 'in' && message.id == id) {
        accumulator += stripHtmlTags(message.text);
      }
      return accumulator;
    }, '');

    navigator.clipboard.writeText(textToCopy)
      .then(() => {
        notify()
      })
      .catch(err => {
        console.error('Failed to copy text: ', err);
      });
  }

  console.log("message", messages)
  const startSpeech = (id: number) => {

    console.log("id", id)
    // Your code to retrieve text based on the message ID
    setCurrentReadingId(id)
    // Check if SpeechSynthesis is supported by the browser
    if ('speechSynthesis' in window) {
      // Stop current speech if it's playing
      if (currentSpeech) {
        speechSynthesis.cancel();
      }

      const textToSpeak: string = messages.reduce((accumulator, message) => {
        if (message.type === 'in' && message.id === id) {
          accumulator += stripHtmlTags(message.text);
        }
        return accumulator;
      }, '');

      const speech = new SpeechSynthesisUtterance(textToSpeak);
      speech.lang = 'en-GB'; // Set language to British English

      // Get available voices
      const voices = speechSynthesis.getVoices();

      // Find a voice with British English language (lang: 'en-GB')
      const voice = voices.find(voice => voice.lang === 'en-GB') || null;

      // Set the voice for speech synthesis
      speech.voice = voice;

      // Speak the text
      speechSynthesis.speak(speech);

      // Store the current speech instance
      setCurrentSpeech(speech);
    } else {
      console.error('Speech synthesis is not supported by your browser.');
    }
  };

  // Function to stop speech synthesis
  const stopSpeech = () => {
    if (currentSpeech) {
      speechSynthesis.cancel();
      setCurrentSpeech(null);
    }
    setCurrentReadingId(null);
  };

  const notify = () => {
    toast.success('Copied to Clipboard!', {
      position: "bottom-left",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
  };

  const sendMessage = () => {
    const newMessage: MessageModel = {
      id: 0,
      user: 2,
      type: "out",
      text: message,
      time: ""
    };

    bufferMessages.push(newMessage);
    setMessages(bufferMessages);
    setMessage("");
    setProcessing(true)
    let messageFromBot: MessageModel = {
      id: 0,
      user: 0,
      type: "in",
      text: '<img src="https://www.kentuckypower.com/lib/images/company/loading_inline_dots.gif" height="12" />',
      time: ""
    };
    bufferMessages.push(messageFromBot);
    setProcessing(false)
    setMessages(() => bufferMessages);
    message !== "" &&
      custom_chat_gpt_demo({
        // info: sessionStorage.getItem('response'),
        json_data: sessionStorage.getItem('response'),
        search_text: message,
        username: currentUser?.username,
        dataSource: datasource
      }).then((res) => {
        console.log(res)
        toggleChatUpdateFlat(!chatUpdateFlag);
        setTimeout(() => {
          let messageFromBot: MessageModel = {
            user: 0,
            type: "in",
            text: res.data.summary,
            time: "",
            id: res.data.chat_log_id
          };
          bufferMessages.pop()
          bufferMessages.push(messageFromBot);
          setProcessing(false)
          setMessages(() => bufferMessages);
          toggleChatUpdateFlat((flag) => !flag);
        }, 800);
      }).catch((error) => {
        toggleChatUpdateFlat(!chatUpdateFlag);
        setTimeout(() => {
          let messageFromBot: MessageModel = {
            id: messageCounter,
            user: 0,
            type: "in",
            text: '<p style="color:red; border: 1px dashed; padding: 5px">Facing Network error please try again later<p>',
            time: ""
          };
          bufferMessages.pop()
          bufferMessages.push(messageFromBot);
          setProcessing(false)
          setMessages(() => bufferMessages);
          toggleChatUpdateFlat((flag) => !flag);
        }, 800);
      });
  };

  const regenerateMessage = (id: number) => {
    setCurrMessage(id)
    setRegenrate(true)
    // const textWithTypeOut: string = messages.reduce((accumulator, message) => {
    //   if (message.type === 'out' && message.id == id) {
    //     accumulator += message.text;
    //   }
    //   return accumulator;
    // }, '');
    // console.log(textWithTypeOut)

    custom_chat_gpt_demo({
      // info: sessionStorage.getItem('response'),
      json_data: sessionStorage.getItem('response'),
      search_text: '',
      username: currentUser?.username,
      dataSource: datasource,
      chat_log_id: id
    }).then((res) => {
      console.log(res)
      toggleChatUpdateFlat(!chatUpdateFlag);
      setTimeout(() => {
        let messageFromBot: MessageModel = {
          id: res.data.chat_log_id,
          user: 0,
          type: "in",
          text: res.data.summary,
          time: ""
        };
        bufferMessages.pop()
        bufferMessages.push(messageFromBot);
        setProcessing(false)
        setRegenrate(false)
        setMessages(() => bufferMessages);
        toggleChatUpdateFlat((flag) => !flag);
      }, 800);
    }).catch((error) => {
      toggleChatUpdateFlat(!chatUpdateFlag);
      setTimeout(() => {
        let messageFromBot: MessageModel = {
          id: 0,
          user: 0,
          type: "in",
          text: "<p>Facing Network error please try again later<p>.",
          time: ""
        };
        // bufferMessages.pop()
        bufferMessages.push(messageFromBot);
        setProcessing(false)
        setRegenrate(false)
        setMessages(() => bufferMessages);
        toggleChatUpdateFlat((flag) => !flag);
      }, 800);
    });
  }

  const onEnterPress = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    // if (e.keyCode === 13 && e.shiftKey === false) {
    //   e.preventDefault();
    //   sendMessage();
    // }
    alert("This feature is unavailable in demo version")
    return
  };

  const {
    transcript,
    interimTranscript,
    finalTranscript,
    listening,
    browserSupportsSpeechRecognition
  } = useSpeechRecognition();
  const startListening = () => SpeechRecognition.startListening({ continuous: true });

  if (!browserSupportsSpeechRecognition) {
    return <span>Browser doesn't support speech recognition.</span>;
  }

  return (
    <div
      className="card-body"
      id={isDrawer ? "kt_drawer_chat_messenger_body" : "kt_chat_messenger_body"}
    >
      <ToastContainer />
      <div
        className={clsx("scroll-y me-n5 pe-5", {
          "h-400px mh-350px": !isDrawer
        })}
        data-kt-element="messages"
        data-kt-scroll="true"
        data-kt-scroll-activate="{default: false, lg: true}"
        data-kt-scroll-max-height="auto"
        data-kt-scroll-dependencies={
          isDrawer
            ? "#kt_drawer_chat_messenger_header, #kt_drawer_chat_messenger_footer"
            : "#kt_header, #kt_app_header, #kt_app_toolbar, #kt_toolbar, #kt_footer, #kt_app_footer, #kt_chat_messenger_header, #kt_chat_messenger_footer"
        }
        data-kt-scroll-wrappers={
          isDrawer
            ? "#kt_drawer_chat_messenger_body"
            : "#kt_content, #kt_app_content, #kt_chat_messenger_body"
        }
        data-kt-scroll-offset={isDrawer ? "0px" : "5px"}
        style={{ marginBottom: '20px', display: 'flex', flexDirection: 'column-reverse', overflow: 'auto' }}
      >
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          {messages.map((message, index) => {
            const userInfo = userInfos[message.user];
            const state = message.type === "in" ? "info" : "primary";
            const templateAttr = {};
            if (message.template) {
              Object.defineProperty(templateAttr, "data-kt-element", {
                value: `template-${message.type}`
              });
            }
            const contentClass = `${isDrawer ? "" : "d-flex"} justify-content-${message.type === "in" ? "start" : "end"
              } mb-10`;
            return (
              <div
                key={`message${index}`}
                className={clsx("d-flex", contentClass, "mb-10", {
                  "d-none": message.template
                })}
                {...templateAttr}
              >
                <div
                  className={clsx(
                    "d-flex flex-column align-items",
                    `align-items-${message.type === "in" ? "start" : "end"}`
                  )}
                >
                  <div className="d-flex align-items-center mb-2">
                    {message.type === "in" ? (
                      <>
                        <div className="symbol  symbol-35px symbol-circle ">
                          <img
                            alt="Pic"
                            src={toAbsoluteUrl(`/media/${userInfo.avatar}`)}
                          />
                        </div>
                        <div className="ms-3">
                          <a
                            href="#"
                            className="fs-5 fw-bolder text-gray-900 text-hover-primary me-1"
                          >
                            Optira Bot
                          </a>
                          <span className="text-muted fs-7 mb-1">
                            {message.time}
                          </span>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="me-3">
                          <span className="text-muted fs-7 mb-1">
                            {message.time}
                          </span>
                          <a
                            href="#"
                            className="fs-5 fw-bolder text-gray-900 text-hover-primary ms-1"
                          >
                            You
                          </a>
                        </div>
                        <div className="symbol  symbol-35px symbol-circle ">
                          <img
                            alt="Pic"
                            src={toAbsoluteUrl(`/media/${userInfo.avatar}`)}
                          />
                        </div>
                      </>
                    )}
                  </div>

                  <div
                    className={clsx(
                      "p-5 rounded",
                      `bg-light-${state}`,
                      "fs-5",
                      "text-dark fw-bold mw-lg-800px",
                      `text-${message.type === "in" ? "start" : "end"}`
                    )}
                    data-kt-element="message-text"
                    // dangerouslySetInnerHTML={{__html: message.text}}
                    dangerouslySetInnerHTML={{ __html: message.text }}
                  ></div>


                  {
                    (messages.length > 3 && message.type === "in" && message.text.length != 98) &&
                    <div>
                      {/* <p>{message.text.length}</p> */}
                      <div className="d-flex flex-row justify-content-start pt-2">
                        {(currentReadingId === null || currentReadingId !== message.id) ?
                          <a onClick={() => startSpeech(message.id)} key={message.id}>
                            <HiSpeakerWave className="px-1" style={{ cursor: 'pointer' }} color="#565674" size={28} title="Read Aloud" />
                          </a>
                          : <a onClick={() => stopSpeech()} key={message.id}>
                            <PiStopCircleFill className="fs-2x px-1" style={{ cursor: 'pointer' }} color="red" title="stop" />
                          </a>
                        }
                        <a onClick={() => handleCopy(message.id)} title="Copy Text" ><KTIcon className="fs-2x px-1" iconName="copy" /></a>
                        {/* <div><KTIcon className="fs-2 px-1" iconName="arrow-circle-left" /></div> */}
                        {
                          index == (messages.length - 1) &&
                          <div>
                            {(currMessage == message.id && regenrate == true) ?
                              <span className="indicator-progress" style={{ display: "block" }}>
                                <span className="spinner-border align-middle fs-2"></span>
                              </span>
                              : <span className='menu-icon' onClick={(e) => {
                                alert("This feature is unavailable in the demo version.")
                                return
                              }}>
                                <KTIcon iconName='arrow-circle-left' className='fs-2x' />
                              </span>}
                          </div>
                        }
                        <a href="#" onClick={(e) => {
                          alert("This feature is unavailable in the demo version.")
                          return
                        }} title="Bad Response">
                          <KTIcon className="fs-2x px-1" iconName="dislike" />
                        </a>
                      </div>
                      <div>
                        {(currMessage == message.id && dislikeReason) &&
                          <div className="border border-2 border-dashed border rounded p-2">
                            <div className="d-flex justify-content-between">
                              <p>Tell me more : </p>
                              <a href="#" onClick={() => setDislikeReason(false)}>
                                <KTIcon className="fs-2 px-1" iconName="cross" />
                              </a>
                            </div>
                            <button className="btn btn-sm border border-2 border-dashed border rounded mx-1" onClick={() => { handleFeedback("Incorrect Source") }}>Incorrect Source</button>
                            <button className="btn btn-sm border border-2 border-dashed border rounded mx-1" onClick={() => { handleFeedback("Long Response Time") }}>Long Response Time</button>
                            <button className="btn btn-sm border border-2 border-dashed border rounded mx-1" onClick={() => { handleFeedback("Unnecessary Information Provided") }}>Unnecessary Information Provided</button>
                            <button className="btn btn-sm border border-2 border-dashed border rounded mx-1" onClick={() => { handleFeedback("Others") }}>Others</button>
                          </div>
                        }
                      </div>
                    </div>
                  }

                  {(messages.length == 3 || index == (messages.length - 1)) && message.type == 'in' &&
                    <div className="card p-3 mt-1 shadow-sm" style={{ maxWidth: '100%', margin: '0 auto' }}>
                      <ul className="list-unstyled d-flex flex-wrap justify-content-center p-0 gap-2">
                        {randomQuestions.length > 0 ? (randomQuestions.map((qa, index) => (
                          <li key={index} className="mb-2">
                            <button
                              onClick={() => handleQuestionClick(qa.id, qa.question, qa.answer)}
                              className="btn btn-secondary w-100 text-left p-3 rounded"
                              style={{ maxWidth: '100%', marginBottom: '10px' }}
                            >
                              {qa.question}
                            </button>
                          </li>
                        ))) : (
                          <p></p>
                        )
                        }
                      </ul>
                    </div>

                  }
                </div>
              </div>
            );
          })}
        </div>
      </div >

      <div
        className={clsx(
          "card-footer",
          `${isDrawer ? "position-fixed pt-2 p-3" : "p-0"}`
        )}
        style={{
          bottom: "0px",
          width: "-webkit-fill-available",
          display: "flex"
        }}
        id={
          isDrawer
            ? "kt_drawer_chat_messenger_footer"
            : "kt_chat_messenger_footer"
        }
      >
        <textarea
          className="form-control form-control-flush mb-3"
          rows={1}
          data-kt-element="input"
          placeholder="Type a message"
          value={listening ? transcript : message}
          disabled
          onChange={(e) => setMessage(e.target.value)}
          onKeyDown={onEnterPress}
        ></textarea>

        <div className="d-flex flex-stack">
          <div className="d-flex align-items-center me-2">
            {/* <button
              className="btn btn-sm btn-icon btn-active-light-primary me-1"
              type="button"
              data-bs-toggle="tooltip"
              title="Coming soon"
            >
              <i className="bi bi-paperclip fs-3"></i>
            </button>
            <button
              className="btn btn-sm btn-icon btn-active-light-primary me-1"
              type="button"
              data-bs-toggle="tooltip"
              title="Coming soon"
            >
              <i className="bi bi-upload fs-3"></i>
            </button> */}
          </div>
          <button
            className="btn btn-sm btn-success mx-2 fs-4"
            type="button"
            data-kt-element="send"
            disabled
            onClick={sendMessage}
          >
            Ask
          </button>
          {/* {
            messages.length > 3 &&
            < button
              className={`btn btn-sm btn-secondary me-3 w-60px fs-4`}
              type="button"
              data-kt-element="send"
              onClick={regenerateMessage}
            >
              {
                regenrate == true ?
                  <span className="indicator-progress" style={{ display: "block" }}>
                    <span className="spinner-border align-middle fs-4"></span>
                  </span>
                  : <span className='menu-icon'>
                    <KTIcon iconName='arrow-circle-left' className='fs-2' />
                  </span>
              }
            </button>
          } */}
          {/* < button
            className={`btn btn-sm btn-secondary me-3 w-60px fs-4`}
            type="button"
            data-kt-element="send"
          >
            {
              regenrate == true ?
                <span className="indicator-progress" style={{ display: "block" }}>
                  <span className="spinner-border align-middle fs-4"></span>
                </span>
                : <span className='menu-icon'>
                  <KTIcon iconName='arrow-circle-left' className='fs-2' />
                </span>
            }
          </button> */}
          {
            listening ?
              <button
                className="btn btn-sm btn-success mx-2 fs-4"
                onClick={SpeechRecognition.stopListening}
              >
                <PiMicrophone className="fs-2x px-1" />
              </button> :
              <button
                disabled
                className="btn btn-sm btn-danger mx-2 fs-4"
                onClick={() => SpeechRecognition.startListening({ continuous: true })}
              >
                <PiMicrophone className="fs-2x px-1" />
              </button>
          }
        </div>
      </div>
    </div >
  );
};

export { ChatInner };
function e(reason: any): PromiseLike<never> {
  throw new Error("Function not implemented.");
}