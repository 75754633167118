import axios from 'axios'
import { AuthModel, ForgotPasswordModel, ResponseModel, UserModel } from './_models'

const API_URL = process.env.REACT_APP_API_URL
// const API_URL = "https://apps.optira.ai:8000"
// const API_URL = "http://localhost:8000"

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/verify_token`
export const LOGIN_URL = `${API_URL}/login`
export const REGISTER_URL = `${API_URL}/register`
export const REQUEST_PASSWORD_URL = `${API_URL}/forgot_password/`
export const REQUEST_PASSWORD_CHANGE = `${API_URL}/verify_otp_for_password_reset/`

// Server should return AuthModel
export async function login(email: string, password: string) {
  // const response = await axios.post<ResponseModel>(`${API_URL}/login/`, {email, password})
  // return response.data

  const response = {
    "status": true,
    "data": {
      "username": "Demo",
      "email": "demo@thirdeyedata.ai",
      "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImRlbW9AdGhpcmRleWVkYXRhLmFpIiwiZXhwIjoxNzI5NTg3Mzk4fQ.-iXUFzT5rfGkITyaa0HWRzc4XbypKIs_QG9kQFJlbxs",
      "subscribed": "premium",
      "type": "superadmin",
      "document_service": 1,
      "resume_service": 1,
      "chatgpt_service": 1,
      "resume_search_service": 0,
      "otp_verified": 1
    },
    "message": "User found Successfully",
    "status_code": 200
  }
return response.data
}

// Server should return AuthModel
export function register(
  email: string,
  firstname: string,
  lastname: string,
  password: string,
  password_confirmation: string
) {
  return axios.post(REGISTER_URL, {
    email,
    first_name: firstname,
    last_name: lastname,
    password,
    password_confirmation,
  })
}

// Server should return object => { result: boolean } (Is Email in DB)
export async function requestPassword(email: string) {
  const response = await axios.post<ForgotPasswordModel>(REQUEST_PASSWORD_URL, {
    email,
  })
  if (response.data.status == true) {
    return response.data;
  } else {
    alert(response.data.message);
  }
}

//for verify otp and password change
export async function verifyOtpForPasswordReset(email: string, otp: string, new_password: string) {
  const response = await axios.post<ForgotPasswordModel>(REQUEST_PASSWORD_CHANGE, {
    email,
    otp,
    new_password
  })
  console.log(response.data.status);
  if (response.data.status == true) {
    return response.data;
  } else {
    alert(response.data.message);
  }
}

export async function getUserByToken(token: string) {
  const response = {
    status: true,
    data: {
      username: "Demo",
      id: 0,
      universityName: "",
      password: "",
      first_name: "",
      last_name: "",
      email: "demo@thirdeyedata.ai",
      usertype: "superadmin",
      token: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImRlbW9AdGhpcmRleWVkYXRhLmFpIiwiZXhwIjoxNzI5NTg3Mzk4fQ.-iXUFzT5rfGkITyaa0HWRzc4XbypKIs_QG9kQFJlbxs",
      organizationId: 0,
      document_service: 1,
      resume_service: 1,
      chatgpt_service: 1,
      resume_search_service: 0,
      organization_name: "TE",
      subscribed: "premium",
      otp_verified: 1,
    },
    message: "User Found Successfully",
    status_code: 200,
  };

  console.log(response);
  return response.data; // Return the user data
}
