import React, { useEffect, useState } from "react";
import { Blocks } from "react-loader-spinner";
import { useParams } from "react-router-dom";
import { utils, writeFile } from "xlsx";
import { KTIcon } from "../../../_metronic/helpers";
import { documentApproval, documentAssignTo, documentFeedback, filesByUser, getAllMember, processDocuments } from "../../api";
import { bankstatementFileInfo, handwrittenFileInfo, invoiceFileInfo, voucherFileInfo,receiptFileInfo, faxFileInfo } from "../../data/idpDocumentLibrary";
import { useAuth } from "../../modules/auth";
import { ButtonRow } from "../../modules/documentprocessing/ButtonRow";
import { DocumentLibraryView } from "../../modules/documentprocessing/documentlibrary/DocumentLibraryView";
import { UploadDocuments } from "../../modules/documentprocessing/documentlibrary/UploadDocuments";
import { FileDetails } from "../../modules/documentprocessing/DocumentProcessingModel";
import DocumentReviewView from "../../modules/documentprocessing/documentreview/DocumentReviewView";
import { HeaderSection } from "../../modules/documentprocessing/HeaderSection";
import Feedback from "../../modules/documentValidations/Feedback";
import AssignDocument from "../../modules/teams/AssignDocument";
// import { saveAs } from 'file-saver';

type Props = {};

type fileType = Array<string>;

interface TeamMemberObject {
  username: string;
  email: string;
  password: string;
  organizationId: number;
  type: string;
}

type MyObject = {
  [key: string]: string[];
};

export default function DocumentProcessingNew({ }: Props) {
  const { currentUser } = useAuth();
  const { doctype } = useParams()

  const [folder, setFolder] = useState<FileDetails[] | null>();
  const [selectedFiles, setSelectedFiles] = useState<string[]>([]);
  const [result, setResult] = useState<MyObject>({});
  const [resultNew, setResultNew] = useState<MyObject>({});
  const [resObj, setResObj] = useState<MyObject>({});
  const [viewtables, setViewTables] = useState<Array<MyObject>>([]);
  const [tables, setTables] = useState<Array<Array<MyObject>>>([]);
  const [tableHeader, setTableHeader] = useState<string[][]>([]);
  const [processing, setProcessing] = useState<boolean>(false);
  const [preview, setPreview] = useState<boolean>(false);
  const [fileName, setFileName] = useState<string>("");
  const [downlodingJson, setDownloadingJson] = useState<boolean>(false);
  const [responseJson, setResponseJson] = useState<Array<Array<Object>>>([])
  const [resultView, setResultView] = useState<string>("default")
  const [sasUrl, setSasUrl] = React.useState("");
  const [closeModal, setCloseModal] = useState(false);
  const [downloadFormat, setDownloadFormat] = useState('csv')
  const [respError, setRespError] = useState(false)
  const [refresh, setRefresh] = useState<boolean>(false)
  const [fileUrl, setFileUrl] = useState<string>()
  const [downloadType, setDownloadType] = useState<string>()
  const [teams, setTeams] = useState<string[]>()
  const [feedback, setFeedback] = useState<string>()
  const [teamMember, setTeamMember] = useState<string>()
  const [processAction, setProcessAction] = useState<string | null>(null)
  const [tabButton, setTabButton] = useState<string>("processed")
  const [isApproval, setIsApproval] = useState<string>()

  const handleFileInfo = (tag: string) => {
    console.log(tag)
    if (tag == "invoice") {
      setFolder(invoiceFileInfo.data)
    } else if (tag == "bankstatement") {
      setFolder(bankstatementFileInfo.data)
    } else if (tag == "handwritten") {
      console.log(tag)
      setFolder(handwrittenFileInfo.data)
    }else if(tag=="voucher"){
      setFolder(voucherFileInfo.data)
    } else if(tag=='receipt'){
      setFolder(receiptFileInfo.data)
    } else if(tag=='fax'){
      setFolder(faxFileInfo.data)
    }
    else {
      setFolder([])
    }
  }

  const handleDownload = async (format: string, fileUrl?: string) => {
    if (fileUrl) {
      setProcessing(true);
      setViewTables([]);
      setResponseJson([]);
      // setSelectedFiles([]);
      setResultNew({});
      let tempTableHeader: string[][] = [];
      let tempJsonResp: Object[][] = [];
      let fileName: string;
      let processDate: string;

      const processedFile: FileDetails | undefined = folder?.find(
        (file: FileDetails) => file.file_url === fileUrl
      );

      console.log(processedFile)
      // Extract file name without extension
      const trimmedFileName = processedFile?.file_original_name.replace(/\.[^/.]+$/, "");
      console.log("trimmedFileName:", trimmedFileName);
      fileName = trimmedFileName || "";

      // Extract and format the process date
      const trimmedProcessDate = processedFile?.process_date.split("T")[0];
      console.log("trimmedProcessDate:", trimmedProcessDate);
      processDate = trimmedProcessDate || "";
      // setFileName(processedFile?processedFile.file_original_name:'')
      if (!processedFile) {
        alert("Unable to process the file");
        setProcessing(false);
        return;
      }

      const responseJson = await new Promise<Object[][]>((resolve) => {
        setTimeout(() => {
          setResultNew(processedFile.output);
          Object.entries(processedFile.output).map(([key, value], index) => {
            let resJson: Array<Object> = [];

            if (Array.isArray(value) && value.length > 0 && typeof value[0] === "object") {
              let viewKeyList = Object.keys(value[0] || {});
              tempTableHeader.push(viewKeyList);
              setTableHeader([...tempTableHeader]);

              value.map((val) => {
                var abcObj: { [key: string]: string } = {};
                Object.entries(val).map(([itemKey, itemValue]) => {
                  abcObj[itemKey] = String(itemValue);
                });
                resJson.push(abcObj);
              });
            }

            resJson.length > 0 && tempJsonResp.push(resJson);
          });
          resolve(tempJsonResp);
        }, 2000);
      });

      console.log("Final responseJson:", responseJson); // Logs the updated responseJson after processing
      setResponseJson(responseJson); // Set the processed responseJson to state
      setDownloadFormat(format);
      setDownloadType(format);

      var tempJsonFile = responseJson[0] ? responseJson[0] : []
      var tempResultJsonData: MyObject = {}
      console.log("length of resultnew: ", Object.keys(resultNew).length)
      Object.keys(processedFile).length > 0 && Object.entries(processedFile).map(([key, value]) => {
        if ((typeof value !== "object" && key !== "file_url" && key !== "blob_location")|| (value && key !== "file_url" && key !== "blob_location")) {
          tempResultJsonData[key] = value;
        }
      })
      console.log(tempResultJsonData)
      tempJsonFile.unshift(tempResultJsonData)
      console.log(tempJsonFile)
      if (format == 'csv' || format == 'xlsx') {
        handleCSVDownload(format, fileName, processDate, tempJsonFile); // Call CSV download when downloadType state changes
      }
      console.log(downloadType)
      if (format == 'txt' || format == 'json') {
        handleJsonDownload(format, fileName, processDate, tempJsonFile); // Call CSV download when downloadType state changes
      }
      setProcessing(false);
    } else {
      alert("Unable to download file");
    }
  };


  const handleCSVDownload = (format: string, fileName: string, processDate: string, tempJsonFile: any) => {
    console.log(responseJson)

    // create file in browser
    const jsonFileName = fileName.slice(0, fileName.indexOf('.pdf'));
    // const json = JSON.stringify(tempJsonFile, null, 2);
    const flatten = (data: Record<string, any>, prefix = ''): Record<string, any> => {
      const result: Record<string, any> = {};
      for (const key in data) {
        if (data[key] && typeof data[key] === 'object') {
          // Recursively flatten nested objects
          const flatObject = flatten(data[key], prefix + key + '.');
          for (const subKey in flatObject) {
            result[subKey] = flatObject[subKey];
          }
        } else {
          result[prefix + key] = data[key];
        }
      }
      return result;
    };
    
    // Flatten the tempJsonFile data
    const flattenedData = Array.isArray(tempJsonFile) 
      ? tempJsonFile.map(item => flatten(item)) 
      : [flatten(tempJsonFile)];

    const workbook = utils.book_new();
    const worksheet = utils.json_to_sheet(flattenedData);
    utils.book_append_sheet(workbook, worksheet, "");
    const outputFileName = `${fileName}_${processDate}`;
    format === "csv"
      ? writeFile(workbook, `${outputFileName}.csv`)
      : writeFile(workbook, `${outputFileName}.xlsx`);

  };

  const handleJsonDownload = (format: string, fileName: string, processDate: string, tempJsonFile: any) => {

    console.log("come")
    setDownloadingJson(true)
    var data, filename, link;

    // create file in browser
    const jsonFileName = `${fileName}_${processDate}`;
    const json = JSON.stringify(tempJsonFile, null, 2);
    const blob = new Blob([json], { type: "application/json" });
    const href = URL.createObjectURL(blob);

    // create "a" HTLM element with href to file
    link = document.createElement("a");
    link.href = href;
    format == 'json' ? link.download = jsonFileName + ".json" : link.download = jsonFileName + ".txt";
    document.body.appendChild(link);
    link.click();

    // clean up "a" element & remove ObjectURL
    document.body.removeChild(link);
    URL.revokeObjectURL(href);

    setDownloadingJson(false)
  }

  // const handleProcess = (fileLink?: string): Promise<void> => {
  //   // Set initial states
  //   setProcessing(true); // Set processing state to true
  //   setRespError(false); // Reset response error state
  //   setViewTables([]); // Clear view tables
  //   setResponseJson([]); // Clear response JSON
  //   setSelectedFiles([]); // Clear selected files
  //   setResultNew({}); // Clear result new

  //   let fileList: Array<Object> = []; // Initialize an array to hold file objects

  //   // If a fileLink is provided, add it to the selectedFiles array
  //   if (fileLink) {
  //     let tempSelectedFiles = selectedFiles;
  //     tempSelectedFiles.push(fileLink);
  //     setSelectedFiles(tempSelectedFiles);

  //     // Create file object and push it to fileList
  //     let fileObj = {
  //       fileLink: fileLink
  //     };
  //     fileList.push(fileObj);
  //   } else {
  //     // If no fileLink is provided, iterate through selectedFiles
  //     selectedFiles.forEach((selectedFile) => {
  //       // Create file object for each selected file and push it to fileList
  //       let fileObj = {
  //         fileLink: selectedFile
  //       };
  //       fileList.push(fileObj);
  //     });
  //   }

  //   console.log(fileName);


  //   // Make API call to process documents
  //   return new Promise<void>((resolve, reject) => {
  //     processDocuments({
  //       files: fileList,
  //       username: currentUser?.username,
  //       email: currentUser?.email,
  //       tag: doctype,
  //       subscribed: currentUser?.subscribed
  //     }).then((res) => {
  //       // Process response data
  //       let tempTableHeader: string[][] = []; // Temporary array to hold table headers
  //       let tempJsonResp: Object[][] = []; // Temporary array to hold JSON response
  //       let responseNew = res.data; // Get response data
  //       setResultNew(responseNew); // Set result new

  //       // Iterate through response data
  //       Object.entries(responseNew).map(([key, value], index) => {
  //         console.log("type: ", typeof (value));

  //         let resJson: Array<Object> = [];

  //         // Check if value is an array and not empty
  //         if (Array.isArray(value) && value.length > 0 && typeof value[0] === "object") {
  //           let viewKeyList = Object.keys(value[0] || {}); // Get keys of first object in the array
  //           console.log(viewKeyList);
  //           tempTableHeader.push(viewKeyList); // Push keys to temporary table header array
  //           setTableHeader([...tempTableHeader]); // Set table header

  //           // Iterate through array elements
  //           value.map((val) => {
  //             var abcObj: { [key: string]: string } = {}; // Create object to hold key-value pairs
  //             Object.entries(val).map(([itemKey, itemValue], index) => {
  //               abcObj[itemKey] = String(itemValue); // Convert each value to string and assign to object
  //             });
  //             resJson.push(abcObj); // Push object to response JSON array
  //           });
  //         }
  //         // Check if response JSON array is not empty and push it to temporary response array
  //         resJson.length > 0 && tempJsonResp.push(resJson);
  //       });

  //       sessionStorage.setItem("response", JSON.stringify(res.data)); // Store response data in session storage
  //       setResponseJson(tempJsonResp); // Set response JSON
  //       console.log(tempJsonResp);

  //       // Make API call to get files by user
  //       filesByUser({ email: currentUser?.email, tag: doctype, username: currentUser?.username }).then((res) => {
  //         setFolder(res.data); // Set folder state with response data
  //       });

  //       setProcessing(false); // Set processing state to false
  //       resolve(); // Resolve the promise
  //     }).catch((error) => {
  //       setRespError(true);
  //       reject(error); // Reject the promise with error
  //       if (error.response) { // Check if error.response exists
  //         if (error.response.status === 426) {
  //           alert(error.response.data.data);
  //           setProcessing(false);
  //         } else if (error.response.status === 413) {
  //           alert("Resume data limit exceeded. Please try another resume.");
  //           setProcessing(false);
  //         } else if (error.response.status === 500) {
  //           alert(error.response.data.message);
  //           setProcessing(false);
  //         } else {
  //           alert("Facing Network Issue. Please Try again.");
  //           setProcessing(false);
  //         }
  //       } else {
  //         alert("Oops! Something went wrong.\n\nWe encountered an unexpected issue while processing this file. Please try again with different file. If the problem persists, contact our support team for assistance.");
  //         setProcessing(false);
  //       }
  //     });
  //   });
  // };

  const handleProcess = (fileLink: string) => {

    setProcessing(true);
    setViewTables([]);
    setResponseJson([]);
    setSelectedFiles(() => []);
    setResultNew({});
    let tempTableHeader: string[][] = [];
    let tempJsonResp: Object[][] = [];
    let tempSelectedFiles = selectedFiles;
    tempSelectedFiles.push(fileLink);
    setSelectedFiles(tempSelectedFiles);
    const processedFile: FileDetails | undefined = folder?.find((file: FileDetails) => file.file_url === fileLink);
    setTimeout(() => {
      if (processedFile) {
        setResultNew(processedFile.output);
        Object.entries(processedFile.output).map(([key, value], index) => {
          console.log("type: ", typeof (value));

          let resJson: Array<Object> = [];

          // Check if value is an array and not empty
          if (Array.isArray(value) && value.length > 0 && typeof value[0] === "object") {
            let viewKeyList = Object.keys(value[0] || {}); // Get keys of first object in the array
            console.log(viewKeyList);
            tempTableHeader.push(viewKeyList); // Push keys to temporary table header array
            setTableHeader([...tempTableHeader]); // Set table header

            // Iterate through array elements
            value.map((val) => {
              var abcObj: { [key: string]: string } = {}; // Create object to hold key-value pairs
              Object.entries(val).map(([itemKey, itemValue], index) => {
                abcObj[itemKey] = String(itemValue); // Convert each value to string and assign to object
              });
              resJson.push(abcObj); // Push object to response JSON array
            });
          }
          // Check if response JSON array is not empty and push it to temporary response array
          resJson.length > 0 && tempJsonResp.push(resJson);
        });
        setResponseJson(tempJsonResp);
        setProcessing(false)
      }
    }, 2000);
  }

  const handleDocumentApproval = (approval: string) => {
    setIsApproval('')
    console.log(selectedFiles)
    if (selectedFiles.length > 0) {
      setProcessAction(approval)
      let files: Array<Object> = [];
      let fileUrl: string = ''
      selectedFiles.forEach((selectedFile) => {
        fileUrl = selectedFile
        let fileObj = {
          file_url: selectedFile

        };
        files.push(fileObj);
      });
      const updatedFolder = folder?.map((file) => {
        // Check if the file matches the given URL
        if (file.file_url === fileUrl) {
          // Update the approval and folder properties based on approval value
          return {
            ...file,
            approval: approval,
            folder: approval === "accepted" ? "accepted" : "rejected",
          };
        }
        return file; // Return unchanged file for non-matching items
      });

      const updatedFile = updatedFolder?.find((file) => file.file_url === fileUrl);
      if (updatedFile) {
        setIsApproval(updatedFile.approval||'');
      }

      setFolder(updatedFolder);

      setFolder(updatedFolder); // Update the state with modified data
      setTimeout(() => {
        setProcessAction(null); // Reset process action
        alert(`Your document have been ${approval}.`); // Show alert after the delay
      }, 800);

    } else {
      alert("No file selected")
    }
  }

  useEffect(() => {
    // getAllMember({ organizationId: currentUser?.organizationId }).then((res) => {
    //   //  Filtering out emails of objects with type !== 'superadmin'
    //   const emails = res.data
    //     .filter((user: TeamMemberObject) => user.type !== 'superadmin')
    //     .map((user: TeamMemberObject) => user.email);

    //   setTeams(emails)
    // });
  }, [])

  const toggleState = () => {
    setPreview(prevState => !prevState); // Invert the current state value
  };

  useEffect(() => {
    setFolder(null)
    console.log("doctype", doctype)
    if (doctype) handleFileInfo(doctype)
  }, [doctype]);

  useEffect(() => {
    if (refresh) {
      // let tempFolder: FileDetails[] = []
      // setFolder(tempFolder)
      // filesByUser({ email: currentUser?.email, tag: doctype, username: currentUser?.username }).then((res) => {
      //   setFolder(res.data);
      //   setRefresh(false)
      // });
      if (doctype) handleFileInfo(doctype)
    }
  }, [refresh])



  return (
    <div>

      {/* Upload Document */}
      <div className="modal fade" id="kt_modal_assosiate_student" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered mw-650px">
          <div className="modal-content">
            <div className="modal-header">
              <h2 className="fw-bolder">Upload Document</h2>
              <button
                className="btn btn-sm btn-icon btn-active-color-primary"
                data-bs-dismiss="modal"
              // onClick={(e) => setCloseModal(true)}
              >
                <KTIcon iconName="cross" className="fs-1" />
              </button>
            </div>
            <div className="modal-body scroll-y mx-5 mx-xl-15 my-7">
              {
                currentUser?.usertype == 'superadmin' ?
                  <UploadDocuments closeModal={closeModal} setRefresh={setRefresh} setCloseModal={setCloseModal} /> :
                  <p className="fs-1 fw-bold text-center">Feature Disabled</p>
              }
            </div>
          </div>
        </div>
      </div>

      {/* Preview Document */}
      <div className="modal fade" id="kt_modal_preview" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered mw-650px">
          <div className="modal-content">
            <div className="modal-header">
              <h2 className="fw-bolder">Preview Document</h2>
              <div
                className="btn btn-sm btn-icon btn-active-color-primary"
                data-bs-dismiss="modal"
              >
                <KTIcon iconName="cross" className="fs-1" />
              </div>
            </div>
            <div className="modal-body scroll-y mx-5 mx-xl-15 my-7">
              <div>
                <iframe
                  src={sasUrl}
                  title="PDF Preview"
                  width="100%"
                  height="500px"
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Assign Team */}
      <div
        className="modal fade"
        id="kt_modal_assign"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered mw-650px">
          <div className="modal-content">
            <div className="modal-header">
              <h2 className="fw-bolder">Assign Document to Team Member</h2>
              <div
                className="btn btn-sm btn-icon btn-active-color-primary"
                data-bs-dismiss="modal"
              >
                <KTIcon iconName="cross" className="fs-1" />
              </div>
            </div>
            <AssignDocument
              selectedFiles={selectedFiles}
              setSelectedFiles={setSelectedFiles}
              setProcessAction={setProcessAction}
              setRefresh={setRefresh}
            />
          </div>
        </div>
      </div>

      {/* Provide Feedback */}
      <div
        className="modal fade"
        id="kt_modal_feedback"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered mw-650px">
          <div className="modal-content">
            <div className="modal-header">
              <h2 className="fw-bolder">Provide Give Feedback about the Processed Document</h2>
              <div
                className="btn btn-sm btn-icon btn-active-color-primary"
                data-bs-dismiss="modal"
              >
                <KTIcon iconName="cross" className="fs-1" />
              </div>
            </div>
            <Feedback handleDocumentApproval={handleDocumentApproval} selectedFiles={selectedFiles} setSelectedFiles={setSelectedFiles} setRefresh={setRefresh} />
          </div>
        </div>
      </div>

      <div className="mx-2 mb-xl-8" style={{ borderRadius: '0px', minHeight: '90vh' }}>
        {/* begin::Header */}
        <HeaderSection doctype={doctype ? doctype : ""} />
        {/* end::Header */}

        <ButtonRow
          tables={tables}
          resultNew={resultNew}
          responseJson={responseJson}
          setResultView={setResultView}
          resultView={resultView}
          toggleState={toggleState}
          preview={preview}
          tab={tabButton}
        />

        {folder && folder.length > 0 ? (
          <div className="card-body pt-5">
            {
              preview ?
                <DocumentReviewView
                  fileName={fileName}
                  doctype={doctype ? doctype : ""}
                  resultNew={resultNew}
                  resultView={resultView}
                  tableHeader={tableHeader}
                  responseJson={responseJson}
                  processAction={processAction}
                  selectedFiles={selectedFiles}
                  processing={processing}
                  handleDocumentApproval={handleDocumentApproval}
                  handleDownload={handleDownload}
                  isApproval={isApproval||''}
                /> :
                <>
                  {
                    folder &&
                    <DocumentLibraryView
                      doctype={doctype ? doctype : "Document"}
                      documents={folder}
                      setRefresh={setRefresh}
                      preview={preview}
                      toggleState={toggleState}
                      setFileName={setFileName}
                      onReview={handleProcess}
                      handleDownload={handleDownload}
                      setTabButton={setTabButton}
                    />
                  }
                </>
            }
          </div>
        ) : (
          <div className="row">
            {folder?.length == 0 ?
              <div className="card-body pt-5">
                <p className="fw-bold fs-2 text-center">No Files Present. Please Upload New Files</p>
              </div>
              : <Blocks
                visible={true}
                height="80"
                width="80"
                ariaLabel="blocks-loading"
                wrapperStyle={{}}
                wrapperClass="blocks-wrapper"
              />}
          </div>
        )}
      </div>
    </div>
  );
}