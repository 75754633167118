
export const invoiceQuestionAnswer = [
    {
        "id": 4,
        "question": "What is the invoice number for the cleaning services?",
        "answer": "The invoice number for the cleaning services is 1234-15099. This unique identifier helps to track and reference the specific invoice for the services rendered."
    },
    {
        "id": 5,
        "question": "What is the total amount for the cleaning invoice?",
        "answer": "The total amount for the cleaning invoice is $929.50, which includes the subtotal of the cleaning services along with any applicable taxes and discounts."
    },
    {
        "id": 6,
        "question": "Who is the client for the cleaning invoice?",
        "answer": "The client for the cleaning services is Franc Swapson, a regular customer who has previously availed similar cleaning services from the provider."
    },
    {
        "id": 7,
        "question": "What is the cleaning period mentioned in the invoice?",
        "answer": "The cleaning period mentioned in the invoice spans from December 1, 2023, to December 31, 2023, covering a full month of cleaning services."
    },
    {
        "id": 8,
        "question": "What is the subtotal amount for the cleaning services?",
        "answer": "The subtotal amount for the cleaning services is $895.00, representing the cost of the services before any discounts or taxes are applied."
    },
    {
        "id": 9,
        "question": "What is the discount applied in the cleaning invoice?",
        "answer": "The cleaning invoice includes a discount of $50.00, which was applied to the subtotal to provide a reduction in the overall cost of the services."
    },
    {
        "id": 10,
        "question": "What is the tax rate for the cleaning invoice?",
        "answer": "The tax rate for the cleaning invoice is 10%, which is applied to the subtotal amount after the discount is applied, resulting in the final total."
    },
    {
        "id": 11,
        "question": "What is the payment instruction for the cleaning invoice?",
        "answer": "The payment instruction for the cleaning invoice is as follows: payments should be made to National Bank of Hamamanji with the account number 0123 0000 1111 2323 and sort code 25-88-00."
    },
    {
        "id": 12,
        "question": "What is the thank you note in the cleaning invoice?",
        "answer": "The thank you note in the cleaning invoice reads: 'THANK YOU FOR YOUR BUSINESS!' which is a gesture of appreciation for the client's continued patronage."
    },
    {
        "id": 13,
        "question": "What is the bill-to address for the commercial invoice?",
        "answer": "The bill-to address for the commercial invoice is 100 Mighty Bay, 125863 Rome, Italy. This is the location to which the invoice was sent for payment processing."
    },
    {
        "id": 14,
        "question": "What is the total amount for the commercial invoice?",
        "answer": "The total amount for the commercial invoice is $702.00, which includes the cost of the products, shipping charges, and applicable sales taxes."
    },
    {
        "id": 15,
        "question": "What is the payment method mentioned in the commercial invoice?",
        "answer": "The payment method mentioned in the commercial invoice is Credit Card. The customer is required to pay using a valid credit card during checkout."
    },
    {
        "id": 16,
        "question": "What is the company name for the commercial invoice?",
        "answer": "The company name on the commercial invoice is 'Local Store,' which is the seller of the goods listed in the invoice."
    },
    {
        "id": 17,
        "question": "What is the total sales tax for the commercial invoice?",
        "answer": "The total sales tax for the commercial invoice is $117.00. This represents the sales tax applied based on the jurisdiction of the transaction."
    },
    {
        "id": 18,
        "question": "What is the shipping charge for the commercial invoice?",
        "answer": "The shipping charge for the commercial invoice is $100.00, covering the cost of delivering the products to the buyer's location."
    },
    {
        "id": 19,
        "question": "What is the total quantity of products in the commercial invoice?",
        "answer": "The total quantity of products in the commercial invoice is 3 items, which includes various goods listed on the invoice such as equipment and parts."
    },
    {
        "id": 20,
        "question": "What is the unit price of the conveyor belt in the commercial invoice?",
        "answer": "The unit price of the conveyor belt in the commercial invoice is $200.00 per unit. This is the cost of each conveyor belt before any taxes or discounts."
    },
    {
        "id": 21,
        "question": "What is the total amount for the HVAC invoice?",
        "answer": "The total amount for the HVAC invoice is $137.50, which includes the cost of parts and labor for HVAC repairs, as well as applicable taxes."
    },
    {
        "id": 22,
        "question": "What is the invoice date for the HVAC invoice?",
        "answer": "The invoice date for the HVAC invoice is September 22, 2024, which is the date when the services were completed and the invoice was generated."
    },
    {
        "id": 23,
        "question": "What is the name of the person to pay for the HVAC invoice?",
        "answer": "The person to pay for the HVAC invoice is Lisa Johnson, who is responsible for settling the amount due for the HVAC services rendered."
    },
    {
        "id": 24,
        "question": "What is the amount for the windshield wipers in the HVAC invoice?",
        "answer": "The amount for the windshield wipers in the HVAC invoice is $16.00, representing the cost of the wiper blades purchased for HVAC-related maintenance."
    },
    {
        "id": 25,
        "question": "What is the total tax rate for the HVAC invoice?",
        "answer": "The total tax rate for the HVAC invoice is 10%, which was applied to the subtotal after the cost of parts and labor was calculated."
    },
    {
        "id": 26,
        "question": "What is the name of the customer for the HVAC invoice?",
        "answer": "The customer for the HVAC invoice is Mr. Alex Thompson, who requested and paid for HVAC repairs at his premises."
    },
    {
        "id": 27,
        "question": "What is the invoice number for the landscaper invoice?",
        "answer": "The invoice number for the landscaper invoice is 1500.225. This unique reference number helps identify and track the landscaping service."
    },
    {
        "id": 28,
        "question": "What is the total amount for the landscaper invoice?",
        "answer": "The total amount for the landscaper invoice is $10,670.00, which includes the cost of landscaping services, plants, materials, and labor."
    },
    {
        "id": 29,
        "question": "What is the subtotal for the landscaper invoice?",
        "answer": "The subtotal for the landscaper invoice is $10,645.00, representing the total cost of landscaping services and materials before taxes and discounts."
    },
    {
        "id": 30,
        "question": "What is the total discount for the landscaper invoice?",
        "answer": "The landscaper invoice includes a discount of $945.00, which was applied to reduce the overall cost of services and materials."
    },
    {
        "id": 31,
        "question": "What is the bank account information for the landscaper invoice?",
        "answer": "The bank account information for the landscaper invoice is as follows: Bank Name: Bank Name Here, Account Number: 2412 2000 5454 5152, with additional instructions for payment."
    },
    {
        "id": 32,
        "question": "What is the total quantity of ice blue decorative stone in the landscaper invoice?",
        "answer": "The total quantity of ice blue decorative stone in the landscaper invoice is 50 units, which were used in landscaping to enhance the aesthetic appearance of the yard."
    }




];

export const bankStatementQuestionsAndAnswers = [

    { id: 4, question: "What is the opening balance for May 2024?", answer: "The opening balance is '$12,340.00'." },
    { id: 5, question: "What is the total credit for May 2024?", answer: "The total credit is '$20,500.00'." },
    { id: 6, question: "What is the total debit for May 2024?", answer: "The total debit is '$7,500.00'." },
    { id: 7, question: "What is the closing balance for May 2024?", answer: "The closing balance is '$25,340.00'." },
    { id: 8, question: "What is the description of the transaction on May 10, 2024?", answer: "The transaction description is 'Grocery Purchase' of '$150.00'." },
    { id: 9, question: "What is the reference number for the wire transfer on May 18, 2024?", answer: "The reference number is 'WT657892'." },
    { id: 10, question: "What is the amount of the loan repayment on May 25, 2024?", answer: "The loan repayment is '$1,200.00'." },
    { id: 11, question: "Who made the direct deposit on May 3, 2024?", answer: "The direct deposit was made by 'ABC Company'." },
    { id: 12, question: "What is the total transaction fee for May 2024?", answer: "The total transaction fee is '$25.00'." },
    { id: 13, question: "What is the interest adjustment for May 2024?", answer: "The interest adjustment is '$5.50'." },
    { id: 14, question: "What is the transaction date for the wire transfer on May 18, 2024?", answer: "The transaction date is 'May 18, 2024'." },
    { id: 15, question: "What is the balance after the 'Grocery Purchase' on May 10, 2024?", answer: "The balance after the grocery purchase is '$12,190.00'." },
    { id: 16, question: "What is the merchant for the 'Grocery Purchase' on May 10, 2024?", answer: "The merchant is 'XYZ Supermarket'." },
    { id: 17, question: "What is the reference number for the deposit on May 3, 2024?", answer: "The reference number is 'DP4458'." },
    { id: 18, question: "What is the location for the ATM withdrawal on May 7, 2024?", answer: "The location is 'ATM at 123 Main St.'." },
    { id: 19, question: "What is the total amount of cash deposits in May 2024?", answer: "The total amount of cash deposits is '$3,000.00'." },
    { id: 20, question: "What is the payment type for the 'Loan Repayment' on May 25, 2024?", answer: "The payment type is 'Online Transfer'." },
    { id: 21, question: "What is the service fee for the loan repayment on May 25, 2024?", answer: "The service fee for the loan repayment is '$5.00'." },
    { id: 22, question: "What is the transaction reference for the cash withdrawal on May 5, 2024?", answer: "The transaction reference is 'CW1234'." },
    { id: 23, question: "What is the amount for the 'Loan Repayment' on May 25, 2024?", answer: "The loan repayment amount is '$1,200.00'." },
    { id: 24, question: "What is the location of the direct deposit made on May 3, 2024?", answer: "The direct deposit was made at 'New York, NY'." },
    { id: 25, question: "What is the total number of transactions in May 2024?", answer: "The total number of transactions is '35'." },
    { id: 26, question: "What is the highest value debit transaction for May 2024?", answer: "The highest value debit transaction is '$1,500.00' for 'Rent Payment'." },
    { id: 27, question: "What is the total balance for all checks cashed in May 2024?", answer: "The total balance for checks cashed is '$2,000.00'." },
    { id: 28, question: "What is the transaction ID for the wire transfer on May 18, 2024?", answer: "The transaction ID is 'WTR657892'." },
    { id: 29, question: "What is the amount for the 'Grocery Purchase' on May 10, 2024?", answer: "The amount for the grocery purchase is '$150.00'." },
    { id: 30, question: "What is the total value of international transactions for May 2024?", answer: "The total value of international transactions is '$4,000.00'." },
];
export const handwrittenFileQuestionsAndAnswers = [
    // Medical Concerns (Highest Priority)
    { id: 4, question: "What are Sally Walker's medical concerns?", answer: "The medical concerns are runny nose, micas in throat, weakness, aches, chills fired." },
    { id: 5, question: "What are LAXMI's medical concerns?", answer: "The medical concerns include Cholecystitis, muco/pyocele of GB, and HTN." },
    { id: 6, question: "What are Dalia Kundu's medical concerns?", answer: "The medical concerns include MBC, (Bone /Kung /Liver), and Plan: -6# (P+H) -Inj xgeva -alt # (120)." },

    // Dalia Kundu Additional Fields
    { id: 7, question: "What is Dalia Kundu's MR number?", answer: "The MR number is NCRI/21/1427." },
    { id: 8, question: "Who is Dalia Kundu's consultant?", answer: "The consultant is Tanmoy Kumar Mandal." },
    { id: 9, question: "What is Dalia Kundu's appointment date?", answer: "The appointment date is 2021-02-10." },
    { id: 10, question: "What is Dalia Kundu's age?", answer: "The age is 64." },
    { id: 11, question: "What is Dalia Kundu's BP, pulse, and oxygen level?", answer: "The BP, pulse, and oxygen level information is not provided." },
    { id: 12, question: "What is Dalia Kundu's history or symptoms?", answer: "The history or symptoms include MBC." },
    { id: 13, question: "What is Dalia Kundu's clinical examination result?", answer: "The clinical examination result is (8/5/3)." },
    { id: 14, question: "What is Dalia Kundu's diagnosis?", answer: "The diagnosis is (Bone /Kung /Liver)." },
    { id: 15, question: "What medicines are prescribed for Dalia Kundu?", answer: "The prescribed medicines include Plan: -6# (P+H) -Inj xgeva -alt # (120)." },
    { id: 16, question: "What advice is prescribed for Dalia Kundu?", answer: "The advice prescribed includes Adv ou Dis 1 CBC Adv 2 Give G (P+H) ou 10/2/21 3 RIS on 3/3/21 ℃ CBC - HOW -TOS - Closeu-TDS." },
    { id: 17, question: "What is the follow-up date for Dalia Kundu?", answer: "The follow-up includes T. Rexa (4)-BD D2-D4 for (2 - T.Ondan (4) -BDD2-D4 -T. Ultracet = BDx 5 days." },
    { id: 18, question: "What is the additional information for Dalia Kundu?", answer: "The additional information is STODDARLE COST." },

    // LAXMI Additional Fields
    { id: 19, question: "What is LAXMI's registration card type?", answer: "The registration card type is ACCIDENT & EMERGENCY REGISTRATION CARD." },
    { id: 20, question: "What is LAXMI's hospital name?", answer: "The hospital name is Bhagwan Mahavir Hospital." },
    { id: 21, question: "What is LAXMI's contact number?", answer: "The contact number is 9891596832." },
    { id: 22, question: "What is LAXMI's BP and pulse rate?", answer: "The BP is 110/80 mmHg, and the pulse rate is 84." },
    { id: 23, question: "What is LAXMI's provisional diagnosis?", answer: "The provisional diagnosis is cholecystitis with muco/pyocele of GB." },
    { id: 24, question: "What medicines are prescribed for LAXMI?", answer: "The prescribed medicines include T. Dicho 1 tab BD, T. Rantas, and LORS (water usage)." },
    { id: 25, question: "What is LAXMI's follow-up plan?", answer: "The follow-up plan includes review in OPD on 10/2, with further advice for RIS on 3/3/2021." },
    { id: 26, question: "What is LAXMI's presenting complaint?", answer: "The presenting complaint is abdominal pain and HTN." },
    { id: 27, question: "What is LAXMI's review timeline?", answer: "The review is scheduled in OPD on 10/2/2021." },

    // Sally Walker Additional Fields
    { id: 28, question: "What is Sally Walker's current medication?", answer: "The current medication is Vyvanse (25mg) daily for attention." },
    { id: 29, question: "What is Sally Walker's address?", answer: "The address is 24 Barney Lane." },
    { id: 30, question: "What is Sally Walker's phone number?", answer: "The phone number is 9069173486." },
    { id: 31, question: "What is Sally Walker's presenting complaint?", answer: "Sally Walker's presenting complaint is a runny nose and throat infection." },
    { id: 32, question: "What is Sally Walker's BP, pulse, and oxygen level?", answer: "The BP is 120/80 mmHg, the pulse rate is 70, and oxygen levels are within normal range." },
    { id: 33, question: "What is Sally Walker's clinical examination result?", answer: "The clinical examination showed signs of a mild viral infection, including a sore throat." },
    { id: 34, question: "What advice is prescribed for Sally Walker?", answer: "The prescribed advice includes rest, fluid intake, and over-the-counter medication for symptom relief." },
    { id: 35, question: "What is Sally Walker's follow-up plan?", answer: "Sally Walker should follow up in 1 week or earlier if symptoms worsen." },
    { id: 36, question: "What additional information is there for Sally Walker?", answer: "There is no additional information provided for Sally Walker." }
];

export const voucherFileQuestionsAndAnswers = [
    // Gift Coupon 1: THREE NIGHT IN MALDIVES ISLAND
    { id: 4, question: "What type of gift coupon is 'THREE NIGHT IN MALDIVES ISLAND'?", answer: "The type is 'GIFT COUPON'." },
    { id: 5, question: "What is the occasion for the 'THREE NIGHT IN MALDIVES ISLAND' gift coupon?", answer: "The occasion is 'HAPPY BIRTHDAY'." },
    { id: 6, question: "What is the value of the 'THREE NIGHT IN MALDIVES ISLAND' gift coupon?", answer: "The value is '$3000'." },
    { id: 7, question: "What is the start date of the 'THREE NIGHT IN MALDIVES ISLAND' gift coupon?", answer: "The start date is '24 AUGUST 2029'." },
    { id: 8, question: "What is the end date of the 'THREE NIGHT IN MALDIVES ISLAND' gift coupon?", answer: "The end date is '26 AUGUST 2029'." },
    { id: 9, question: "What message is included with the 'THREE NIGHT IN MALDIVES ISLAND' gift coupon?", answer: "The message is 'Have a wonderful birthday! We hope you enjoy your present! You deserve it!'" },
    { id: 10, question: "Who is the recipient of the 'THREE NIGHT IN MALDIVES ISLAND' gift coupon?", answer: "The recipient is 'Flavia'." },
    { id: 11, question: "Who is the sender of the 'THREE NIGHT IN MALDIVES ISLAND' gift coupon?", answer: "The sender is 'Mom and Dad'." },

    // Gift Coupon 2: 10% off
    { id: 12, question: "What is the description of the '10% off' gift coupon?", answer: "The description is 'Enjoy your dinner in ABC Restaurant'." },
    { id: 13, question: "What is the start date of the '10% off' gift coupon?", answer: "The start date is '24 JANUARY 2022'." },
    { id: 14, question: "What is the end date of the '10% off' gift coupon?", answer: "The end date is '31 JANUARY 2022'." },
    { id: 15, question: "What is the location of the '10% off' gift coupon?", answer: "The location is 'P 153/1, Scheme 7 M, C I T Road, Kankurgachi, Kolkata - 700054 (Hudco Junction)'" },
    { id: 16, question: "Who is the recipient of the '10% off' gift coupon?", answer: "The recipient is 'JHON DEY'." },

    // Gift Coupon 3: $50 Voucher
    { id: 17, question: "What is the voucher value of the '$50 Voucher' gift coupon?", answer: "The voucher value is '$50'." },
    { id: 18, question: "What is the ticket code of the '$50 Voucher' gift coupon?", answer: "The ticket code is '00-00-01'." },
    { id: 19, question: "What is the store address for the '$50 Voucher' gift coupon?", answer: "The store address is '123 Anywhere St., Any City'." },
    { id: 20, question: "What is the contact number for the '$50 Voucher' gift coupon?", answer: "The contact number is '123-456-7890'." },
    { id: 21, question: "What is the usage instruction for the '$50 Voucher' gift coupon?", answer: "The usage is 'FOR USE IN-STORE ONLY'." },
    { id: 22, question: "What is the start date of the '$50 Voucher' gift coupon?", answer: "The start date is '2025-12-05'." },
    { id: 23, question: "What is the end date of the '$50 Voucher' gift coupon?", answer: "The end date is '2025-12-24'." },
    { id: 24, question: "What is the store name for the '$50 Voucher' gift coupon?", answer: "The store name is 'The Story Name'." },
];

export const receiptQuestionsAndAnsers = [
    { "id": 4, "question": "What is the name of the company on the receipt from Berghotel Grosse Scheidegg?", "answer": "The company name is 'Berghotel Grosse Scheidegg'." },
    { "id": 5, "question": "What is the address of Berghotel Grosse Scheidegg?", "answer": "The address is '3818 Grindelwald'." },
    { "id": 6, "question": "What is the receipt number at Berghotel Grosse Scheidegg?", "answer": "The receipt number is '4572'." },
    { "id": 7, "question": "What is the total amount on the receipt from Berghotel Grosse Scheidegg?", "answer": "The total amount is 54.50 CHF." },
    { "id": 8, "question": "What items were purchased at Berghotel Grosse Scheidegg?", "answer": "The items purchased were 'Latte Macchiato', 'Gloki', 'Schweinschnitzel', and 'Chässpätzli'." },
    { "id": 9, "question": "How much did the Latte Macchiato cost at Berghotel Grosse Scheidegg?", "answer": "The total cost for Latte Macchiato was 9.00 CHF." },
    { "id": 10, "question": "What is the contact phone number for Berghotel Grosse Scheidegg?", "answer": "The contact phone number is '033 853 67 16'." },
    { "id": 11, "question": "What is the equivalent in Euro on the receipt from Berghotel Grosse Scheidegg?", "answer": "The equivalent in Euro is 36.33 EUR." },
    { "id": 12, "question": "What is the tax percentage on the receipt from Berghotel Grosse Scheidegg?", "answer": "The tax percentage is 7.6%." },
    { "id": 13, "question": "How many units of Gloki were ordered at Berghotel Grosse Scheidegg?", "answer": "One unit of Gloki was ordered." },
    { "id": 14, "question": "What is the name of the company on the receipt from The Sand Dollar?", "answer": "The company name is 'The Sand Dollar'." },
    { "id": 15, "question": "What is the address of The Sand Dollar?", "answer": "The address is '100 Chopin Plaza, Miami, FL 33131'." },
    { "id": 16, "question": "What is the date on the receipt from The Sand Dollar?", "answer": "The date on the receipt is '2019-06-13'." },
    { "id": 17, "question": "What time is listed on the receipt from The Sand Dollar?", "answer": "The time is '11:29 AM'." },
    { "id": 18, "question": "How much did the Lobster Roll cost at The Sand Dollar?", "answer": "The total cost for Lobster Roll was 25.00 USD." },
    { "id": 19, "question": "How much did the Kale Salad - Shrimp cost at The Sand Dollar?", "answer": "The total cost for Kale Salad - Shrimp was 18.00 USD." },
    { "id": 20, "question": "What is the total amount on the receipt from The Sand Dollar?", "answer": "The total amount is 82.98 USD." },
    { "id": 21, "question": "What is the payment method used on the receipt from The Sand Dollar?", "answer": "The payment method is 'AMEX 83911'." },
    { "id": 22, "question": "What items were purchased at The Sand Dollar?", "answer": "The items purchased were 'SM Calamari', 'Lobster Roll', 'Kale Salad - Shrimp', and 'Snapper Entree'." },
    { "id": 23, "question": "How much did the Snapper Entree cost at The Sand Dollar?", "answer": "The total cost for Snapper Entree was 28.00 USD." },
    { "id": 24, "question": "What is the name of the company on the receipt from Hotel Restaurant and Bar?", "answer": "The company name is 'Hotel Restaurant and Bar'." },
    { "id": 25, "question": "What is the phone number for Hotel Restaurant and Bar?", "answer": "The phone number is '650-309-1992'." },
    { "id": 26, "question": "What is the address of Hotel Restaurant and Bar?", "answer": "The address is '1016 6th Ave, New York, NY'." },
    { "id": 27, "question": "What is the terminal mentioned on the receipt from Hotel Restaurant and Bar?", "answer": "The terminal is '2'." },
    { "id": 28, "question": "What time is listed on the receipt from Hotel Restaurant and Bar?", "answer": "The time is '12:54 PM'." },
    { "id": 29, "question": "How much did the Hendrick Gin & Tonic cost at Hotel Restaurant and Bar?", "answer": "The cost for Hendrick Gin & Tonic was 10.50 USD." },
    { "id": 30, "question": "How much did the Ginger Mule cost at Hotel Restaurant and Bar?", "answer": "The cost for Ginger Mule was 9.50 USD." }
]


