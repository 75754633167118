/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { ThreeDots } from "react-loader-spinner";
import { Link, useNavigate } from "react-router-dom";
import { statistic } from "../../api";
import { useAuth } from "../auth";
import { KTIcon, toAbsoluteUrl } from "../../../_metronic/helpers";
import { Dropdown1 } from "../../../_metronic/partials/content/dropdown/Dropdown1";
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

type Props = {
  className: string;
  color: string;
  svgIcon?: string;
  iconColor: string;
  title: string;
  titleColor?: string;
  description: string;
  descriptionColor?: string;
  image?: string;
  tag?: string;
  processed: number;
  unprocessed: number;
};

const DocumentTypeCard: React.FC<Props> = ({
  className,
  color,
  svgIcon,
  iconColor,
  title,
  titleColor,
  description,
  descriptionColor,
  image,
  tag,
  processed,
  unprocessed
}) => {
  

  const [fetching, setFetching] = useState(false)
  const navigate = useNavigate()

  
  return (
    <div className={`card ${className}`} style={{ boxShadow: '6px 6px 19px #0c101b, -6px -6px 19px #1c263f' }} title="Click to test your document">
      <div className="card-body p-0">
        <div className={`px-9 pt-7 card-rounded h-275px w-100 bg-${color}`}>
          <div className="d-flex flex-stack">
            <h3 className="m-0 text-white fw-bold fs-1">
              {title}
            </h3>
            <div className="ms-1">
              <button
                title="Analytics"
                type="button"
                className={`btn btn-sm btn-icon btn-primary`}
                onClick={(e) => navigate(`/statistics/${tag}`)}
              >
                <img src={toAbsoluteUrl("/media/custom-icon/graph-icon.png")} alt="Thirdeye data" height={25} />
              </button>
              <Dropdown1 />
            </div>
          </div>
          <div className="d-flex text-center flex-column text-white pt-8">
            {/* <span className="fw-semibold fs-7">Total API Call</span> */}
            <span className="fw-bold fs-5 pt-1">{description}</span>
          </div>
        </div>

        <div
          className="shadow-xs card-rounded mx-9 mb-9 px-6 py-2 position-relative z-index-1 bg-light"
          style={{ marginTop: "-100px" }}
        >
          {
            fetching ?
              <div className="">
                {/* <div className="fs-4 fw-bold text-center">Fetching Analytics &nbsp;</div> */}
                <SkeletonTheme baseColor="#1f2940" highlightColor="#565674">
                  <div className="my-4"><Skeleton height={40} /></div>
                  <div className="my-4"><Skeleton height={40} /></div>
                  <div className="my-4"><Skeleton height={40} /></div>
                </SkeletonTheme>
              </div> :
              <div className="">
                <div className="d-flex align-items-center my-3">
                  <div className="symbol symbol-45px w-40px me-5">
                    <span className="symbol-label bg-lighten">
                      <KTIcon iconName="compass" className="fs-1" />
                    </span>
                  </div>
                  <div className="d-flex align-items-center flex-wrap w-100">
                    <div className="mb-1 pe-3 flex-grow-1">
                      <a
                        href="#"
                        className="fs-5 text-gray-800 text-hover-primary fw-bold"
                        style={{ margin: '0', opacity: 0.5 }}
                      >
                        Processed Count
                      </a>
                    </div>
                    <div className="d-flex align-items-center">
                      <div className="fw-bold fs-1 text-gray-800 pe-1"> {processed}</div>
                    </div>
                  </div>
                </div>
                <div className="d-flex align-items-center my-3">
                  <div className="symbol symbol-45px w-40px me-5">
                    <span className="symbol-label bg-lighten">
                      <KTIcon iconName="category" className="fs-1" />
                    </span>
                  </div>
                  <div className="d-flex align-items-center flex-wrap w-100">
                    <div className="mb-1 pe-3 flex-grow-1">
                      <a
                        href="#"
                        className="fs-5 text-gray-800 text-hover-primary fw-bold"
                        style={{ margin: '0', opacity: 0.5 }}
                      >
                        Unprocessed Count
                      </a>
                    </div>
                    <div className="d-flex align-items-center">
                      <div className="fw-bold fs-1 text-gray-800 pe-1">{unprocessed}</div>
                    </div>
                  </div>
                </div>
                <div className="d-flex align-items-center my-3">
                  <div className="symbol symbol-45px w-40px me-5">
                    <span className="symbol-label bg-lighten">
                      <KTIcon iconName="document" className="fs-1" />
                    </span>
                  </div>
                  <div className="d-flex align-items-center flex-wrap w-100">
                    <div className="mb-1 pe-3 flex-grow-1">
                      <a
                        href="#"
                        className="fs-5 text-gray-800 text-hover-primary fw-bold"
                        style={{ margin: '0', opacity: 0.5 }}
                      >
                        Total Count
                      </a>
                    </div>
                    <div className="d-flex align-items-center">
                      <div className="fw-bold fs-1 text-gray-800 pe-1">{processed + unprocessed}</div>
                    </div>
                  </div>
                </div>
              </div>
          }
        </div>
      </div>
    </div >
  );
};

export { DocumentTypeCard };