/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { Blocks, ProgressBar } from 'react-loader-spinner'
import { KTIcon } from '../../../_metronic/helpers'
import { updateDownloadCount } from '../../api'
import { useAuth } from '../auth'
import Template1 from '../resumeTemplate/template1/Forum'
import { ResumeDataObject, ResumeTableProps } from './ResumeFormattingModel'
import { Spinner } from 'react-bootstrap'

export const ResumeTable: React.FC<ResumeTableProps> = ({ className, resumeData, setLink, type, fetching, formatting, targetRef, handleResumeProcess, compare, download, setRefresh, setSelectedFiles, toPDF,bulkTab}) => {
    const [data, setData] = useState<ResumeDataObject[]>([])
    const [fileLink, setFileLink] = useState<string>()
    const [processingFile, setProcessingFile] = useState<string>()
    const [fileName, setFileName] = useState<string>("");
    const [processing, setProcessing] = useState<boolean>(false)
    const [selectedFilesTable, setSelectedFilesTable] = useState<string[]>([]);
    const [selectedFileNames, setSelectedFileNames] = useState<string[]>([]);
    const [closeModal, setCloseModal] = useState(false);
    const [showFeedback, setShowFeedback] = useState('');
    const [resumeOutput, setResumeOutput] = useState<JSON>();


    const { currentUser } = useAuth();
    console.log("resumedata", resumeData)

    
    const handleSelect = (
        e: any,
        url: string,
        name: string,
        processed: number
    ) => {

        if (e.target.checked) {
            if (selectedFilesTable.length >= 5) {
                alert("You can only select up to 5 Resumes.");
                e.preventDefault(); // Prevents checkbox from being checked
                return;
            }
            let tempSelectedFileNames = selectedFileNames;
            tempSelectedFileNames.push(name);
            setSelectedFileNames([...tempSelectedFileNames]);
            selectedFilesTable.push(url);
            setFileName(name);


            // if (processed == 1 && selectedFilesTable.length <= 1) {
            //   handleProcess();
            // }

        }
        if (!e.target.checked) {
            setFileName("");
            // setResult({});
            const index = selectedFilesTable.indexOf(url);
            if (index > -1) {
                selectedFilesTable.splice(index, 1);
                let tempSelectedFileNames = selectedFileNames;
                tempSelectedFileNames.splice(index, 1);
                setSelectedFileNames([...tempSelectedFileNames]);
            }
        }
        setSelectedFiles(selectedFilesTable)
    };

    console.log("Resume table",selectedFilesTable)

    const handleDownloadCount = (process_file_url: string) => {
        updateDownloadCount({ useremail: currentUser?.email, process_file_url: process_file_url }).then((res) => {
        })
    }

    useEffect(() => {
        setSelectedFilesTable([])
        setSelectedFiles([])
      
    }, [formatting,bulkTab])


   
    console.log("resume table",selectedFilesTable.length)
    return (

        <div>
            <div
                className={formatting ? 'd-flex align-items-center justify-content-center' : 'd-none'}
                style={{
                    width: '100vw',
                    height: '100vh',
                    zIndex: 100,
                    backgroundColor: '#5e627861',
                    position: 'fixed',
                    top: 0,
                    left: 0,
                }}
            >
                {/* <Spinner animation="border" role="status" variant="light" style={{ width: '3rem', height: '3rem' }}>
                    <span className="visually-hidden">Loading...</span>
                </Spinner> */}
            </div>




            {/* Feedback Modal */}
            <div
                className="modal fade"
                id="kt_modal_show_feedback"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-centered mw-650px">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h2 className="fw-bolder">Document Feedback</h2>
                            <button
                                className="btn btn-sm btn-icon btn-active-color-primary"
                                data-bs-dismiss="modal"
                                type='button'
                            >
                                <KTIcon iconName="cross" className="fs-1" />
                            </button>
                        </div>
                        <div className="modal-body scroll-y mx-5 mx-xl-15 my-7">
                            {
                                (showFeedback == '' || showFeedback == null) ? <p className="fs-1 fw-bold text-center">No Feedback Provided</p> :
                                    <p className="fs-4">{showFeedback}</p>
                            }
                        </div>
                    </div>
                </div>
            </div>

            {/* Formated Resume Modal */}
            <div
                className="modal fade"
                id="kt_modal_formatted_resume"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-centered mw-850px">
                    <div className="modal-content" style={{ height: "80vh", overflowY: "scroll" }}>
                        <div className="modal-header">
                            <h2 className="fw-bolder">Processed Resume</h2>
                            <div>
                                {/* <button
                                    className="btn btn-sm btn-success me-3 my-3 w-180px "
                                    onClick={(e) => {
                                        e.preventDefault()
                                        toPDF()
                                    }}
                                >
                                    Download PDF
                                </button> */}
                                <button
                                    className="btn btn-sm btn-icon btn-danger"
                                    data-bs-dismiss="modal"
                                    type='button'
                                >
                                    <KTIcon iconName="cross" className="fs-1" />
                                </button>
                            </div>
                        </div>
                        <div className="modal-body scroll-y">
                            <div ref={targetRef}>
                                {resumeOutput && <Template1 candidateResumeData={resumeOutput} />}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className={`card ${className}`} style={{ boxShadow: '6px 6px 19px #0c101b, -6px -6px 19px #1c263f' }}>
                <div className='card-body  py-2'>
                    <div className='table-responsive' style={{ maxHeight: 'calc(100vh - 350px)', overflowY: 'auto' }}>
                        {
                            fetching ?
                                (
                                    <div className="row align-items-center">
                                        {

                                            <Blocks
                                                visible={true}
                                                height="80"
                                                width="80"
                                                ariaLabel="blocks-loading"
                                                wrapperStyle={{}}
                                                wrapperClass="blocks-wrapper"
                                            />
                                        }
                                    </div>
                                ) :
                                (
                                    <>
                                        {/* begin::Table */}
                                        <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
                                            {/* begin::Table head */}
                                            <thead>
                                                <tr className='fw-bold text-white-50 bg-light p-2'>

                                                    {resumeData.length > 0 && resumeData.some(data => data.processed !== 1) && (
                                                        <th className='w-25px'>
                                                            <div className='form-check form-check-sm form-check-custom form-check-solid'>
                                                                <input
                                                                    className='form-check-input'
                                                                    type='checkbox'
                                                                    value='1'
                                                                    data-kt-check='true'
                                                                    data-kt-check-target='.widget-13-check'
                                                                    
                                                                />
                                                            </div>
                                                        </th>
                                                    )}
                                                    {/* <th className='ps-4 min-w-50px rounded-start'></th> */}
                                                    <th className='ps-4 min-w-100px rounded-start'>Original File</th>
                                                    {type == 'processed' && <th className='min-w-120px'>Proccessed Date</th>}
                                                    {/* {type == 'processed' && <th className='min-w-120px'>Process Time</th>} */}
                                                    <th className='min-w-100px'>Source of Import</th>
                                                    <th className='min-w-100px'>Approval</th>
                                                    {/* <th className='min-w-100px'>Approval</th> */}
                                                    <th className='min-w-120px text-end px-5'>Actions</th>
                                                </tr>
                                            </thead>
                                            {/* end::Table head */}
                                            {/* begin::Table body */}
                                            <tbody>
                                                {resumeData.length > 0 ? (resumeData.map((resume) => (
                                                    <tr>
                                                        {resume.processed !== 1 && (
                                                            <td className='ps-4'>
                                                                <input
                                                                    className="form-check-input bg-light border-4 border-gray-400 border-start border-top"
                                                                    type="checkbox"
                                                                    value=""
                                                                    checked={selectedFilesTable.includes(resume.file_url)}
                                                                    onClick={(e) =>
                                                                        handleSelect(
                                                                            e,
                                                                            resume.file_url,
                                                                            resume.file_original_name,
                                                                            resume.processed
                                                                        )
                                                                    }
                                                                />
                                                            </td>
                                                        )}
                                                        <td >
                                                            {(processing && (fileLink && fileLink == resume.file_url)) ?
                                                                <span className="indicator-progress me-5" style={{ display: "block" }}>
                                                                    {/* Please wait...{" "} */}
                                                                    <span className="spinner-border align-middle ms-2 fs-3x"></span>
                                                                </span> :
                                                                <></>
                                                                // <div className='symbol symbol-50px me-5'>
                                                                //     <KTIcon iconName='file' className="fs-3x text-dark" />
                                                                // </div>
                                                            }
                                                            <div>
                                                                <div className='d-flex align-items-center justify-content-start'>
                                                                    <div className='d-flex justify-content-start flex-column'>
                                                                        <p
                                                                            style={{ cursor: 'pointer' }}
                                                                            className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'
                                                                            title={resume.file_original_name}
                                                                        >
                                                                            {/* {resume.original_file_name.substring(0, 20)} */}
                                                                            {(resume.file_original_name.lastIndexOf('.') > -1 && resume.file_original_name.lastIndexOf('.') <= 20) ? resume.file_original_name.substring(0, resume.file_original_name.lastIndexOf('.')) : resume.file_original_name.substring(0, 20)}

                                                                        </p>
                                                                        <span className='text-dark fw-bold d-block fs-8'>{new Date(resume.upload_date).toLocaleString()}</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        {type == 'processed' &&
                                                            <td>
                                                                <p
                                                                    style={{ cursor: 'pointer' }}
                                                                    onClick={(e) => {
                                                                        setResumeOutput(resume.output)
                                                                    }}
                                                                    data-bs-toggle="modal"
                                                                    data-bs-target="#kt_modal_formatted_resume"
                                                                    className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'
                                                                    title={resume.file_original_name}
                                                                >
                                                                    {new Date(resume.process_date).toDateString()}
                                                                </p>
                                                            </td>
                                                        }
                                                        {/* {
                                                            type == 'processed' &&
                                                            <td
                                                                className='text-dark fw-bold text-hover-primary fs-6'>
                                                                {
                                                                    // (
                                                                    //     resume.process_time.split(':').reduce((acc, curr, idx) =>
                                                                    //         acc + (Number(curr) * Math.pow(60, 2 - idx)) as number, 0) / 60
                                                                    // ).toFixed(2)
                                                                    resume.process_date.substring(resume.process_date.indexOf(':') + 1, resume.process_date.indexOf(':') + 6)
                                                                } min
                                                            </td>
                                                        } */}
                                                        <td>
                                                            {
                                                                resume.source_of_import == "web" ?
                                                                    <span className='badge rounded-pill text-bg-light fs-4'>Web App</span>
                                                                    : <span className='badge rounded-pill text-bg-primary fs-4'>OneDrive</span>
                                                            }
                                                        </td>
                                                        <td>
                                                            {!resume.approval || (resume.approval !== "rejected" && resume.approval !== "accepted") ?
                                                                <span className='badge badge-light-warning fs-4'>Pending</span>
                                                                :
                                                                resume.approval == 'rejected' ?
                                                                    <span className='badge badge-light-danger fs-4 text-capitalize'>{resume.approval}</span>
                                                                    : <span className='badge badge-light-success fs-4 text-capitalize'>{resume.approval}</span>
                                                            }
                                                            {/* <span className='text-muted fw-semibold text-muted d-block fs-7'>Insurance</span> */}
                                                        </td>
                                                        {/* <td>
                                                        {
                                                            resume.approval == "pending" || resume.approval == "" || resume.approval == null ?
                                                                <span className='badge badge-light-warning'>Pending</span>
                                                                : resume.approval == "accepted" ?
                                                                    <span className='badge badge-light-success'>Accepted</span>
                                                                    : <span className='badge badge-light-danger'>Rejected</span>
                                                        }
                                                    </td> */}
                                                        <td className='text-end px-4'>

                                                            {/* <a
                                                            type='button'
                                                            data-bs-toggle="modal"
                                                            data-bs-target="kt_modal_assign"
                                                            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                                                        >
                                                            <KTIcon iconName='file-right' className='fs-3' />
                                                        </a>
                                                        <a
                                                            type='button'
                                                            data-bs-toggle="modal"
                                                            data-bs-target="kt_modal_assign"
                                                            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                                                        >
                                                            <KTIcon iconName='shield-cross' className='fs-3' />
                                                        </a>
                                                        <a
                                                            type='button'
                                                            data-bs-toggle="modal"
                                                            data-bs-target="kt_modal_assign"
                                                            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                                                        >
                                                            <KTIcon iconName='send' className='fs-3' />
                                                        </a> */}

                                                            {
                                                                resume.processed === 1 &&
                                                                <>
                                                                    {
                                                                        <a
                                                                            href='#'

                                                                            onClick={(e) => {

                                                                                setSelectedFiles([])
                                                                                compare(resume.file_original_name, resume.file_url, resume.process_file_url, resume.output, resume.approval)
                                                                                // download(resume.output, resume.uploaded_file_url)
                                                                            }}
                                                                            className='btn btn-primary btn-sm w-120px fs-8 mx-1'

                                                                        >
                                                                            <KTIcon iconName='switch' className='fs-3' /> {" "} Validate
                                                                        </a>
                                                                    }
                                                                    {/* {
                                                                    resume.approval == "accepted" ?
                                                                        <a
                                                                            href={resume.process_file_url}
                                                                            onClick={(e) => handleDownloadCount(resume.process_file_url)}
                                                                            className='btn btn-success btn-sm w-120px fs-8 mx-1'
                                                                        >
                                                                            <KTIcon iconName='file-down' className='fs-3' /> {" "} Download
                                                                        </a> 
                                                                        : <button
                                                                            disabled={true}
                                                                            className='btn btn-secondary btn-sm w-120px fs-8 mx-1'
                                                                        >
                                                                            <KTIcon iconName='file-down' className='fs-3' /> {" "} Download
                                                                        </button>

                                                                } */}
                                                                    <a
                                                                        href='#'
                                                                        type='button'
                                                                        onClick={(e) => {
                                                                            download(resume.output, resume.file_url, resume.file_original_name)
                                                                            // handleDownloadCount(resume.process_file_url);
                                                                            // setResumeOutput(resume.output)
                                                                        }}
                                                                        className='btn btn-success btn-sm w-120px fs-8 mx-1'
                                                                    >
                                                                        <KTIcon iconName='file-down' className='fs-3' /> {" "} Download
                                                                    </a>
                                                                    {/* <button
                                                                    data-bs-toggle="modal"
                                                                    data-bs-target="#kt_modal_show_feedback"
                                                                    onClick={(e) => {
                                                                        setShowFeedback(resume.feedback)
                                                                    }}
                                                                    type='button'
                                                                    disabled={resume.feedback == null ? true : false}
                                                                    className={`btn btn-icon btn-icon-muted ${resume.feedback === null ? 'btn-secondary' : 'btn-warning'} btn-sm w-120px fs-8 mx-1`}
                                                                >
                                                                    <KTIcon iconName='information' className='fs-4 fw-bolder' />
                                                                </button> */}
                                                                </>
                                                            }
                                                            {
                                                                type == 'unprocessed' &&
                                                                // <ProgressBar
                                                                //     visible={true}
                                                                //     height="80"
                                                                //     width="80"
                                                                //     // color="#4fa94d"
                                                                //     ariaLabel="progress-bar-loading"
                                                                //     wrapperStyle={{}}
                                                                //     wrapperClass=""
                                                                // />
                                                                <button
                                                                    type="button"
                                                                    disabled={selectedFilesTable.length>0}
                                                                    onClick={(e) => {
                                                                        setLink(resume.file_url);
                                                                        setProcessingFile(resume.file_url)
                                                                        handleResumeProcess(resume.file_url);

                                                                    }}
                                                                    // data-bs-toggle="modal"
                                                                    // data-bs-target={currentUser?.username != 'forum' ? "#kt_modal_select_resume" : ""}
                                                                    className='btn btn-primary my-3 me-3 w-120px'

                                                                >
                                                                    {!formatting && <>
                                                                        <KTIcon iconName='pencil' className='fs-3' />
                                                                        {" "}
                                                                        Process
                                                                    </>}
                                                                    {formatting && (resume.file_url == processingFile ? (
                                                                        <span className="indicator-progress" style={{ display: "block" }}>
                                                                            Processing...
                                                                            {/* <span className="spinner-border spinner-border-sm align-middle ms-2"></span> */}
                                                                        </span>) :
                                                                        <>
                                                                            <KTIcon iconName='pencil' className='fs-3' />
                                                                            {" "}
                                                                            Process
                                                                        </>
                                                                    )}
                                                                </button>
                                                            }
                                                        </td>
                                                    </tr>
                                                ))) :
                                                    <div className="row align-items-center">
                                                        {/* <h4 className="text-center">No Resume Found. Please Process Your Resume</h4> */}
                                                    </div>
                                                }
                                            </tbody>
                                            {/* end::Table body */}
                                        </table>
                                        {/* end::Table */}
                                        {/* <div className='card-footer'>
                            <div className='d-flex flex-stack flex-wrap pt-10'>
                                <div className='fs-6 fw-bold text-gray-700'>Showing 1 to 5 of 15 entries</div>

                                <ul className='pagination'>
                                    <li className='page-item previous'>
                                        <a href='#' className='page-link'>
                                            <i className='previous'></i>
                                        </a>
                                    </li>

                                    <li className='page-item active'>
                                        <a href='#' className='page-link'>
                                            1
                                        </a>
                                    </li>

                                    <li className='page-item'>
                                        <a href='#' className='page-link'>
                                            2
                                        </a>
                                    </li>

                                    <li className='page-item'>
                                        <a href='#' className='page-link'>
                                            3
                                        </a>
                                    </li>

                                    <li className='page-item next'>
                                        <a href='#' className='page-link'>
                                            <i className='next'></i>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div> */}
                                    </>
                                )
                        }
                    </div>
                </div>
            </div>
        </div >
    )
}