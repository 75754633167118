import React, { useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { KTIcon, toAbsoluteUrl } from '../../../_metronic/helpers';
import { useAuth } from '../auth/core/Auth';

interface OneDriveImportModalProps {
    show: boolean;
    onClose: () => void;
}

interface Directory {
    id: string;
    name: string;
    subfolders: any[]; // Adjust the type of subfolders as needed
}

interface ResumeAutomationRequest {
    account: string;
    srcDir: string;
    destDir: string;
    template: string;
}

const templatesData = [
    {
        name: "Thirdeye",
        value: "thirdeye"
    },
    {
        name: "Forum",
        value: "forum"
    }
]

const resumeAutomationRequestInitialValue = {
    account: "",
    srcDir: "",
    destDir: "",
    template: ""
}

const OneDriveImportModal: React.FC<OneDriveImportModalProps> = ({ show, onClose }) => {
    const { currentUser } = useAuth();
    const [onedriveAccounts, setOnedriveAccounts] = useState<string[]>([]);
    const [selectedOnedriveAccount, setSelectedOnedriveAccount] = useState<string>("");
    const [directories, setDirectories] = useState<Directory[]>([]);
    const [fetchingDirectories, setFetchingDirectories] = useState<boolean>(false);
    const [resumeAutomationRequest, setResumeAutomationRequest] = useState<ResumeAutomationRequest>(resumeAutomationRequestInitialValue);
    const [saving, setSaving] = useState<boolean>(false)

    const handleSave = () => {
    //     setSaving(true)
    //     saveOnedriveFolder({
    //         "userName": currentUser?.username,
    //         "email": resumeAutomationRequest.account,
    //         "tag": "resume",
    //         "sourceDirectory": resumeAutomationRequest.srcDir,
    //         "destinationDirectory": resumeAutomationRequest.destDir,
    //         "sourceDirectoryName": directories.find(dir => dir.id === resumeAutomationRequest.srcDir)?.name || "",
    //         "destinationDirectoryName": directories.find(dir => dir.id === resumeAutomationRequest.destDir)?.name || "",
    //         "template": resumeAutomationRequest.template
    //     }).then((res) => {
    //         alert("Connection Successful");
    //         setSaving(false)
    //         onClose();
    //     }).catch((error) => {
    //         console.error("Error saving OneDrive folder:", error);
    //         alert("Connection failed. Please try again.");
    //         setSaving(false)
    //         onClose();
    //     });
    //     // alert(`Imported directory: ${selectedDirectory}`);
    };

    const handleAccountSelection = (e: any) => {
    //     setFetchingDirectories(true)
    //     setResumeAutomationRequest({
    //         ...resumeAutomationRequest, account: e.target.value
    //     })
    //     listOnedriveFolder({ email: e.target.value }).then((res) => {
    //         setDirectories(res.data)
    //         setFetchingDirectories(false)
    //     }).catch((e) => {
    //         alert("Error Occured")
    //         setFetchingDirectories(false)
    //     })
    }

    const handleClose = () => {
        setSelectedOnedriveAccount("")
        setDirectories([]);
        setResumeAutomationRequest(resumeAutomationRequestInitialValue)
        onClose();
    };

    useEffect(() => {
        // checkOnedriveAuthenticated({ email: currentUser?.email }).then((res) => {
        //     setOnedriveAccounts(res.data)
        // })
    }, [])

    return (
        <Modal show={show} onHide={handleClose} centered>
            <Modal.Header closeButton>
                <Modal.Title>Import from OneDrive</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {/* Step 1: Import from OneDrive */}
                <div className="card mb-4 p-4 bg-secondary">
                    <div className="d-flex align-items-center fs-4">
                        <img src={toAbsoluteUrl("/media/logos/onedrive-logo.png")} width={30} className="me-3" alt="OneDrive Logo" />
                        <span>Connect your OneDrive account</span>
                    </div>
                    <hr />
                    <select
                        className="form-select my-2"
                        aria-label="Select account"
                        onChange={(e) => { handleAccountSelection(e) }}
                        value={resumeAutomationRequest.account}
                    >
                        <option value={""}>Select the connection</option>
                        {
                            onedriveAccounts.map((dir) => (
                                <option value={dir}>{dir}</option>
                            ))
                        }
                    </select>
                    <div className="fs-6 mb-3">
                        {onedriveAccounts.length == 0 && "Currently, no accounts are linked."}
                        <a href={`https://platform.optira.ai:8002/authenticate_onedrive?email=${currentUser?.email}`} target='_blank' rel="noopener noreferrer">
                            Connect a new Account
                        </a>
                    </div>
                </div>

                {/* Step 2: Select Directory */}
                {resumeAutomationRequest.account !== "" && (
                    <div className="card mb-4 p-4 bg-secondary">
                        <div className="d-flex align-items-center fs-4">
                            <KTIcon iconName='folder' className='fs-1 me-3' />
                            <span>Configure directory</span>
                        </div>
                        <hr />
                        <div className="row">
                            <div className="col-md-6">
                                <label>Source Directory</label>
                                {fetchingDirectories ?
                                    <div>Fetching your directories ... </div> :
                                    <select
                                        className="form-select my-2"
                                        aria-label="Select directory"
                                        onChange={(e) => setResumeAutomationRequest({
                                            ...resumeAutomationRequest, srcDir: e.target.value
                                        })}
                                        value={resumeAutomationRequest.srcDir}
                                    >
                                        <option value="">Select the directory</option>
                                        {directories.map((directory) => (
                                            <option key={directory.id} value={directory.id}>
                                                {directory.name}
                                            </option>
                                        ))}
                                    </select>
                                }
                            </div>
                            <div className="col-md-6">
                                <label>Destination Directory</label>
                                {fetchingDirectories ?
                                    <div>Fetching your directories ... </div> :
                                    <select
                                        className="form-select my-2"
                                        aria-label="Select directory"
                                        onChange={(e) => setResumeAutomationRequest({
                                            ...resumeAutomationRequest, destDir: e.target.value
                                        })}
                                        value={resumeAutomationRequest.destDir}
                                    >
                                        <option value="">Select the directory</option>
                                        {directories.map((directory) => (
                                            <option key={directory.id} value={directory.id}>
                                                {directory.name}
                                            </option>
                                        ))}
                                    </select>
                                }
                            </div>
                        </div>
                    </div>
                )}

                {/* Step 3: Select Template */}
                {resumeAutomationRequest.account !== "" && (
                    <div className="card p-4 bg-secondary">
                        <div className="d-flex align-items-center fs-4">
                            <KTIcon iconName='file' className='fs-1 me-3' />
                            <span>Choose formatting template</span>
                        </div>
                        <hr />
                        <select
                            className="form-select my-2"
                            aria-label="Select directory"
                            onChange={(e) => setResumeAutomationRequest({
                                ...resumeAutomationRequest, template: e.target.value
                            })}
                            value={resumeAutomationRequest?.template}
                        >
                            <option value="">Select the directory</option>
                            {templatesData.map((template) => (
                                <option key={template.value} value={template.value}>
                                    {template.name}
                                </option>
                            ))}
                        </select>
                    </div>
                )}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="success"
                    onClick={handleSave}
                // disabled={Object.values(resumeAutomationRequest).every(value => value !== "")}
                >
                    {!saving ? (
                        <span className="indicator-label d-flex align-items-center">
                            <KTIcon iconName="save-2" className="fs-1" /> &nbsp;
                            Finsh and Save
                        </span>
                    ) : (
                        <span className="indicator-progress" style={{ display: 'block' }}>
                            Saving... <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                        </span>
                    )}
                </Button>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal >
    );
};

export default OneDriveImportModal;
