import { useEffect, useState } from "react";

import { Blocks } from "react-loader-spinner";
import { useParams } from "react-router-dom";
import { KTIcon } from "../../../_metronic/helpers";
import { getAllMember, memberRegister, memberStatistic, updateUser } from "../../api";
import { useAuth } from "../../modules/auth";
import { TeamTable } from "../../modules/teams/TeamTable";

// import { saveAs } from 'file-saver';

type Props = {};

type fileType = Array<string>;

const bgList = ["bg-success", "bg-primary", "bg-warning"];

type MyObject = {
    [key: string]: string[];
};

interface TeamMemberFormObject {
    username: string;
    email: string;
    password: string;
    organizationId?: number;
    type: string;
    subscribed: string;
}

interface TeamMemberObject {
    username: string;
    email: string;
    password: string;
    organizationId: number;
    type: string;
    organization_name: string;
    subscribed: string;
}

interface TeamAnalyticsObject {
    assign_to: string,
    processed_count: number,
    unprocessed_count: number,
    total_count: number,
    accepted_count: number,
    rejected_count: number,
    pending_count: number
}

export const Teams = ({ }: Props) => {
    const { currentUser } = useAuth();
    const { doctype } = useParams()

    const initialFormData: TeamMemberFormObject = {
        username: '', // Provide default values or leave empty based on your requirements
        email: '',
        password: '',
        organizationId: currentUser?.organizationId,
        type: '',
        subscribed: 'free_trail'
    };

    const [fetching, setFetching] = useState<boolean>(false)
    const [processing, setProcessing] = useState<boolean>(false)
    const [update, setUpdate] = useState<boolean>(false)
    const [fetchAnalytics, setFetchAnalytics] = useState<boolean>(false)

    const [responseMessage, setResponseMessage] = useState("");
    const [members, setMembers] = useState<TeamMemberObject[]>();

    const [formData, setFormData] = useState<TeamMemberFormObject>(initialFormData);
    const [memberAnalytics, setMemberAnalytics] = useState<TeamAnalyticsObject>({
        assign_to: '',
        processed_count: 0,
        unprocessed_count: 0,
        total_count: 0,
        accepted_count: 0,
        rejected_count: 0,
        pending_count: 0
    });

    const handleInputChange = (e: any) => {
        const { name, value } = e.target;

        setFormData(prevData => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleCreateMember = () => {
        setProcessing(true);
        try {
            memberRegister(formData).then((res) => {
                if (res.status === 200) {
                    // Reset the form data after successful registration
                    setFormData(initialFormData);
                } else {
                    // If response status is not OK, handle error
                    throw new Error(res.data.message || 'Unknown error occurred');
                }
                setProcessing(false);
                setFetching(true);
                setMembers([]);
                // getAllMember({ organizationId: currentUser?.organizationId }).then((res) => {
                //     const notSuperMember = res.data.filter((mem: TeamMemberObject) => mem.type !== 'superadmin');
                //     setMembers(notSuperMember);
                //     setFetching(false);
                // }).catch((error) => {
                //     console.error('Error while fetching members:', error);
                //     setResponseMessage('Error occurred while fetching members.');
                //     setFetching(false);
                // });
            }).catch((error) => {
                // Handle registration error
                console.error('Error while registering member:', error);
                if (error.response && error.response.status === 400) {
                    // If response status is 400, show an alert
                    alert('Bad request: ' + (error.response.data.message || 'Unknown error occurred'));
                } else {
                    // For other errors, log them
                    console.error('Error:', error);
                }
                setResponseMessage('Error occurred while registering user.');
                setFetching(false);
            });
        } catch (error) {
            console.error('Error:', error);
            setResponseMessage('Error occurred while registering user.');
            setFetching(false);
        }
    };

    const handleUpdateMember = () => {
        setProcessing(true)
        try {
            updateUser(formData).then((res) => {
                // setResponseMessage(res.data.message);
                setProcessing(false)
                setFetching(true)
                setUpdate(false)
                setMembers([])
                // getAllMember({ organizationId: currentUser?.organizationId }).then((res) => {
                //     const notSuperMember = res.data.filter((mem: TeamMemberObject) => mem.type !== 'superadmin')
                //     setMembers(notSuperMember);
                //     setFetching(false)
                // });
            })

            // Reset the form data after successful registration
            setFormData(initialFormData);
            setFetching(false)
        } catch (error) {
            console.error('Error:', error);
            setResponseMessage('Error occurred while registering user.');
            setFetching(false)
        }

    };

    const setUpdateUser = (user: TeamMemberObject) => {
        setUpdate(true)
        setFormData(user)
    }

    const setUserAnalytics = (user: TeamMemberFormObject) => {
        setFetchAnalytics(true)
        memberStatistic({ "org_id": currentUser?.organizationId, tag: 'resume' }).then((res: any) => {
            const desiredObject = res.data.find((item: TeamAnalyticsObject) => item.assign_to == user.email);
            setMemberAnalytics(desiredObject)
            setFetchAnalytics(false)
        })
    }

    // useEffect(() => {
    //     setFetching(true)
    //     getAllMember({ organizationId: currentUser?.organizationId }).then((res) => {
    //         const notSuperMember = res.data.filter((mem: TeamMemberObject) => mem.type !== 'superadmin')
    //         setMembers(notSuperMember);
    //         setFetching(false)
    //     });
    // }, []);


    return (
        <div>

            {/* Create Team Member */}
            <div
                className="modal fade"
                id="kt_modal_team"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-centered mw-650px">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h2 className="fw-bolder">Create Team Member for {currentUser?.organization_name}</h2>
                            <div
                                className="btn btn-sm btn-icon btn-active-color-primary"
                                data-bs-dismiss="modal"
                                onClick={(e) => setUpdate(false)}
                            >
                                <KTIcon iconName="cross" className="fs-1" />
                            </div>
                        </div>
                        <div className="modal-body scroll-y">
                            <div
                                className={`card card-xl-stretch`}
                                style={{ maxHeight: "60vh", overflowY: "scroll" }}
                            >
                                <div className="row">
                                    <div className="col-12">
                                        {/* <div className="row mb-6">
                                            <label className="col-lg-3 col-form-label fw-bold fs-6">
                                                <span className="">Organization Id</span>
                                            </label>

                                            <div className="col-lg-9 fv-row">
                                                <input
                                                    type="text"
                                                    // className="form-select form-select-solid"
                                                    className='form-control'
                                                    readOnly
                                                    value={currentUser?.organizationId}
                                                    // onChange={handleInputChange}
                                                    name="organizationId"
                                                />

                                            </div>
                                        </div> */}
                                        <div className="row mb-6">
                                            <label className="col-lg-3 col-form-label fw-bold fs-6">
                                                <span className="">Member Email</span>
                                            </label>

                                            <div className="col-lg-9 fv-row">
                                                <input
                                                    type="text"
                                                    disabled={update}
                                                    className='form-control'
                                                    value={formData?.email}
                                                    onChange={handleInputChange}
                                                    name="email"

                                                />

                                            </div>
                                        </div>
                                        <div className="row mb-6">
                                            <label className="col-lg-3 col-form-label fw-bold fs-6">
                                                <span className="">Member Name</span>
                                            </label>

                                            <div className="col-lg-9 fv-row">
                                                <input
                                                    type="text"
                                                    // className="form-select form-select-solid"
                                                    className='form-control'
                                                    value={formData?.username}
                                                    onChange={handleInputChange}
                                                    name="username"

                                                />
                                            </div>
                                        </div>

                                        <div className="row mb-6">
                                            <label className="col-lg-3 col-form-label fw-bold fs-6">
                                                <span className="">Password</span>
                                            </label>

                                            <div className="col-lg-9 fv-row">
                                                <input
                                                    type={update ? "text" : "password"}
                                                    // className="form-select form-select-solid"
                                                    className='form-control'
                                                    value={formData?.password}
                                                    onChange={handleInputChange}
                                                    name="password"
                                                />

                                            </div>
                                        </div>
                                        <div className="row mb-6">
                                            <label className="col-lg-3 col-form-label fw-bold fs-6">
                                                <span className="">Member Type</span>
                                            </label>

                                            <div className="col-lg-9 fv-row">
                                                <select
                                                    className="form-select form-select-solid"
                                                    data-kt-select2="true"
                                                    data-placeholder="Select option"
                                                    data-allow-clear="true"
                                                    value={formData?.type}
                                                    name="type"
                                                    onChange={handleInputChange}
                                                >
                                                    <option value="">---Select User Type---</option>
                                                    <option value="admin">Admin</option>
                                                    <option value="regular">Regular</option>
                                                </select>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                            </div>
                            <div className="my-2 px-2 text-center d-flex justify-content-end">
                                <button
                                    className="btn btn-lg btn-success"
                                    data-bs-dismiss="modal"
                                    onClick={update ? handleUpdateMember : handleCreateMember}
                                >
                                    {!processing && <span className="indicator-label">{update ? "Update" : "Save"}</span>}
                                    {processing && (
                                        <span className="indicator-progress" style={{ display: "block" }}>
                                            Please wait...{" "}
                                            <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                        </span>
                                    )}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Create Team Member Analytics */}
            <div
                className="modal fade"
                id="kt_modal_team_analytics"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-centered mw-450px">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h2 className="fw-bolder">Team Member Analytics</h2>
                            <div
                                className="btn btn-sm btn-icon btn-active-color-primary"
                                data-bs-dismiss="modal"
                                onClick={(e) => setUpdate(false)}
                            >
                                <KTIcon iconName="cross" className="fs-1" />
                            </div>
                        </div>
                        <div className="modal-body scroll-y">
                            <div
                                className={`card card-xl-stretch`}
                                style={{ maxHeight: "60vh", overflowY: "scroll" }}
                            >
                                <div className="row">
                                    {
                                        fetchAnalytics ?
                                            <Blocks
                                                visible={true}
                                                height="80"
                                                width="80"
                                                ariaLabel="blocks-loading"
                                                wrapperStyle={{}}
                                                wrapperClass="blocks-wrapper"
                                            /> :
                                            <div className="col-12">
                                                <div className="row mb-2 align-items-end">
                                                    <div className="col-lg-8 col-form-label fw-bold fs-6">
                                                        <span className="">Documents Assigned</span>
                                                    </div>

                                                    <div className="col-lg-4">
                                                        <p>{memberAnalytics.total_count}</p>
                                                    </div>
                                                </div>
                                                <div className="row mb-2 align-items-end">
                                                    <div className="col-lg-8 col-form-label fw-bold fs-6">
                                                        <span className="">Proccesed Documents</span>
                                                    </div>

                                                    <div className="col-lg-4 fv-row">
                                                        <p>{memberAnalytics.processed_count}</p>
                                                    </div>
                                                </div>
                                                <div className="row mb-2 align-items-end">
                                                    <div className="col-lg-8 col-form-label fw-bold fs-6">
                                                        <span className="">Unproccesed Documents</span>
                                                    </div>

                                                    <div className="col-lg-4 fv-row">
                                                        <p>{memberAnalytics.unprocessed_count}</p>
                                                    </div>
                                                </div>
                                                <div className="row mb-2 align-items-end">
                                                    <div className="col-lg-8 col-form-label fw-bold fs-6">
                                                        <span className="">Documents Accepted</span>
                                                    </div>

                                                    <div className="col-lg-4 fv-row">
                                                        <p>{memberAnalytics.accepted_count}</p>
                                                    </div>
                                                </div>
                                                <div className="row mb-2 align-items-end">
                                                    <div className="col-lg-8 col-form-label fw-bold fs-6">
                                                        <span className="">Documents Rejected</span>
                                                    </div>

                                                    <div className="col-lg-4 fv-row">
                                                        <p>{memberAnalytics.rejected_count}</p>
                                                    </div>
                                                </div>
                                                <div className="row mb-2 align-items-end">
                                                    <div className="col-lg-8 col-form-label fw-bold fs-6">
                                                        <span className="">Documents with Pending Aproval</span>
                                                    </div>

                                                    <div className="col-lg-4 fv-row">
                                                        <p>{memberAnalytics.pending_count}</p>
                                                    </div>
                                                </div>

                                            </div>
                                    }
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="card card-xl-stretch mb-xl-8 bg-light" style={{ borderRadius: '0px', minHeight: '90vh' }}>
                {/* begin::Header */}
                <div className="card-header border-0 pt-5">
                    <h3 className="card-title align-items-start flex-column">
                        <span className="card-label fw-bold text-dark mb-2">
                            {" "}
                            {currentUser?.organization_name} Team Members
                        </span>
                        <span className="text-muted mt-1 fw-semibold fs-7">
                            Create New <strong> Team Member</strong>
                        </span>
                    </h3>
                </div>
                {/* end::Header */}


                {/* begin: Button Row */}
                <div className="row">
                    <div
                        className="d-flex justify-content-end my-1"
                        data-kt-user-table-toolbar="base"
                    >
                        <div className="px-5">
                            <button
                                type="button"
                                className="btn btn-primary me-3 my-3 w-180px "
                                data-bs-toggle="modal"
                                data-bs-target="#kt_modal_team"
                            // onClick={(e) => setCloseModal(false)}
                            >
                                <KTIcon iconName="plus" className="fs-3" />
                                Add Member
                            </button>
                        </div>
                    </div>
                </div>
                {/* end: Button Row */}

                <div className="row d-flex justify-content-between position-relative mx-5">
                    <div className="col-sm-12 col-md-12">
                        <div
                            className={`card card-xl-stretch mb-5 mb-xl-12`}
                            style={{ maxHeight: "70vh", overflowY: "scroll" }}
                        >
                            {members && members.length > 0 ? (
                                <TeamTable className='mb-5 mb-xl-8' userDetails={members} setUpdateUser={setUpdateUser} setUserAnalytics={setUserAnalytics} />
                            ) : (
                                <div className="row align-items-center">
                                    {
                                        fetching ?
                                            <Blocks
                                                visible={true}
                                                height="80"
                                                width="80"
                                                ariaLabel="blocks-loading"
                                                wrapperStyle={{}}
                                                wrapperClass="blocks-wrapper"
                                            />
                                            : <div className="card-body pt-5">
                                                <p className="fs-3 fw-bold text-center">No Team Members Created</p>
                                            </div>
                                    }
                                </div>
                            )}

                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
