import React, { useEffect, useState } from 'react';
import { ResumeTable } from './ResumeTable';
import clsx from "clsx";
import { useAuth } from '../auth';
import { KTIcon, toAbsoluteUrl } from '../../../_metronic/helpers';
import { ResumeLibraryProps } from './ResumeFormattingModel';
import { Blocks } from 'react-loader-spinner';


const ResumeLibrary: React.FC<ResumeLibraryProps> = ({
    tabProp,
    resumeData,
    unResumeData,
    handleSetLink,
    fetching,
    formatting,
    processAction,
    targetRef,
    toPDF,
    handleResumeProcess,
    compare,
    download,
    setRefresh,
    handleDocumentApproval,
    setSelectedFiles,
    setTabButton,
    setTab,
    bulkTab,
}) => {
    const { currentUser } = useAuth();
    const [tab, setTabLibrary] = useState("processed");
    const [selectedFiles, setLocalSelectedFiles] = useState<string[]>([]);
    const [processedResume, setProcessedResume] = useState(resumeData)
    const [unProcessedResume, setUnprocessedResume] = useState(unResumeData)
    const [searchQuery, setSearchQuery] = useState<string>("");
    // Function to update selected files
    const updateSelectedFiles = (files: string[]) => {
        setLocalSelectedFiles(files);
        setSelectedFiles(() => [...files]); // Update the selected files in the parent component
        console.log("resume library", selectedFiles)
    };


    useEffect(() => {
        setProcessedResume(resumeData)
        setUnprocessedResume(unResumeData)
    }, [resumeData, unResumeData])
    const filterByName = (e: any) => {
        setSearchQuery(e.target.value)
    }

    useEffect(() => {
        if (bulkTab || formatting) {
            setTab("unprocessed")
        }
    }, [bulkTab, formatting])
    useEffect(() => {
        console.log(tabProp)
        setTabLibrary(tabProp)
    }, [tabProp])

    useEffect(() => {
        setSelectedFiles(selectedFiles);
    }, [selectedFiles])

    useEffect(() => {
        setProcessedResume(resumeData);
        setUnprocessedResume(unResumeData);

        console.log("filtered docs", resumeData, unResumeData)

        if (tab == 'processed') {
            console.log(searchQuery)
            const filterBySearch = resumeData.filter((item) => {
                if (item.file_original_name && item.file_original_name.toLowerCase()
                    .includes(searchQuery.toLowerCase())) { return item; }
            })
            setProcessedResume(filterBySearch);
        } else {

            const filterBySearch = unResumeData.filter((item) => {
                if (item.file_original_name && item.file_original_name.toLowerCase()
                    .includes(searchQuery.toLowerCase())) { return item; }
            })
            setUnprocessedResume(filterBySearch);
        }

    }, [searchQuery, tab])

    setTabButton(tab);
    // Pagination state
    const [currentPage, setCurrentPage] = useState(1);
    const documentsPerPage = 5;

    // Calculate the total number of pages
    const totalResumeData = Math.ceil(processedResume.length / documentsPerPage);
    const totalUnresumeData = Math.ceil(unProcessedResume.length / documentsPerPage);

    // Slice the documents to be displayed on the current page
    const currentResumeData = processedResume.slice((currentPage - 1) * documentsPerPage, currentPage * documentsPerPage);
    const currentUnresumeData = unProcessedResume.slice((currentPage - 1) * documentsPerPage, currentPage * documentsPerPage);


    // Handle page change
    const handlePageChange = (page: number) => {
        if (page > 0 && page <= (tab === "processed" ? totalResumeData : totalUnresumeData)) {
            setCurrentPage(page);
        }
    };

    useEffect(() => {
        setCurrentPage(1)
    }, [tab])


    return (

        fetching ? (
            <>
                <div
                    className={fetching ? 'd-flex align-items-center justify-content-center' : 'd-none'}
                    style={{
                        width: '100vw',
                        height: '100vh',
                        zIndex: 100,
                        backgroundColor: '#5e627861',
                        position: 'fixed',
                        top: 0,
                        left: 0,
                    }}
                >

                </div>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Blocks
                        visible={true}
                        height="80"
                        width="80"
                        ariaLabel="blocks-loading"
                        wrapperStyle={{}}
                        wrapperClass="blocks-wrapper"
                    />
                </div>
            </>
        ) : (
            <>
                <div className="col-sm-12 col-md-12">
                    <div className="card mb-5 mb-xl-8 mx-5">
                        <div className="card-header card-header-stretch">
                            <ul
                                className="nav nav-stretch nav-line-tabs fw-bold border-transparent flex-nowrap"
                                role="tablist"
                            >
                                <li className="nav-item">
                                    <a
                                        className={clsx(`nav-link cursor-pointer text-white-50`, {
                                            active: tab === "processed"
                                        })}
                                        onClick={() => setTabLibrary("processed")}
                                        role="tab"
                                        style={
                                            tab === "processed"
                                                ? { borderBottom: "4px solid #fff" }
                                                : {}
                                        }
                                    >
                                        Processed Resume
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a
                                        className={clsx(`nav-link cursor-pointer text-white-50`, {
                                            active: tab === "unprocessed"
                                        })}
                                        onClick={() => setTabLibrary("unprocessed")}
                                        role="tab"
                                        style={
                                            tab === "unprocessed"
                                                ? { borderBottom: "4px solid #fff" }
                                                : {}
                                        }
                                    >
                                        Unprocessed Resumes
                                    </a>
                                </li>
                            </ul>

                            {/* <div className='row my-3'> */}
                            <div className='ms-auto d-flex align-items-center'>
                                <div className="input-group">
                                    <span className="input-group-text" id="basic-addon1">
                                        <KTIcon className='fs-2' iconName={'magnifier'} />
                                    </span>
                                    <input
                                        type="text"
                                        className="form-control w-120px"
                                        placeholder="Search Resume"
                                        aria-label="Search"
                                        aria-describedby="basic-addon1"
                                        onChange={(e) => filterByName(e)}
                                        

                                    />
                                    {/* <UsersListFilter /> */}
                                </div>
                            </div>
                            {/* </div> */}
                            {/* <div className='col-auto'>
                                {
                                    (currentUser?.usertype == 'superadmin' && selectedFiles.length > 0) &&
                                    <button
                                        type="button"
                                        disabled={selectedFiles.length == 0}
                                        className="btn btn-sm btn-primary me-3 w-180px fs-4"
                                        data-bs-toggle="modal"
                                        data-bs-target="#kt_modal_assign_review"
                                    >
                                        {
                                            processAction == "assinging" ?
                                                <span className="indicator-progress" style={{ display: "block" }}>
                                                    Processing...{" "}
                                                    <span className="spinner-border align-middle fs-6"></span>
                                                </span>
                                                : <span className='d-flex align-items-center justify-content-between'>
                                                    <img src={toAbsoluteUrl("/media/custom-icon/assign-file.png")} alt="Thirdeye data" height={22} /> &nbsp;
                                                    Assign
                                                </span>
                                        }
                                    </button>
                                }
                            </div> */}
                        </div>
                        <form className="form">
                            <div className="card-body">
                                <div className="tab-content pt-3">
                                    <div className={clsx("tab-pane", { active: tab === "processed" })}>
                                        {currentResumeData.length > 0 ? (
                                            <ResumeTable
                                                targetRef={targetRef}
                                                toPDF={toPDF}
                                                className="mb-5 mb-xl-8"
                                                resumeData={currentResumeData}
                                                setLink={handleSetLink}
                                                type="processed"
                                                fetching={fetching}
                                                formatting={formatting}
                                                handleResumeProcess={handleResumeProcess}
                                                compare={compare}
                                                download={download}
                                                setRefresh={setRefresh}
                                                setSelectedFiles={updateSelectedFiles}
                                                bulkTab={bulkTab}

                                            />
                                        ) : (
                                            <div className="card-body pt-5">
                                                <p className="fw-bold fs-2 text-center">
                                                    No Files Present.
                                                </p>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className="tab-content pt-3">
                                    <div className={clsx("tab-pane", { active: tab === "unprocessed" })}>
                                        {currentUnresumeData.length > 0 ? (
                                            <ResumeTable
                                                targetRef={targetRef}
                                                toPDF={toPDF}
                                                className="mb-5 mb-xl-8"
                                                resumeData={currentUnresumeData}
                                                setLink={handleSetLink}
                                                type="unprocessed"
                                                fetching={fetching}
                                                formatting={formatting}
                                                handleResumeProcess={handleResumeProcess}
                                                compare={compare}
                                                download={download}
                                                setRefresh={setRefresh}
                                                setSelectedFiles={updateSelectedFiles}
                                                bulkTab={bulkTab}


                                            />
                                        ) : (
                                            <div className="card-body pt-5">
                                                <p className="fw-bold fs-2 text-center">
                                                    No Files Present.
                                                </p>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </form>
                        <div className='card-footer'>
                            <div className='d-flex flex-stack flex-wrap'>
                                <div className='fs-6 fw-bold text-gray-700'>

                                    {((tab === "processed" && processedResume.length === 0) ||
                                        (tab === "unprocessed" && unProcessedResume.length === 0))
                                        ? "Showing 0 to 0 of 0 Results"
                                        : `Showing ${(currentPage - 1) * documentsPerPage + 1} to ${Math.min(
                                            currentPage * documentsPerPage,
                                            tab === "processed" ? processedResume.length : unProcessedResume.length
                                        )} of ${tab === "processed" ? processedResume.length : unProcessedResume.length
                                        } Results`}
                                </div>
                                <ul className='pagination'>
                                    <li className='page-item previous'>
                                        <a href='#' className='page-link' onClick={() => handlePageChange(currentPage - 1)}>
                                            <i className='previous'></i>
                                        </a>
                                    </li>
                                    {[...Array(tab === "processed" ? totalResumeData : totalUnresumeData)].map((_, index) => (
                                        <li key={index} className={clsx('page-item', { active: index + 1 === currentPage })}>
                                            <a href='#' className='page-link' onClick={() => handlePageChange(index + 1)}>
                                                {index + 1}
                                            </a>
                                        </li>
                                    ))}
                                    <li className='page-item next'>
                                        <a href='#' className='page-link' onClick={() => handlePageChange(currentPage + 1)}>
                                            <i className='next'></i>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    );

};

export default ResumeLibrary;
