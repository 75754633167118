import { useState } from 'react';
import { Link } from 'react-router-dom';
import { MixedWidgetDocument } from '../../../_metronic/partials/widgets/mixed/MixedWidgetDocument';

type Props = {}

const data = [
  {
    link: "invoice",
    svgIcon: "document",
    title: "Invoices",
    description: "Extract invoice ID, customer details, vendor details, ship to, bill to, total tax, subtotal, line items and more.",
    disabbled: false
  },
  {
    link: "bankstatement",
    svgIcon: "cheque",
    title: "Bank Statements",
    description: "Extract customer ID, customer details, account statement and Dump into csv, sql table etc",
    disabbled: false
  },
  {
    link: "handwritten",
    svgIcon: "message-text",
    title: "Handwritten",
    description: "Extract key value pairs and structure like tables and selection marks from any form or document.",
    disabbled: false
  },
  {
    link: "fax",
    svgIcon: "note",
    title: "Fax",
    description: "Extract invoice ID, customer details, vendor details and contact details from Fax documents.",
    disabbled: false
  },
  {
    link: "vouchers",
    svgIcon: "some-files",
    title: "Vouchers",
    description: "Extract Amount and Gift from Vouchers and vendor details from Gift Cards, and Coupons.",
    disabbled: false
  },
  {
    link: "receipt",
    svgIcon: "receipt-square",
    title: "Receipt",
    description: "Extract receipt ID, customer details, amount details and vendor details from receipts.",
    disabbled: false
  },

]


export default function DocumentTypes({ }: Props) {
  const [searchQuery, setSearchQuery] = useState<string>("");

  const handleTagSearch = () => {
    // setSearchResult([]);
    // setProcessing(true);
    // sharmanSemantic({ search_text: searchQuery }).then((res) => {
    //     setSearchResult(res.data);
    //     setProcessing(false);
    // });
  };



  return (
    <div className='m-3'>
      {/* <h1>Intelligent Document Processing</h1> */}

      <div className="d-flex align-items-center justify-content-between position-relative my-5 w-800px m-auto">
        <i className="ki-duotone ki-magnifier fs-1 position-absolute ms-6">
          <span className="path1"></span>
          <span className="path2"></span>
        </i>
        <input
          type="text"
          data-kt-user-table-filter="search"
          className="form-control form-control-solid w-md-100 ps-14 border border-dark border-2"
          placeholder="Search here"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
        <div
          className="d-flex justify-content-end my-1"
          data-kt-user-table-toolbar="base"
        >
          <button
            type="button"
            className="btn btn-primary me-3 w-200px mx-2"
            onClick={handleTagSearch}
          >
            Search
          </button>
        </div>
      </div>

      {/* begin::Row */}
      <div className='row g-5 g-xl-8 mt-5'>
        {
          data.map((doctype) => (
            <div className='col-xl-4'>
              <Link to={`/documentprocessing/${doctype.link}`}>
                <MixedWidgetDocument
                  className='card-xl-stretch mb-xl-8'
                  tag={doctype.link}
                  svgIcon={doctype.svgIcon}
                  color='light'
                  iconColor='white'
                  title={doctype.title}
                  titleColor='dark'
                  description={doctype.description}
                  descriptionColor='dark'
                />
              </Link>
            </div>
          ))
        }
      </div>
      {/* end::Row */}

    </div>
  )
}