import { ResumeDetails } from "../modules/resumeformatting/ResumeFormattingModel";

export const resumeFileInfo: { status: boolean, data: ResumeDetails[], message: string, status_code: number } = {
    "status": true,
    data: [
        {
            "id": 160,
            "upload_date": "2024-10-20T03:13:59",
            "process_date": "2024-10-20T00:00:00",
            "file_original_name": "477360000003123064_1612302169255.docx",
            "processed_file_name": null,
            "file_url": "https://formrecognizer12345.blob.core.windows.net/form-recognizer/optira_documents/Demo/resume/20241111_084356_477360000003123064_1612302169255.docx",
            "processed": 1,
            "process_file_url": null,
            "process_time": "P0DT00H01M11S",
            "useremail": "demo@thirdeyedata.ai",
            "tag": "resume",
            "assign_to": null,
            "feedback": null,
            "approval": "pending",
            "download_count": null,
            "output": {
                "name": "Saumen Kumar Porel",
                "location": "",
                "current_designation": "",
                "email": "saumenporel@gmail.com",
                "linkedin_id": "https://linkedin.com/in/saumen-kumar-porel-97a0696b",
                "phone": "8583923611",
                "summary": [
                    "Around 2 Yrs experience in US staffing, looking for opportunity in Sr. Recruiter role.",
                    "Through the exposure of the USA IT recruitment process.",
                    "Possess excellent knowledge of recruiting and hiring processes including analysing requirements, sourcing resume, salary negotiation, submission, Interview process, and closing.",
                    "Understanding the client requirements, coordinating for short listing and screening including preliminary interview of the candidates.",
                    "Worked on different kind of IT requirements, Software Engineers/Architects, Database.",
                    "Exposure in different methods of hiring FTE, C2H, C2C, and Corp to Corp, W2 and 1099 tax terms.",
                    "Recruiting US Citizen, Green Card (GC), H1B, H4 EAD, TN Canadian Citizen, OPT/CPT for different contract, contract to hire (C2H) and fulltime position.",
                    "Handled all the IT Technologies involving combination of complex skill sets and niche technologies.",
                    "Experience in effective sourcing strategies to source resumes, utilizing web recruitment tools Monster, LinkedIn, personal database, reference and mass mailing system Prohires, other non-conventional methods of the resource.",
                    "Experience in negotiating salary/ rate with consultants as well with employers.",
                    "Proven ability to self-manage multiple positions by sourcing, screening, coordinating interviews, negotiating and closing candidates.",
                    "Utilize a collaborative consultative approach and demonstrate a commitment to exceeding client expectations.",
                    "Self-starter and able to achieve targets without any supervision.",
                    "Developing-Maintaining rapport with vendors and consultants.",
                    "Dealing with all levels of candidates, Junior, Mid-level and Senior consultants as prescribed in JD.",
                    "Experience in sourcing profiles for different technologies, multiple requirements.",
                    "Clearly communicated all aspects of the offer including salary, benefits, bonuses, relocating, etc.",
                    "Proven experience in mining right consultants who is actively looking for the opportunity in social websites like LinkedIn."
                ],
                "objective": "",
                "work_experience": [
                    {
                        "title": "US IT Recruiter",
                        "company": "3MK Software Solutions LLC",
                        "location": "",
                        "start_time": "Sept 2018",
                        "end_time": "Present",
                        "responsibilities": [
                            "Getting requirements from the source, Prioritize for the day as per client urgency.",
                            "Understand the requirement, Applying different strategies in sourcing most appropriate resume from the internal database, job portals, vendors and from the LinkedIn.",
                            "Short-listing the most suitable CVs for the position.",
                            "Technical screening of candidates, interacting with the candidates to understand their Capabilities, strengths, limitations, Conducting telephonic interviews and discusses the profile with the candidates.",
                            "Negotiate rates & terms of the contract with the vendors and consultants.",
                            "Format resumes, Submitting with all the necessary information in the prescribed format.",
                            "Schedule & coordinating Interviews for the candidates shortlisted by the clients.",
                            "Ensuring that proper Follow up of selected candidates happens until their joining.",
                            "Building resume database for upcoming/highly potential requirements.",
                            "To act as an Interface in every stage of the recruitment process from the client requirement to placing the consultant.",
                            "Maintaining daily, weekly and monthly reports.",
                            "Coordinating with my BDM / Manager in every stage, run the team in their absence whenever it\u2019s required, and get it done all the work smoothly.",
                            "Worked with various consultants who are on H1B, EAD, Green Card, US Citizen, TN Visa.",
                            "Well versed in using job boards like Monster and professional social networking sites like LinkedIn.",
                            "Excellent understanding on complete life cycle of IT recruiting process that includes a detailed and thorough understanding of position requirements, searching for consultants, negotiating rates, coordinating interviews, and offering any other assistance needed for a successful start.",
                            "Phenomenal negotiation skills, excellent verbal and written communication skills, presentation skills and interpersonal skills with hands on experience in a team playing, constantly changing and multi-tasking environment.",
                            "Successfully sourced talented technical consultants with the skill set, workday, java, .Net, Tableau, Analytics, Business Analyst, SAP ABAP, SAP BO, SAP FICO, SAP PM/CS, SAP PP/QM, Oracle developer, Oracle DBA, .Net, Project Manager, Project Lead, Project coordinator, Java Architect, SAP Basis, SAP Data Migration, Informatica, Datastage.",
                            "Motivated and independent with managing time, resources, driving processes, troubleshooting.",
                            "Urgent time-sensitive requests with quick response to deliver results effectively.",
                            "Highly team oriented, receptive to feedback and collaborating with others in developing strategy.",
                            "To approach tasks with a sense of urgency."
                        ],
                        "remaining_related_data": {}
                    },
                    {
                        "title": "Assistant Manager",
                        "company": "Gillanders Arbuthnot & Co. Ltd. (GACL)",
                        "location": "",
                        "start_time": "July 2013",
                        "end_time": "Dec 2016",
                        "responsibilities": [
                            "Analyzing Projects Supplied by the Client. Checking project planning with respect to site conditions.",
                            "Preparing Material Requirement List for particular projects.",
                            "Evaluation of company\u2019s practice, process and procedures with respect to ISO: 9001, 18001, 14001, EFSIS and local Standards. Initiation of new or improved practice, process and procedures.",
                            "Make Checklist for clearance of Bill. Cross Check Contractors billing quantity & amount."
                        ],
                        "remaining_related_data": {}
                    },
                    {
                        "title": "Executive Officer (Forming & Packing)",
                        "company": "AL TAJIR GLASS INDUSTRIES LLC",
                        "location": "DUBAI, U.A.E",
                        "start_time": "May 2007",
                        "end_time": "May 2012",
                        "responsibilities": [
                            "Expertise in operation management & implementing best practice with focus on profitability by ensuring optimal utilization of all equipment & resources and enhance operational efficiency.",
                            "Co-ordination with planning department for timely submission of Product as per Production Planning Control. Coordinate with Purchase Department."
                        ],
                        "remaining_related_data": {}
                    },
                    {
                        "title": "Senior Engineer",
                        "company": "TITAGARH WAGON LTD",
                        "location": "HEAVY ENGINEERING DIVISION",
                        "start_time": "May 2005",
                        "end_time": "April 2007",
                        "responsibilities": [
                            "Highly skilled in managing Operations involving planning, in-process inspection, team building and internal departmental coordination.",
                            "An effective communicator with exceptional analytical, relationship."
                        ],
                        "remaining_related_data": {}
                    },
                    {
                        "title": "Engineer",
                        "company": "CRESCENT FOUNDRY CO. (P) LTD & BHUSHAN STEEL LTD",
                        "location": "",
                        "start_time": "July 2002",
                        "end_time": "April 2005",
                        "responsibilities": [
                            "Achieved Production target. Experience in all aspects of Quality control & Quality assurance of various processes & finished product.",
                            "Responsible for effective Production plan for meeting customer requirements. Coordinate with Third Party inspection Authority."
                        ],
                        "remaining_related_data": {}
                    }
                ],
                "education": [
                    {
                        "degree": "Mechanical Engineering",
                        "university": "West Bengal State Council for Technical Education (WBSCTE)",
                        "location": "",
                        "start_time": "",
                        "end_time": "",
                        "remaining_related_data": {
                            "MMM YYYY-MMM YYYY": ""
                        }
                    }
                ],
                "skills": [
                    {
                        "heading": "Operating Systems",
                        "related_data": [
                            "Windows XP",
                            "Windows 8"
                        ]
                    },
                    {
                        "heading": "Operating Tools",
                        "related_data": [
                            "MS Office"
                        ]
                    },
                    {
                        "heading": "Tools & Technologies",
                        "related_data": [
                            "workday",
                            "java",
                            ".Net",
                            "Tableau",
                            "Analytics",
                            "Business Analyst",
                            "SAP ABAP",
                            "SAP BO",
                            "SAP FICO",
                            "SAP PM/CS",
                            "SAP PP/QM",
                            "Oracle developer",
                            "Oracle DBA",
                            "Project Manager",
                            "Project Lead",
                            "Project coordinator",
                            "Java Architect",
                            "SAP Basis",
                            "SAP Data Migration",
                            "Informatica",
                            "Datastage"
                        ]
                    }
                ],
                "certifications": {
                    "certification_details": "",
                    "date": "",
                    "remaining_related_data": {}
                },
                "projects": {
                    "project_name": "",
                    "technologies": "",
                    "link": "",
                    "time_period": "",
                    "remaining_related_data": {}
                },
                "academic_achievements": "",
                "declaration": {
                    "declaration": "",
                    "signature": "",
                    "date": ""
                },
                "additional": {}
            },
            "process_file_name": null,
            "docx_status": "0",
            "pdf_status": "0",
            "forum_process_url": null,
            "ted_process_url": null,
            "blob_location": "optira_documents/Demo/resume/20241111_084356_477360000003123064_1612302169255.docx",
            "source_of_import": "web",
        },
        {
            "id": 159,
            "upload_date": "2024-10-21T08:13:59",
            "process_date": "2024-10-21T00:00:00",
            "file_original_name": "477360000003123039_1612252853916.docx",
            "processed_file_name": null,
            "file_url": "https://formrecognizer12345.blob.core.windows.net/form-recognizer/optira_documents/Demo/resume/20241111_084356_477360000003123039_1612252853916.docx",
            "processed": 1,
            "process_file_url": null,
            "process_time": "P0DT00H00M39S",
            "useremail": "demo@thirdeyedata.ai",
            "tag": "resume",
            "assign_to": null,
            "feedback": null,
            "approval": "pending",
            "download_count": null,
            "output": {
                "name": "Sudeshna Paul",
                "location": "Kolkata, West Bengal",
                "current_designation": "Assistant Manager \u2013 Human Resources",
                "email": "Spaul3784@gmail.com",
                "linkedin_id": "",
                "phone": "+919836702633",
                "summary": "Human Resources Professional seeking an opportunity to advance my career in a growing employee focused organization. Over 3 years of successful stint in Talent Acquisition and Operation Management in a large financial services company with an employee base of 1800+. Strong interpersonal and communication skills, expert in conducting interviews and negotiating with potential candidates and ability to interact with senior level Managers to understand their talent requirement. Further, experienced in On boarding of new employees including induction process, organizing employee engagement events, managing leave programme etc.",
                "objective": "",
                "work_experience": [
                    {
                        "title": "Assistant Manager \u2013 Human Resources",
                        "company": "SREI Equipment Finance Ltd.",
                        "location": "Kolkata, West Bengal",
                        "start_time": "Sep 2015",
                        "end_time": "Nov 2018",
                        "responsibilities": [
                            "Recruitment cycle from position notification to offer roll out in 30-60 days depending on seniority levels",
                            "Completed recruitment for +300 positions",
                            "Internship placement for 40 candidates",
                            "Onboarding of new employees including induction process",
                            "Organising employee engagement events",
                            "Managing leave programme",
                            "Administering employee satisfaction survey"
                        ],
                        "remaining_related_data": "SREI Equipment Finance is a leading NBFC player in infrastructure and equipment financing sector. Responsible for talent acquisition from entry level to the level of Asst. Vice President. Received job requirement from departmental heads and converted them into JDs. Searched for candidates from internet sites, media advertisements, employee referrals, and recruitment agencies. Shortlisted candidates and interviewed them. Prepared a final shortlist which was sent to the departmental heads for final interviews. Based on the hiring recommendation by departmental head, negotiate with the candidate for remuneration and roll out offer letters. For senior people, conduct psychometric tests before roll out of offer letters. Manage the internship programme of the company."
                    },
                    {
                        "title": "Intern",
                        "company": "Lowe Lintas & Partners, Media Group",
                        "location": "Kolkata, West Bengal",
                        "start_time": "Apr 2012",
                        "end_time": "May 2012",
                        "responsibilities": [],
                        "remaining_related_data": "Analysis of advertising traffic of various brands in various TV General Entertainment Channels, during different time slots of the day, and finding the preferences of the various product/service categories in the various time slots."
                    }
                ],
                "education": [
                    {
                        "degree": "Master of Business Administration",
                        "university": "University Of Sunderland, U.K.",
                        "location": "",
                        "start_time": "MMM YYYY-MMM YYYY",
                        "end_time": "Jul 2015",
                        "remaining_related_data": "Management Development Institute Singapore"
                    },
                    {
                        "degree": "Bachelor of Arts",
                        "university": "Calcutta University",
                        "location": "Kolkata, West Bengal",
                        "start_time": "MMM YYYY-MMM YYYY",
                        "end_time": "Jul 2013",
                        "remaining_related_data": "Loreto College, Major: Communicative English"
                    },
                    {
                        "degree": "High School",
                        "university": "",
                        "location": "Kolkata, West Bengal",
                        "start_time": "MMM YYYY-MMM YYYY",
                        "end_time": "May 2009",
                        "remaining_related_data": "Apeejay School, Board: CBSE"
                    }
                ],
                "skills": [
                    {
                        "heading": "skills",
                        "related_data": [
                            "Search for potential candidates using databases, internet recruiting resources, media advertisements, recruiting firms and employee referrals",
                            "Interviewing and administration of psychometric tests",
                            "Short listing and coordinating with departmental managers regarding recruitment",
                            "Rolling out of offer letters",
                            "Organizing rolling out of offer letters and confirming the joining dates",
                            "Managing internship programmes"
                        ]
                    }
                ],
                "certifications": {
                    "certification_details": "",
                    "date": "",
                    "remaining_related_data": ""
                },
                "projects": {
                    "project_name": "",
                    "technologies": "",
                    "link": "",
                    "time_period": "",
                    "remaining_related_data": ""
                },
                "academic_achievements": "",
                "declaration": {
                    "declaration": "",
                    "signature": "",
                    "date": ""
                },
                "additional": {
                    "languages": {
                        "English": {
                            "read": true,
                            "write": true,
                            "speak": true
                        },
                        "Hindi": {
                            "read": true,
                            "write": true,
                            "speak": true
                        },
                        "Bengali": {
                            "read": true,
                            "write": true,
                            "speak": false
                        }
                    },
                    "personal_interests": [
                        "Music",
                        "Films",
                        "Travel",
                        "Photography",
                        "Cooking"
                    ],
                    "personal_information": {
                        "father_name": "Mr. Subrata Paul",
                        "marital_status": "Married",
                        "birthday": "November 17, 1989",
                        "nationality": "Indian",
                        "gender": "Female",
                        "aadhaar_card_no": "4041 6360 1750"
                    }
                }
            },
            "process_file_name": null,
            "docx_status": "0",
            "pdf_status": "0",
            "forum_process_url": null,
            "ted_process_url": null,
            "blob_location": "optira_documents/Demo/resume/20241111_084356_477360000003123039_1612252853916.docx",
            "source_of_import": "web"
        },
        {
            "id": 158,
            "upload_date": "2024-10-22T03:17:09",
            "process_date": "2024-10-22T00:00:00",
            "file_original_name": "477360000003123009_1612249732131.docx",
            "processed_file_name": null,
            "file_url": "https://formrecognizer12345.blob.core.windows.net/form-recognizer/optira_documents/Demo/resume/20241111_084356_477360000003123009_1612249732131.docx",
            "processed": 1,
            "process_file_url": null,
            "process_time": "P0DT00H00M29S",
            "useremail": "demo@thirdeyedata.ai",
            "tag": "resume",
            "assign_to": null,
            "feedback": null,
            "approval": "pending",
            "download_count": null,
            "output": {
                "name": "PRIYANKA ROY",
                "location": "A-169, DINESH PALLY, PURBA PUTIARY, KHUDGHAT, 700093",
                "current_designation": "",
                "email": "roypriyanka041@gmail.com",
                "linkedin_id": "",
                "phone": "9038851877",
                "summary": "To make optimum utilisation of my knowledge and skills, utilize opportunities effectively for professional growth and to contribute in best possible way for the betterment of the organization and self.",
                "objective": "",
                "work_experience": [
                    {
                        "title": "Guest Relationship Executive",
                        "company": "VFS Global Services",
                        "location": "",
                        "start_time": "Jul 2019",
                        "end_time": "Nov 2019",
                        "responsibilities": [
                            "Applicant handling in documentation.",
                            "Documents Processing.",
                            "Report Making."
                        ],
                        "remaining_related_data": {}
                    },
                    {
                        "title": "HR Recruiter",
                        "company": "SMVA Consultant",
                        "location": "",
                        "start_time": "Nov 2017",
                        "end_time": "May 2018",
                        "responsibilities": [],
                        "remaining_related_data": {}
                    }
                ],
                "education": [
                    {
                        "degree": "DIPLOMA",
                        "university": "FRANKFINN INSTITUTE",
                        "location": "",
                        "start_time": "MMM 2018",
                        "end_time": "MMM 2019",
                        "remaining_related_data": {}
                    },
                    {
                        "degree": "B.COM",
                        "university": "Guwahati University",
                        "location": "",
                        "start_time": "MMM 2017",
                        "end_time": "MMM 2017",
                        "remaining_related_data": {}
                    },
                    {
                        "degree": "ISC-12TH",
                        "university": "ISC",
                        "location": "",
                        "start_time": "MMM 2013",
                        "end_time": "MMM 2013",
                        "remaining_related_data": {}
                    },
                    {
                        "degree": "ICSE-10TH",
                        "university": "ICSE",
                        "location": "",
                        "start_time": "MMM 2011",
                        "end_time": "MMM 2011",
                        "remaining_related_data": {}
                    }
                ],
                "skills": [
                    {
                        "heading": "skills",
                        "related_data": []
                    }
                ],
                "certifications": {
                    "certification_details": "",
                    "date": "",
                    "remaining_related_data": {}
                },
                "projects": {
                    "project_name": "",
                    "technologies": "",
                    "link": "",
                    "time_period": "",
                    "remaining_related_data": {}
                },
                "academic_achievements": "",
                "declaration": {
                    "declaration": "",
                    "signature": "",
                    "date": ""
                },
                "additional": {
                    "fathers_name": "VIJAY KR ROY",
                    "language_known": [
                        "ENGLISH",
                        "HINDI",
                        "BENGALI"
                    ],
                    "date_of_birth": "19th March, 1994",
                    "gender": "Female",
                    "extra_curricular_activities": [
                        "TEACHING STUDENTS.",
                        "LISTENING MUSIC"
                    ]
                }
            },
            "process_file_name": null,
            "docx_status": "0",
            "pdf_status": "0",
            "forum_process_url": null,
            "ted_process_url": null,
            "blob_location": "optira_documents/Demo/resume/20241111_084356_477360000003123009_1612249732131.docx",
            "source_of_import": "web"
        },
        {
            "id": 157,
            "upload_date": "2024-10-23T05:03:59",
            "process_date": "2024-10-23T00:00:00",
            "file_original_name": "477360000003122071_1612323990833.pdf",
            "processed_file_name": null,
            "file_url": "https://formrecognizer12345.blob.core.windows.net/form-recognizer/optira_documents/Demo/resume/20241111_084355_477360000003122071_1612323990833.pdf",
            "processed": 1,
            "process_file_url": null,
            "process_time": "P0DT00H00M27S",
            "useremail": "demo@thirdeyedata.ai",
            "tag": "resume",
            "assign_to": null,
            "feedback": null,
            "approval": "pending",
            "download_count": null,
            "output": {
                "name": "Kimiya Dey",
                "location": "West Bengal",
                "current_designation": "Advanced Tax Analyst",
                "email": "Kimiyadey100@gmail.com",
                "linkedin_id": "",
                "phone": "+91 9538789150",
                "summary": [
                    "A dynamic & experienced professional with over 2.2 years of rich & extensive experience mainly in both human resource and tax domain",
                    "Former advanced analyst in EY GDS and Human Resource analyst in 3M India, Bangalore",
                    "Worked closely in an application that benefits in a huge amount to the client.",
                    "Performed Internal Audit",
                    "A team player possessing inter-personal skills with a track record that demonstrates self-motivation, creativity and initiative to achieve corporate goals.",
                    "Can build effective working relationships with both colleagues and clients",
                    "Performed SWOT"
                ],
                "objective": "",
                "work_experience": [
                    {
                        "title": "Advanced Tax Analyst",
                        "company": "Ernst & Young",
                        "location": "Bangalore",
                        "start_time": "Dec 2018",
                        "end_time": "",
                        "responsibilities": [
                            "Setting-up SharePoint Sites for EY client engagements; ensuring end-to-end delivery and transition of large scale Outsourced Tax and Statutory Compliance Contracts",
                            "Managing Client\u2019s Functional Query from EY user on behalf of Client User; Account Management of Large US Inbound Companies across different sectors",
                            "Capturing changes in the contract scope through change scope management and escalating incidents to respective team either on behalf of client or user",
                            "Worked closely in an application that is beneficiary to the client"
                        ],
                        "remaining_related_data": {}
                    },
                    {
                        "title": "Analyst \u2013 Advanced Analyst",
                        "company": "Ernst & Young",
                        "location": "Bangalore",
                        "start_time": "Aug 2018",
                        "end_time": "Dec 2019",
                        "responsibilities": [
                            "Lead project planning, creation of content, regular coordination with multiple on-shore clients; mentored and trained new hires",
                            "Involved with Service Provider teams and reviewed documents and provided feedback to researchers",
                            "Maintained timelines/milestones, and ensured compliance with quality standards; prepared status reports for weekly status meetings",
                            "Worked as part of the Limited Partner (LP) Team under Investment Research; analyzed information related to Limited Partner domain and tracked their assets and investments in various asset classes",
                            "Performed in-depth analysis of their private equity/venture capital investments from various reports and formulated investment policies after gathering all relevant information from published company reports, news releases and various databases"
                        ],
                        "remaining_related_data": {}
                    },
                    {
                        "title": "Analyst",
                        "company": "3M India",
                        "location": "Bangalore",
                        "start_time": "Nov 2017",
                        "end_time": "Jun 2018",
                        "responsibilities": [
                            "Performed SWOT",
                            "Worked personally on a project with senior Manager titled as 'EMPLOYEE ENGAGEMENT AT THE MANUFACTURING PLANT'",
                            "Capability to convey the implications of any update made in the manufacturing plant",
                            "Collating and documenting key data and information at the request of the clients and senior managers"
                        ],
                        "remaining_related_data": {}
                    }
                ],
                "education": [
                    {
                        "degree": "Master of Science in Industrial and Organizational Psychology",
                        "university": "Kristu Jayanti College",
                        "location": "Bangalore University",
                        "start_time": "MMM YYYY-MMM YYYY",
                        "end_time": "2018",
                        "remaining_related_data": {}
                    },
                    {
                        "degree": "Bachelor of Arts in Psychology",
                        "university": "Delhi University",
                        "location": "",
                        "start_time": "MMM YYYY-MMM YYYY",
                        "end_time": "2016",
                        "remaining_related_data": {}
                    },
                    {
                        "degree": "Diploma in Change Management",
                        "university": "Alison Online Course",
                        "location": "",
                        "start_time": "MMM YYYY-MMM YYYY",
                        "end_time": "2020",
                        "remaining_related_data": {}
                    },
                    {
                        "degree": "Diploma in Human Resource",
                        "university": "Alison Online Course",
                        "location": "",
                        "start_time": "MMM YYYY-MMM YYYY",
                        "end_time": "2020",
                        "remaining_related_data": {}
                    }
                ],
                "skills": [
                    {
                        "heading": "IT Skills",
                        "related_data": [
                            "MS Word",
                            "PowerPoint",
                            "Excel",
                            "Windows 10"
                        ]
                    }
                ],
                "certifications": [],
                "projects": [],
                "academic_achievements": "",
                "declaration": {
                    "declaration": "I hereby declare that the above mentioned details are true to my knowledge",
                    "signature": "",
                    "date": ""
                },
                "additional": {
                    "date_of_birth": "28th Sep 1994",
                    "languages_known": [
                        "Hindi",
                        "English",
                        "Bengali"
                    ],
                    "permanent_address": "Ward No: 20, Millan Mandir Road, Subhas Pally, Siliguri \u2013 734001"
                }
            },
            "process_file_name": null,
            "docx_status": "0",
            "pdf_status": "0",
            "forum_process_url": null,
            "ted_process_url": null,
            "blob_location": "optira_documents/Demo/resume/20241111_084355_477360000003122071_1612323990833.pdf",
            "source_of_import": "web"
        },
        {
            "id": 156,
            "upload_date": "2024-10-24T06:13:58",
            "process_date": "2024-10-24T00:00:00",
            "file_original_name": "477360000003122047_SANCHARI CHATTERJEE.pdf",
            "processed_file_name": null,
            "file_url": "https://formrecognizer12345.blob.core.windows.net/form-recognizer/optira_documents/Demo/resume/20241111_084355_477360000003122047_SANCHARI%20CHATTERJEE.pdf",
            "processed": 1,
            "process_file_url": null,
            "process_time": "P0DT00H00M41S",
            "useremail": "demo@thirdeyedata.ai",
            "tag": "resume",
            "assign_to": null,
            "feedback": null,
            "approval": "pending",
            "download_count": null,
            "output": {
                "name": "Sanchari Chatterjee",
                "location": "New Garia Dev. Co-operative Housing Society Ltd., Kolkata - 700094",
                "current_designation": "",
                "email": "sancharichatterje@gmail.com",
                "linkedin_id": "https://www.linkedin.com/in/sanchari-chatterjee-391084b7/",
                "phone": "91 - 9163462629",
                "summary": "Over 10 years of rich and comprehensive experience in Market Research, Inspection and Administration. A proactive team leader with expertise. Have hands-on knowledge and experience of working on few analytic projects using R, Python and Tableau.",
                "objective": "",
                "work_experience": [
                    {
                        "title": "Inspection Coordinator",
                        "company": "Cotecna Inspection India Pvt. Ltd.",
                        "location": "",
                        "start_time": "Oct 2016",
                        "end_time": "Present",
                        "responsibilities": [
                            "Working in a project with CSIR-CIMFR for Coal Sampling and handling the operation of Coal Subsidiaries and Plants.",
                            "Compile the field data, analyze and validate the same and make a final report for submission.",
                            "Coordinate with the client team and field staff.",
                            "Attend all the queries and resolve it with due diligence.",
                            "Team management and handling operational issues.",
                            "Monitor stock levels of both stationery and safety items and identify purchase need.",
                            "Evaluate offers from vendors and negotiate better price."
                        ],
                        "remaining_related_data": {}
                    },
                    {
                        "title": "Executive Assistant to COO",
                        "company": "Keventer Projects Ltd.",
                        "location": "",
                        "start_time": "Jun 2015",
                        "end_time": "Oct 2016",
                        "responsibilities": [
                            "Minute taking and distribution.",
                            "Procure all travel requirements.",
                            "Arrange all meetings, including room bookings, confirming participant attendance, equipment and setting up conference/video calls. Ensuring all paperwork or pre-meeting is arranged, if necessary.",
                            "Maintain and update all maintenance contracts and agency agreements."
                        ],
                        "remaining_related_data": {}
                    },
                    {
                        "title": "Back Office Executive",
                        "company": "RV Group of Companies",
                        "location": "",
                        "start_time": "Apr 2013",
                        "end_time": "Aug 2014",
                        "responsibilities": [
                            "Provide secretarial support to GM - Audit & Accounts.",
                            "Interpret administrative and operating policies and procedures and convey the same to other employees of the dept.",
                            "Track attendance of the departmental employees and forward the same at the end of the month to HR department for payroll process."
                        ],
                        "remaining_related_data": {}
                    },
                    {
                        "title": "Senior Project Executive",
                        "company": "Data Search (P) Ltd.",
                        "location": "",
                        "start_time": "Mar 2008",
                        "end_time": "May 2011",
                        "responsibilities": [
                            "Proven track record in completing different types of project like Household Surveys, Corporate Studies, Online Surveys, Central Location Tests (CLT) etc. and delivering results on time.",
                            "Dealing with international clientele and regularly update them about the ongoing projects.",
                            "Design Questionnaires following the guidelines provided by the clientele.",
                            "Analyze TOPLINE data in ASCII format developed in SAS.",
                            "Brief the interviewers about the projects to ensure proper data collection.",
                            "Coordinate with the agencies and follow up them for the status report.",
                            "Scrutinize the questionnaires and back check the same to ensure the quality of research done by maintaining sample quota required across multiple locations.",
                            "Translate the questionnaires from English to Bengali in Unicode."
                        ],
                        "remaining_related_data": {}
                    }
                ],
                "education": [
                    {
                        "degree": "MBA \u2013 HRM (Recognized by UGC)",
                        "university": "ICFAI University",
                        "location": "",
                        "start_time": "MMM YYYY",
                        "end_time": "2019",
                        "remaining_related_data": {
                            "cgpa": "6.10"
                        }
                    },
                    {
                        "degree": "Bachelor of Arts",
                        "university": "University of Calcutta",
                        "location": "",
                        "start_time": "MMM YYYY",
                        "end_time": "2007",
                        "remaining_related_data": {
                            "percentage": "39.18%"
                        }
                    },
                    {
                        "degree": "Higher Secondary",
                        "university": "Garia Harimati Devi Uchcha Balika Vidhyalaya",
                        "location": "",
                        "start_time": "MMM YYYY",
                        "end_time": "2004",
                        "remaining_related_data": {
                            "percentage": "50.60%"
                        }
                    },
                    {
                        "degree": "Secondary",
                        "university": "Garia Harimati Devi Uchcha Balika Vidhyalaya",
                        "location": "",
                        "start_time": "MMM YYYY",
                        "end_time": "2002",
                        "remaining_related_data": {
                            "percentage": "67.25%"
                        }
                    }
                ],
                "skills": [
                    {
                        "heading": "skills",
                        "related_data": [
                            "MS OFFICE INCLUDING ADVANCE EXCEL",
                            "SQL",
                            "TABLEAU",
                            "R",
                            "PYTHON",
                            "DATA ANALYSIS",
                            "ANALYTICAL THINKING & RESEARCH",
                            "ABLE TO OPERATE UNDER PRESSURE",
                            "MULTI-TASKER",
                            "TIME MANAGEMENT"
                        ]
                    }
                ],
                "certifications": [
                    {
                        "certification_details": "Data Science & Advanced Tableau Certification Program, Ivy Professional School",
                        "date": "2020",
                        "remaining_related_data": {}
                    },
                    {
                        "certification_details": "Diploma in Multimedia Technology, CMC Academy",
                        "date": "2011",
                        "remaining_related_data": {}
                    }
                ],
                "projects": [
                    {
                        "project_name": "Customer Lifetime Value Prediction",
                        "technologies": "R",
                        "link": "",
                        "time_period": "",
                        "remaining_related_data": {
                            "description": "Designed a Linear Regression model using R to understand the customer lifetime value for an insurance company with the help of coefficients from the model. Forecasted the future using time series forecasting and built an Arima Model."
                        }
                    },
                    {
                        "project_name": "Churn Prediction",
                        "technologies": "R",
                        "link": "",
                        "time_period": "",
                        "remaining_related_data": {
                            "description": "Designed a Logistic Regression model using R to predict the churn in a telecom company."
                        }
                    },
                    {
                        "project_name": "Credit Card Fraud Detection",
                        "technologies": "Python",
                        "link": "",
                        "time_period": "",
                        "remaining_related_data": {
                            "description": "Designed a project in Python on credit card fraud detection."
                        }
                    },
                    {
                        "project_name": "Covid-19 Data Analysis Dashboards",
                        "technologies": "Tableau",
                        "link": "",
                        "time_period": "",
                        "remaining_related_data": {
                            "description": "Designed few dashboards in Tableau of some statistical analysis on Covid-19 India, Summer Recap Headlines, Unemployment & Mental Illness etc. with the help of the dataset fetching from Kaggle."
                        }
                    }
                ],
                "academic_achievements": "Slashed maximum backlog through implementation of central stock management system for optimal coordination between production, planning, logistics and finance.",
                "declaration": {
                    "declaration": "",
                    "signature": "",
                    "date": ""
                },
                "additional": {
                    "achievement": "Slashed maximum backlog through implementation of central stock management system for optimal coordination between production, planning, logistics and finance.",
                    "languages": [
                        "English",
                        "Hindi",
                        "Bengali"
                    ],
                    "current_ctc": "Rs. \u2013 2,80,000/-",
                    "personal_information": {
                        "date_of_birth": "26th July, 1985",
                        "gender": "Female",
                        "citizenship": "Indian"
                    }
                }
            },
            "process_file_name": null,
            "docx_status": "0",
            "pdf_status": "0",
            "forum_process_url": null,
            "ted_process_url": null,
            "blob_location": "optira_documents/Demo/resume/20241111_084355_477360000003122047_SANCHARI CHATTERJEE.pdf",
            "source_of_import": "web"
        },
        {
            "id": 155,
            "upload_date": "2024-10-25T13:13:58",
            "process_date": "2024-10-25T00:00:00",
            "file_original_name": "477360000003122012_1612261382181.docx",
            "processed_file_name": null,
            "file_url": "https://formrecognizer12345.blob.core.windows.net/form-recognizer/optira_documents/Demo/resume/20241111_084354_477360000003122012_1612261382181.docx",
            "processed": 1,
            "process_file_url": null,
            "process_time": "P0DT00H00M18S",
            "useremail": "demo@thirdeyedata.ai",
            "tag": "resume",
            "assign_to": null,
            "feedback": null,
            "approval": "pending",
            "download_count": null,
            "output": {
                "name": "Shruty Gupta",
                "location": "Galino.11 sadar cantt. Jabalpur(m.p)",
                "current_designation": "",
                "email": "guptashruty25@gmail.com",
                "linkedin_id": "",
                "phone": "9171229305",
                "summary": "",
                "objective": "Intend to build a career with committed and dedicated people which will help me to explore myself and realize my potential which in turn would allow me to grow with the company both personally and professionally.",
                "work_experience": [],
                "education": [
                    {
                        "degree": "B.COM(C.A)3RD YEAR",
                        "university": "ST.ALOYSIUS COLLEGE",
                        "location": "JABALPUR",
                        "start_time": "",
                        "end_time": "",
                        "remaining_related_data": {
                            "percentage": "66.22%"
                        }
                    },
                    {
                        "degree": "B.COM(C.A)2ND YEAR",
                        "university": "ST.ALOYSIUS COLLEGE",
                        "location": "JABALPUR",
                        "start_time": "",
                        "end_time": "",
                        "remaining_related_data": {
                            "percentage": "61.78%"
                        }
                    },
                    {
                        "degree": "B.COM(C.A)1ST YEAR",
                        "university": "ST.ALOYSIUS COLLEGE",
                        "location": "JABALPUR",
                        "start_time": "",
                        "end_time": "",
                        "remaining_related_data": {
                            "percentage": "52.67%"
                        }
                    },
                    {
                        "degree": "HIGHER SECONDARY",
                        "university": "M.P.BOARD",
                        "location": "BHOPAL",
                        "start_time": "",
                        "end_time": "",
                        "remaining_related_data": {
                            "percentage": "77.06%"
                        }
                    },
                    {
                        "degree": "HIGH SCHOOL",
                        "university": "M.P.BOARD",
                        "location": "BHOPAL",
                        "start_time": "",
                        "end_time": "",
                        "remaining_related_data": {
                            "percentage": "69.83%"
                        }
                    }
                ],
                "skills": [
                    {
                        "heading": "programming_languages",
                        "related_data": [
                            "Basic computer knowledge",
                            "C",
                            "C++",
                            "PHP",
                            "Adobe Photoshop",
                            "HTML",
                            "HTML 5",
                            "PL/SQL"
                        ]
                    }
                ],
                "certifications": {
                    "certification_details": "",
                    "date": "",
                    "remaining_related_data": ""
                },
                "projects": {
                    "project_name": "",
                    "technologies": "",
                    "link": "",
                    "time_period": "",
                    "remaining_related_data": ""
                },
                "academic_achievements": "",
                "declaration": {
                    "declaration": "I hereby declare that all the above information given by me is true to the best of my knowledge & belief.",
                    "signature": "(shruty gupta)",
                    "date": ""
                },
                "additional": {}
            },
            "process_file_name": null,
            "docx_status": "0",
            "pdf_status": "0",
            "forum_process_url": null,
            "ted_process_url": null,
            "blob_location": "optira_documents/Demo/resume/20241111_084354_477360000003122012_1612261382181.docx",
            "source_of_import": "web"
        },
        {
            "id": 154,
            "upload_date": "2024-10-26T03:13:58",
            "process_date": "2024-10-26T00:00:00",
            "file_original_name": "477360000003121010_1612301686670.pdf",
            "processed_file_name": null,
            "file_url": "https://formrecognizer12345.blob.core.windows.net/form-recognizer/optira_documents/Demo/resume/20241111_084354_477360000003121010_1612301686670.pdf",
            "processed": 1,
            "process_file_url": null,
            "process_time": "P0DT00H00M19S",
            "useremail": "demo@thirdeyedata.ai",
            "tag": "resume",
            "assign_to": null,
            "feedback": null,
            "approval": "pending",
            "download_count": null,
            "output": {
                "name": "",
                "location": "",
                "current_designation": "",
                "email": "anush.samanta@gmail.com",
                "linkedin_id": "",
                "phone": "8013958742",
                "summary": "Professional phone banking manager with two and a half years of experience. Business analyst of Wipro Ltd.",
                "objective": "",
                "work_experience": [
                    {
                        "title": "Associate Business Analyst",
                        "company": "Wipro",
                        "location": "",
                        "start_time": "Jun 2019",
                        "end_time": "Present",
                        "responsibilities": [
                            "Managing incoming and outgoing international calls",
                            "Defining business requirements and reporting them back to client",
                            "Daily communication regarding the needs",
                            "Creating a detailed business analysis, outlining problems, opportunities and solutions for a business",
                            "Budgeting and forecasting",
                            "Planning and monitoring",
                            "Variance analysis",
                            "Pricing",
                            "Reporting"
                        ],
                        "remaining_related_data": {}
                    },
                    {
                        "title": "Virtual Relationship Manager",
                        "company": "HDFC Bank",
                        "location": "",
                        "start_time": "Sep 2016",
                        "end_time": "Apr 2019",
                        "responsibilities": [
                            "Managing incoming calls and customer service inquiries",
                            "Generating sales leads that develop into new customers",
                            "Identifying and assessing customers' needs to achieve satisfaction"
                        ],
                        "remaining_related_data": {}
                    }
                ],
                "education": [
                    {
                        "degree": "B.Tech (Electronics and Communication Engineering)",
                        "university": "Camellia School of Engineering and Technology",
                        "location": "West Bengal University of Technology",
                        "start_time": "Aug 2011",
                        "end_time": "Jul 2015",
                        "remaining_related_data": {}
                    }
                ],
                "skills": [
                    {
                        "heading": "skills",
                        "related_data": [
                            "Good communication - written and oral skills",
                            "Excellent conceptual and analytical skills",
                            "Communicative",
                            "Punctual",
                            "Word",
                            "Microsoft Excel",
                            "CRMnext"
                        ]
                    }
                ],
                "certifications": [],
                "projects": [],
                "academic_achievements": [
                    "Won Certificate of excellent performance in March 2020",
                    "Certified for Fantastic Performance in March 2019",
                    "Quality Topper January 2019 of Virtual Relationship Manager",
                    "Quality Topper January 2019 of Virtual Relationship Management Department",
                    "Highest Appreciation holder December 2018"
                ],
                "declaration": {
                    "declaration": "",
                    "signature": "",
                    "date": ""
                },
                "additional": {
                    "personality": "",
                    "software_skills": "",
                    "languages": [
                        "English",
                        "Hindi",
                        "Bengali"
                    ],
                    "hobbies": [
                        "Watching TV series",
                        "Singing",
                        "Travelling"
                    ]
                }
            },
            "process_file_name": null,
            "docx_status": "0",
            "pdf_status": "0",
            "forum_process_url": null,
            "ted_process_url": null,
            "blob_location": "optira_documents/Demo/resume/20241111_084354_477360000003121010_1612301686670.pdf",
            "source_of_import": "web"
        },
    ],
    "message": "File Information Fetched Successfully",
    "status_code": 200
}