export const documentStatistics = {
    "status": true,
    "data": {
        "invoice": {
            "processed": 4,
            "unprocessed": 0
        },
        "bankstatement": {
            "processed": 4,
            "unprocessed": 0
        },
        "handwritten": {
            "processed": 3,
            "unprocessed": 0
        },
        "voucher": {
            "processed": 3,
            "unprocessed": 0
        },
        "receipt": {
            "processed": 3,
            "unprocessed": 0
        },
        
    },
    "message": "Found Statistics Successfully",
    "status_code": 200
}