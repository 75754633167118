import { FileDetails } from "../modules/documentprocessing/DocumentProcessingModel";

export const invoiceFileInfo: { status: boolean, data: FileDetails[], message: string, status_code: number } = {
    "status": true,
    "data": [
        {
            "id": 31,
            "file_original_name": "Cleaning-Invoice-Template-TemplateLab.com_.pdf",
            "file_url": "https://formrecognizer12345.blob.core.windows.net/form-recognizer/optira_documents/Demo/invoice/20241025_190840_Cleaning-Invoice-Template-TemplateLab.com_.pdf",
            "tag": "invoice",
            "blob_location": "optira_documents/Demo/invoice/20241025_190840_Cleaning-Invoice-Template-TemplateLab.com_.pdf",
            "processed": 1,
            "container_name": null,
            "useremail": "demo@thirdeyedata.ai",
            "output": {
                "invoice_number": "1234-15099",
                "date_of_issue": "2024-01-08",
                "bill_to_client_name": "Franc Swapson",
                "bill_to_address": "123 Main Street Anytown, USA, 12345",
                "description": [
                    {
                        "item": "Curtain Cleaning",
                        "unit_cost": 50.0,
                        "quantity": 3,
                        "amount": 150.0
                    },
                    {
                        "item": "Pressure Washing",
                        "unit_cost": 100.0,
                        "quantity": 2,
                        "amount": 200.0
                    },
                    {
                        "item": "Chimney Sweeping",
                        "unit_cost": 110.0,
                        "quantity": 1,
                        "amount": 110.0
                    },
                    {
                        "item": "Ceiling and Wall Cleaning",
                        "unit_cost": 105.0,
                        "quantity": 1,
                        "amount": 105.0
                    },
                    {
                        "item": "Sanitization Services",
                        "unit_cost": 35.0,
                        "quantity": 8,
                        "amount": 280.0
                    }
                ],
                "grand_total": 929.5,
                "terms_and_conditions": "Unless otherwise agreed in writing by the parties, all invoices are payable within thirty (30) days of the invoice date.",
                "cleaning_period_included": "2023-12-01 to 2023-12-31",
                "cleaning_services_address": "2001 Street Name City, State, Country, Zip code",
                "cleaning_services_phone": "(000) 123 456 7890",
                "cleaning_services_email": "cleaningservices@email.com",
                "cleaning_services_website": "cleaningservices123.com",
                "subtotal": 895.0,
                "discount": 50.0,
                "tax_rate": 10,
                "tax": 84.5,
                "total": 929.5,
                "payment_instructions": "National Bank of Hamamanji Acc.No. 0123 0000 1111 2323 Sort Code 25-88-00",
                "thank_you_note": "THANK YOU FOR YOUR BUSINESS!"
            },
            "created_at": null,
            "upload_date": "2024-10-20T18:08:43",
            "process_date": "2024-10-20T00:00:00",
            "process_time": null,
            "assign_to": null,
            "feedback": null,
            "approval": null,
            "processed_file_name": null,
            "process_file_url": null,
            "pdf_status": null,
            "file_location_for_preview": null
        },
        {
            "id": 32,
            "file_original_name": "Commercial-Invoice-Template-TemplateLab.com_.pdf",
            "file_url": "https://formrecognizer12345.blob.core.windows.net/form-recognizer/optira_documents/Demo/invoice/20241025_190840_Commercial-Invoice-Template-TemplateLab.com_.pdf",
            "tag": "invoice",
            "blob_location": "optira_documents/Demo/invoice/20241025_190840_Commercial-Invoice-Template-TemplateLab.com_.pdf",
            "processed": 1,
            "container_name": null,
            "useremail": "demo@thirdeyedata.ai",
            "output": {
                "company_name": "Local Store",
                "slogan": "Slogan Goes Here",
                "forwarding_agent": "DHL",
                "tracking_number": "55888800000998700",
                "sold_by": "Local Store",
                "sold_by_address": "255 Commercial Street 25880 New York, US",
                "sold_by_phone": "55510002556678",
                "sold_by_email": "info@localstore.com",
                "sold_by_eori": "PT100003456566",
                "product": [
                    {
                        "name": "Conveyor Belt 25",
                        "hs_code": "88565.2252",
                        "units": 2,
                        "unit_price": 200.0,
                        "total_price": 400.0
                    },
                    {
                        "name": "Pole with bracket",
                        "hs_code": "88565.2545",
                        "units": 1,
                        "unit_price": 85.0,
                        "total_price": 85.0
                    }
                ],
                "invoice_date": "2023-08-14",
                "invoice_number": "F1000876/23",
                "payment_method": "Credit Card",
                "order_id": "X001525",
                "bill_to_client_name": "Importing Company",
                "bill_to_address": "100 Mighty Bay 125863 Rome, IT",
                "bill_to_phone": "0039565844452474",
                "bill_to_email": "Info@localstore.com",
                "bill_to_eori": "PT100003456566",
                "insurance_included": false,
                "reason_for_export": "Sale",
                "incoterms": "DAP",
                "goods_description": "Pallet 1200x800x1500mm (15.2kg) 1pc . Carton Box150x200x100mm (15.2kg) 3pcs",
                "sub_total": 485.0,
                "shipping_charges": 100.0,
                "insurance_charges": 0.0,
                "sales_tax": 117.0,
                "total_amount": 702.0
            },
            "created_at": null,
            "upload_date": "2024-10-21T19:18:44",
            "process_date": "2024-10-21T00:00:00",
            "process_time": null,
            "assign_to": null,
            "feedback": null,
            "approval": null,
            "processed_file_name": null,
            "process_file_url": null,
            "pdf_status": null,
            "file_location_for_preview": null
        },
        {
            "id": 33,
            "file_original_name": "HVAC-Invoice-Template-TemplateLab.com_.pdf",
            "file_url": "https://formrecognizer12345.blob.core.windows.net/form-recognizer/optira_documents/Demo/invoice/20241025_190841_HVAC-Invoice-Template-TemplateLab.com_.pdf",
            "tag": "invoice",
            "blob_location": "optira_documents/Demo/invoice/20241025_190841_HVAC-Invoice-Template-TemplateLab.com_.pdf",
            "processed": 1,
            "container_name": null,
            "useremail": "demo@thirdeyedata.ai",
            "output": {
                "invoice_no": "3078/2024",
                "customer_id": "CLT-223",
                "bill_to_client_name": "Mr. Alex Thompson",
                "bill_to_address": "456 Pine Street, Metroville 54321",
                "bill_to_phone": "(555) 789 012 3456",
                "bill_to_email": "client.alex@gmail.com",
                "invoice_date": "2024-09-22",
                "pay_to_name": "Lisa Johnson",
                "pay_to_address": "789 Maple Avenue, Suite 205 Suburbia 98765",
                "pay_to_email": "lisa.j@example.com",
                "pay_to_phone": "(555) 456 789 0123",
                "payment_info": "Secure Banking Account:9876 5432 1098 7654",
                "items": [
                    {
                        "name": "ENGINE OIL FILTER",
                        "quantity": 2,
                        "price": 12.0,
                        "amount": 24.0
                    },
                    {
                        "name": "AIR FILTER",
                        "quantity": 1,
                        "price": 15.0,
                        "amount": 15.0
                    },
                    {
                        "name": "SPARK PLUGS",
                        "quantity": 1,
                        "price": 5.0,
                        "amount": 5.0
                    },
                    {
                        "name": "BRAKE PADS",
                        "quantity": 1,
                        "price": 20.0,
                        "amount": 20.0
                    },
                    {
                        "name": "WINDSHIELD WIPERS",
                        "quantity": 2,
                        "price": 8.0,
                        "amount": 16.0
                    }
                ],
                "subtotal": 125.0,
                "tax_rate": 10,
                "total": 137.5
            },
            "created_at": null,
            "upload_date": "2024-10-22T17:08:44",
            "process_date": "2024-10-22T00:00:00",
            "process_time": null,
            "assign_to": null,
            "feedback": null,
            "approval": null,
            "processed_file_name": null,
            "process_file_url": null,
            "pdf_status": null,
            "file_location_for_preview": null
        },
        {
            "id": 34,
            "file_original_name": "Landscaper-Invoice-Template-TemplateLab.com_.pdf",
            "file_url": "https://formrecognizer12345.blob.core.windows.net/form-recognizer/optira_documents/Demo/invoice/20241025_190841_Landscaper-Invoice-Template-TemplateLab.com_.pdf",
            "tag": "invoice",
            "blob_location": "optira_documents/Demo/invoice/20241025_190841_Landscaper-Invoice-Template-TemplateLab.com_.pdf",
            "processed": 1,
            "container_name": null,
            "useremail": "demo@thirdeyedata.ai",
            "output": {
                "service_provider_name": "John Doe",
                "service_provider_address": "2345 Green Street Los Angeles, CA",
                "material_service": [
                    {
                        "item": "Ice blue decorative stone (yd3)",
                        "price_rate": 59.0,
                        "quantity": 50,
                        "amount": 2950.0
                    },
                    {
                        "item": "Green slate decorative stone (yd3)",
                        "price_rate": 76.0,
                        "quantity": 60,
                        "amount": 4560.0
                    },
                    {
                        "item": "Brown pine mulch (yd3)",
                        "price_rate": 25.0,
                        "quantity": 60,
                        "amount": 1500.0
                    },
                    {
                        "item": "Mason sand (yd3)",
                        "price_rate": 28.0,
                        "quantity": 20,
                        "amount": 560.0
                    },
                    {
                        "item": "Athletic bland grass seed (25lb.)",
                        "price_rate": 75.0,
                        "quantity": 5,
                        "amount": 375.0
                    },
                    {
                        "item": "Labour type 1",
                        "price_rate": 30.0,
                        "quantity": 15,
                        "amount": 450.0
                    },
                    {
                        "item": "Labour type 2",
                        "price_rate": 25.0,
                        "quantity": 10,
                        "amount": 250.0
                    }
                ],
                "terms_and_conditions": "Please pay this invoice in 30 days",
                "bill_to_client_name": "Private Home",
                "bill_to_address": "2566 West Avenue Los Angeles, CA",
                "subtotal": 10645.0,
                "discount": 945.0,
                "invoice_number": "1500.225",
                "invoice_date": "2023-05-25",
                "payment_info": "Bank Name Here Acc: 2412 2000 5454 5152 Additional info here",
                "tax_rate": 10,
                "total": 10670.0,
                "additional_info": "This invoice was created as a result of the performance of works in the yard of your company's headquarters, all according to offer no. 2056/2023. The works included paving and greening of the courtyard in the total area of 245 square yards in the period 05/01/2023 to 05/15/2023."
            },
            "created_at": null,
            "upload_date": "2024-10-23T13:08:44",
            "process_date": "2024-10-23T00:00:00",
            "process_time": null,
            "assign_to": null,
            "feedback": null,
            "approval": null,
            "processed_file_name": null,
            "process_file_url": null,
            "pdf_status": null,
            "file_location_for_preview": null
        },
    ],
    "message": "File Information Fetched Successfully",
    "status_code": 200
}

export const bankstatementFileInfo: { status: boolean, data: FileDetails[], message: string, status_code: number } = {
    "status": true,
    "data": [
        {
            "id": 37,
            "file_original_name": "OLIVIA-APRIL-RBS.pdf",
            "file_url": "https://formrecognizer12345.blob.core.windows.net/form-recognizer/optira_documents/Demo/bankstatement/20241025_191124_OLIVIA-APRIL-RBS.pdf",
            "tag": "bankstatement",
            "blob_location": "optira_documents/Demo/bankstatement/20241025_191124_OLIVIA-APRIL-RBS.pdf",
            "processed": 1,
            "container_name": null,
            "useremail": "demo@thirdeyedata.ai",
            "output": {
                "BANK_NAME": "RBS The Royal Bank of Scotland",
                "STATEMENT_OF_ACCOUNT": "Statement Of Account",
                "CARD_NUMBER": "6543-78901-2345-5678",
                "ACCOUNT_NUMBER": "9876543210",
                "BRANCH_NAME": "Long Beach Harbor Branch",
                "ADDRESS": "789 Seaside Drive Apt 456 Long Beach CA 90802",
                "CUSTOMER_NAME": "Olivia Rodriguez",
                "PERIOD": "2023-04-30",
                "OPENING_BALANCE": "14076.54",
                "TOTAL_CREDIT": "18765.43",
                "TOTAL_DEBIT": "5432.10",
                "CLOSING_BALANCE": "19115.24",
                "TRANSACTION_DETAILS": [
                    {
                        "DATE": "2023-04-05",
                        "REFERENCE": "0202333",
                        "DESCRIPTION": "Direct Deposit",
                        "CREDIT": "4200.00",
                        "DEBIT": "",
                        "BALANCE": "14926.54"
                    },
                    {
                        "DATE": "2023-04-11",
                        "REFERENCE": "0314534",
                        "DESCRIPTION": "ATM Withdrawal",
                        "CREDIT": "",
                        "DEBIT": "120.00",
                        "BALANCE": "13846.54"
                    },
                    {
                        "DATE": "2023-04-15",
                        "REFERENCE": "0417635",
                        "DESCRIPTION": "Online Bill Payment",
                        "CREDIT": "",
                        "DEBIT": "80.00",
                        "BALANCE": "13626.54"
                    },
                    {
                        "DATE": "2023-04-19",
                        "REFERENCE": "0525436",
                        "DESCRIPTION": "Grocery Store Purchase",
                        "CREDIT": "",
                        "DEBIT": "200.00",
                        "BALANCE": "12826.54"
                    },
                    {
                        "DATE": "2023-04-20",
                        "REFERENCE": "0627637",
                        "DESCRIPTION": "Check Payment - Rent",
                        "CREDIT": "",
                        "DEBIT": "1000.00",
                        "BALANCE": "17326.54"
                    },
                    {
                        "DATE": "2023-04-21",
                        "REFERENCE": "0828738",
                        "DESCRIPTION": "Direct Deposit",
                        "CREDIT": "4500.00",
                        "DEBIT": "",
                        "BALANCE": "17308.04"
                    },
                    {
                        "DATE": "2023-04-28",
                        "REFERENCE": "0926539",
                        "DESCRIPTION": "Service Fee",
                        "CREDIT": "",
                        "DEBIT": "15.00",
                        "BALANCE": "19108.04"
                    },
                    {
                        "DATE": "2023-04-29",
                        "REFERENCE": "1032130",
                        "DESCRIPTION": "Online Transfer",
                        "CREDIT": "",
                        "DEBIT": "1800.00",
                        "BALANCE": "19115.24"
                    },
                    {
                        "DATE": "2023-04-30",
                        "REFERENCE": "1201131",
                        "DESCRIPTION": "Interest Adjustment",
                        "CREDIT": "7.20",
                        "DEBIT": "",
                        "BALANCE": "19115.24"
                    }
                ]
            },
            "created_at": null,
            "upload_date": "2024-10-20T13:11:28",
            "process_date": "2024-10-20T00:00:00",
            "process_time": null,
            "assign_to": null,
            "feedback": null,
            "approval": null,
            "processed_file_name": null,
            "process_file_url": null,
            "pdf_status": null,
            "file_location_for_preview": null
        },
        {
            "id": 38,
            "file_original_name": "OLIVIA-FAB-RBS.pdf",
            "file_url": "https://formrecognizer12345.blob.core.windows.net/form-recognizer/optira_documents/Demo/bankstatement/20241025_191124_OLIVIA-FAB-RBS.pdf",
            "tag": "bankstatement",
            "blob_location": "optira_documents/Demo/bankstatement/20241025_191124_OLIVIA-FAB-RBS.pdf",
            "processed": 1,
            "container_name": null,
            "useremail": "demo@thirdeyedata.ai",
            "output": {
                "BANK_NAME": "RBS The Royal Bank of Scotland",
                "STATEMENT_OF_ACCOUNT": "Statement Of Account",
                "CARD_NUMBER": "6543-78901-2345-5678",
                "ACCOUNT_NUMBER": "9876543210",
                "BRANCH_NAME": "Long Beach Harbor Branch",
                "ADDRESS": "789 Seaside Drive Apt 456 Long Beach CA 90802",
                "CUSTOMER_NAME": "Olivia Rodriguez",
                "OPENING_BALANCE": "12500.50",
                "TOTAL_CREDIT": "18432.10",
                "TOTAL_DEBIT": "5210.75",
                "CLOSING_BALANCE": "15536.25",
                "TRANSACTION_DETAILS": [
                    {
                        "DATE": "2023-02-01",
                        "REFERENCE": "0202233",
                        "DESCRIPTION": "Direct Deposit",
                        "CREDIT": "3500.00",
                        "DEBIT": "",
                        "BALANCE": "12150.50"
                    },
                    {
                        "DATE": "2023-02-11",
                        "REFERENCE": "0312234",
                        "DESCRIPTION": "ATM Withdrawal",
                        "CREDIT": "",
                        "DEBIT": "250.00",
                        "BALANCE": "12000.50"
                    },
                    {
                        "DATE": "2023-02-16",
                        "REFERENCE": "0412235",
                        "DESCRIPTION": "Online Bill Payment",
                        "CREDIT": "",
                        "DEBIT": "150.00",
                        "BALANCE": "11650.50"
                    },
                    {
                        "DATE": "2023-02-20",
                        "REFERENCE": "0522236",
                        "DESCRIPTION": "Grocery Store Purchase",
                        "CREDIT": "",
                        "DEBIT": "350.00",
                        "BALANCE": "9850.50"
                    },
                    {
                        "DATE": "2023-02-21",
                        "REFERENCE": "0622237",
                        "DESCRIPTION": "Check Payment - Rent",
                        "CREDIT": "",
                        "DEBIT": "1800.00",
                        "BALANCE": "14350.50"
                    },
                    {
                        "DATE": "2023-02-23",
                        "REFERENCE": "0822238",
                        "DESCRIPTION": "Direct Deposit",
                        "CREDIT": "4500.00",
                        "DEBIT": "",
                        "BALANCE": "15525.50"
                    },
                    {
                        "DATE": "2023-02-24",
                        "REFERENCE": "0922239",
                        "DESCRIPTION": "Service Fee",
                        "CREDIT": "",
                        "DEBIT": "25.00",
                        "BALANCE": "14325.50"
                    },
                    {
                        "DATE": "2023-02-26",
                        "REFERENCE": "1032230",
                        "DESCRIPTION": "Online Transfer",
                        "CREDIT": "",
                        "DEBIT": "1200.00",
                        "BALANCE": "12500.50"
                    },
                    {
                        "DATE": "2023-02-27",
                        "REFERENCE": "1202210",
                        "DESCRIPTION": "Interest Adjustment",
                        "CREDIT": "10.75",
                        "DEBIT": "",
                        "BALANCE": "15536.25"
                    }
                ]
            },
            "created_at": null,
            "upload_date": "2024-10-21T15:08:25",
            "process_date": "2024-10-21T00:00:00",
            "process_time": null,
            "assign_to": null,
            "feedback": null,
            "approval": null,
            "processed_file_name": null,
            "process_file_url": null,
            "pdf_status": null,
            "file_location_for_preview": null
        },
        {
            "id": 39,
            "file_original_name": "OLIVIA-MAR-RBS.pdf",
            "file_url": "https://formrecognizer12345.blob.core.windows.net/form-recognizer/optira_documents/Demo/bankstatement/20241025_191125_OLIVIA-MAR-RBS.pdf",
            "tag": "bankstatement",
            "blob_location": "optira_documents/Demo/bankstatement/20241025_191125_OLIVIA-MAR-RBS.pdf",
            "processed": 1,
            "container_name": null,
            "useremail": "demo@thirdeyedata.ai",
            "output": {
                "CARD_NUMBER": "6543-78901-2345-5678",
                "ACCOUNT_NUMBER": "9876543210",
                "BRANCH_NAME": "Long Beach Harbor Branch",
                "ADDRESS": "789 Seaside Drive Apt 456 Long Beach CA 90802",
                "CUSTOMER_NAME": "Olivia Rodriguez",
                "OPENING_BALANCE": "8567.89",
                "TOTAL_CREDIT": "14567.89",
                "TOTAL_DEBIT": "3210.98",
                "CLOSING_BALANCE": "13660.64",
                "TRANSACTION_DETAILS": [
                    {
                        "DATE": "2023-03-03",
                        "REFERENCE": "0202231",
                        "DESCRIPTION": "Direct Deposit",
                        "CREDIT": "3500.00",
                        "DEBIT": "",
                        "BALANCE": "8567.89"
                    },
                    {
                        "DATE": "2023-03-10",
                        "REFERENCE": "0312224",
                        "DESCRIPTION": "ATM Withdrawal",
                        "CREDIT": "",
                        "DEBIT": "180.00",
                        "BALANCE": "8447.89"
                    },
                    {
                        "DATE": "2023-03-16",
                        "REFERENCE": "0412215",
                        "DESCRIPTION": "Online Bill Payment",
                        "CREDIT": "",
                        "DEBIT": "120.00",
                        "BALANCE": "8367.89"
                    },
                    {
                        "DATE": "2023-03-20",
                        "REFERENCE": "0522336",
                        "DESCRIPTION": "Grocery Store Purchase",
                        "CREDIT": "",
                        "DEBIT": "200.00",
                        "BALANCE": "8167.89"
                    },
                    {
                        "DATE": "2023-03-21",
                        "REFERENCE": "0621137",
                        "DESCRIPTION": "Check Payment - Rent",
                        "CREDIT": "",
                        "DEBIT": "1000.00",
                        "BALANCE": "7167.89"
                    },
                    {
                        "DATE": "2023-03-24",
                        "REFERENCE": "0829838",
                        "DESCRIPTION": "Direct Deposit",
                        "CREDIT": "5000.00",
                        "DEBIT": "",
                        "BALANCE": "12167.89"
                    },
                    {
                        "DATE": "2023-03-28",
                        "REFERENCE": "0929839",
                        "DESCRIPTION": "Service Fee",
                        "CREDIT": "",
                        "DEBIT": "15.00",
                        "BALANCE": "12152.89"
                    },
                    {
                        "DATE": "2023-03-29",
                        "REFERENCE": "1030830",
                        "DESCRIPTION": "Online Transfer",
                        "CREDIT": "",
                        "DEBIT": "1500.00",
                        "BALANCE": "13652.89"
                    },
                    {
                        "DATE": "2023-03-31",
                        "REFERENCE": "1202231",
                        "DESCRIPTION": "Interest Adjustment",
                        "CREDIT": "7.75",
                        "DEBIT": "",
                        "BALANCE": "13660.64"
                    }
                ]
            },
            "created_at": null,
            "upload_date": "2024-10-22T16:11:29",
            "process_date": "2024-10-22T00:00:00",
            "process_time": null,
            "assign_to": null,
            "feedback": null,
            "approval": null,
            "processed_file_name": null,
            "process_file_url": null,
            "pdf_status": null,
            "file_location_for_preview": null
        },
        {
            "id": 40,
            "file_original_name": "RBS-APRIL.pdf",
            "file_url": "https://formrecognizer12345.blob.core.windows.net/form-recognizer/optira_documents/Demo/bankstatement/20241025_191125_RBS-APRIL.pdf",
            "tag": "bankstatement",
            "blob_location": "optira_documents/Demo/bankstatement/20241025_191125_RBS-APRIL.pdf",
            "processed": 1,
            "container_name": null,
            "useremail": "demo@thirdeyedata.ai",
            "output": {
                "BANK_NAME": "RBS The Royal Bank of Scotland",
                "STATEMENT_OF_ACCOUNT": "Statement Of Account",
                "BRANCH_NAME": "Santa Monica Beach Branch",
                "CUSTOMER_NAME": "Emily Rodriguez",
                "PERIOD": "2023-04-30",
                "CARD_NUMBER": "6789-1235-9876-5432",
                "ACCOUNT_NUMBER": "0987654321",
                "OPENING_BALANCE": "25430.00",
                "ADDRESS": "456 Seaview Drive Apt 210 Santa Monica CA 90401",
                "TOTAL_CREDIT": "8550.00",
                "TOTAL_DEBIT": "5700.00",
                "CLOSING_BALANCE": "32956.00",
                "TRANSACTION_DETAILS": [
                    {
                        "DATE": "2023-04-01",
                        "REFERENCE": "0334101",
                        "DESCRIPTION": "Account Interest",
                        "CREDIT": "12.50",
                        "DEBIT": "",
                        "BALANCE": "25430.00"
                    },
                    {
                        "DATE": "2023-04-05",
                        "REFERENCE": "0323102",
                        "DESCRIPTION": "Branch Deposit",
                        "CREDIT": "5000.00",
                        "DEBIT": "",
                        "BALANCE": "30430.00"
                    },
                    {
                        "DATE": "2023-04-10",
                        "REFERENCE": "0334103",
                        "DESCRIPTION": "Branch Deposit",
                        "CREDIT": "1550.00",
                        "DEBIT": "",
                        "BALANCE": "31980.00"
                    },
                    {
                        "DATE": "2023-04-15",
                        "REFERENCE": "0308704",
                        "DESCRIPTION": "Branch Cheque - Johnson Holdings",
                        "CREDIT": "",
                        "DEBIT": "5000.00",
                        "BALANCE": "26980.00"
                    },
                    {
                        "DATE": "2023-04-20",
                        "REFERENCE": "0309005",
                        "DESCRIPTION": "Branch Deposit",
                        "CREDIT": "4000.00",
                        "DEBIT": "",
                        "BALANCE": "30980.00"
                    },
                    {
                        "DATE": "2023-04-22",
                        "REFERENCE": "0302306",
                        "DESCRIPTION": "Cost of Bank Cheque",
                        "CREDIT": "",
                        "DEBIT": "20.00",
                        "BALANCE": "30960.00"
                    },
                    {
                        "DATE": "2023-04-25",
                        "REFERENCE": "0304507",
                        "DESCRIPTION": "Branch Deposit",
                        "CREDIT": "2000.00",
                        "DEBIT": "",
                        "BALANCE": "32960.00"
                    },
                    {
                        "DATE": "2023-04-28",
                        "REFERENCE": "0301308",
                        "DESCRIPTION": "FED Bank Account Debits Tax",
                        "CREDIT": "",
                        "DEBIT": "2.00",
                        "BALANCE": "32958.00"
                    },
                    {
                        "DATE": "2023-04-30",
                        "REFERENCE": "0301209",
                        "DESCRIPTION": "VIC FID Charge",
                        "CREDIT": "",
                        "DEBIT": "2.00",
                        "BALANCE": "32956.00"
                    }
                ]
            },
            "created_at": null,
            "upload_date": "2024-10-23T19:18:22",
            "process_date": "2024-10-23T00:00:00",
            "process_time": null,
            "assign_to": null,
            "feedback": null,
            "approval": null,
            "processed_file_name": null,
            "process_file_url": null,
            "pdf_status": null,
            "file_location_for_preview": null
        },
    ],
    "message": "File Information Fetched Successfully",
    "status_code": 200
}

export const handwrittenFileInfo: { status: boolean, data: FileDetails[], message: string, status_code: number } = {
    "status": true,
    "data": [
        {
            "id": 46,
            "file_original_name": "HealthForm.pdf",
            "file_url": "https://formrecognizer12345.blob.core.windows.net/form-recognizer/optira_documents/Demo/handwritten/20241025_191302_HealthForm.pdf",
            "tag": "handwritten",
            "blob_location": "optira_documents/Demo/handwritten/20241025_191302_HealthForm.pdf",
            "processed": 1,
            "container_name": null,
            "useremail": "demo@thirdeyedata.ai",
            "output": {
                "Date": "2019-09-14",
                "Name": "Sally Walker",
                "DOB": "1986-09-04",
                "Address": "24 Barney Lane",
                "City": "Toward",
                "State": "NJ",
                "Zip": "07082",
                "Email": "Sally.walker@Gmail.com",
                "Phone_Number": "9069173486",
                "Gender": "F",
                "Referred_By": "None",
                "Emergency_Contact": "Eva6",
                "Marital_Status": "Single",
                "Occupation": "Software Engineer",
                "Emergency_Contact_Phone": "9063343926",
                "Medical_Concerns": "Runny nose, micas in throat, weakness, aches, chills fired",
                "Current_Medication": "Vyvanse (25mg) daily for attention"
            },
            "created_at": null,
            "upload_date": "2024-10-20T19:13:03",
            "process_date": "2024-10-20T00:00:00",
            "process_time": null,
            "assign_to": null,
            "feedback": null,
            "approval": null,
            "processed_file_name": null,
            "process_file_url": null,
            "pdf_status": null,
            "file_location_for_preview": null
        },

        {
            "id": 47,
            "file_original_name": "DoctorPrescription1.pdf",
            "file_url": "https://formrecognizer12345.blob.core.windows.net/form-recognizer/optira_documents/demo/handwritten/20241121_164403_laxmi_prescription.pdf",
            "tag": "handwritten",
            "blob_location": "optira_documents/Demo/handwritten/20241025_191302_DoctorPrescription1.pdf",
            "processed": 1,
            "container_name": null,
            "useremail": "demo@thirdeyedata.ai",
            "output": {
                "Hospital_Name": "Bhagwan Mahavir Hospital",
                "Location": "Pitampura, Delhi, 110034",
                "Government": "Govt. Of NCT of Delhi",
                "Registration_Card_Type": "ACCIDENT & EMERGENCY REGISTRATION CARD",
                "Patient_Name": "LAXMI",
                "Relationship": "S/D/W of SH",
                "Sex": "Female",
                "Age": "52 Y/ 0 Mnt",
                "Date": "2021-07-13",
                "Time": "11 : 48 : 34 AM",
                "Emergency_Number": "84169",
                "MLC_Non_MLC": "MLC NO. UID NO",
                "Room": "ROOM",
                "Department": "Deptt.",
                "ICD_10_Code": "ICD-10 Code",
                "Address": "PITAM PURA",
                "Contact_Number": "9891596832",
                "Occupation": "Occupation s",
                "Religion": "Religion",
                "Marital_Status": "Married",
                "Referral": "Ref. from",
                "Provisional_Diagnosis": "Provisional Diagnosis Kjel0 HTN. c/o - paris abdomeno ac-fairs BP-110/80nunkg PR- 84 mb 2100 Ki dicho lamp in slat :- By-fautac 1amp un stat Review in ORD 102' \u00b7 LORS in 1 ( water usa 23/3/2021 - Cholecystitis c . T. Dicho 1 tab BD .T. Rantas , tab on BBR muco/ pyocele of 9B",
                "Additional_Information": "Print back 13/07/2021 11"
            },
            "created_at": null,
            "upload_date": "2024-10-21T15:13:03",
            "process_date": "2024-10-21T00:00:00",
            "process_time": null,
            "assign_to": null,
            "feedback": null,
            "approval": null,
            "processed_file_name": null,
            "process_file_url": null,
            "pdf_status": null,
            "file_location_for_preview": null
        },

        {
            "id": 48,
            "file_original_name": "DoctorPrescription2.pdf",
            "file_url": "https://formrecognizer12345.blob.core.windows.net/form-recognizer/optira_documents/anupam/handwritten/20241121_164402_prescription.pdf",
            "tag": "handwritten",
            "blob_location": "optira_documents/Demo/handwritten/20241025_191302_DoctorPrescription2.pdf",
            "processed": 1,
            "container_name": null,
            "useremail": "demo@thirdeyedata.ai",
            "output": {
                "Quality_Of_Care": "PATIENT SAFETY & NACH PRE ACCREDITED",
                "IL_CLI": "SA",
                "PAR": "NSCBCH",
                "NCRI": "SWAST",
                "Hospital_Name": "NETAJI SUBHAS CHANDRA BOSE CANCER HOSPITAL (A Unit of Himadri Memorial Cancer Welfare Trust)",
                "Address": "3081 Nayabad, Kolkata - 700 094, India",
                "Help_Desk": "(033) 71223000",
                "Mobile": "9874210003 / 9874210007",
                "Website": "www.nscri.in",

                "Patient_Name": "Dalia Kundu",
                "MR_No": "NCRI/21/1427",
                "Sex": "F",
                "Height_Weight": "1",
                "Consultant_Name": "Tanmoy Kumar Mandal",
                "Appointment_Date": "2021-02-10",
                "Age": "64",
                "BP_Pulse_Oxygen": "",
                "History_Symptoms": "MBC",
                "Clinical_Examination": "(8/5/3)",
                "Diagnosis": "(Bone /Kung /Liver)",
                "Medicine_Prescribed": "Plan :- - 6# (P+H) - Inj xgeva -alt # (120)",
                "Advice_Prescribed": "Adv ou Dis 1 CBC Adv 2 Give G (P+H) ou 10/2/21 3 RIS on 3/3/21 \u2103 CBC - HOW -TOS - Closeu-TDS",
                "Follow_Up_Date": "- T. Rexa (4)-BD D2-D4 for (2 - T.Ondan (4) - BDD2-D4 -T. Ultracet = BDx 5 days",


                "Additional_Information": "STODDARLE COST"
            },
            "created_at": null,
            "upload_date": "2024-10-22T15:12:45",
            "process_date": "2024-10-22T00:00:00",
            "process_time": null,
            "assign_to": null,
            "feedback": null,
            "approval": null,
            "processed_file_name": null,
            "process_file_url": null,
            "pdf_status": null,
            "file_location_for_preview": null
        },

    ],
    "message": "File Information Fetched Successfully",
    "status_code": 200
}


export const voucherFileInfo: { status: boolean, data: FileDetails[], message: string, status_code: number } = {
    "status": true,
    "data": [
        {
            "id": 46,
            "file_original_name": "gift_coupon1.pdf",
            "file_url": "https://formrecognizer12345.blob.core.windows.net/form-recognizer/optira_documents/Demo/voucher/giftVoucher1.pdf",
            "tag": "voucher",
            "blob_location": "optira_documents/Demo/voucher/20241025_191302_giftVoucher1.pdf",
            "processed": 1,
            "container_name": null,
            "useremail": "demo@thirdeyedata.ai",
            "output": {
                "title": "THREE NIGHT IN MALDIVES ISLAND",
                "type": "GIFT COUPON",
                "occasion": "HAPPY BIRTHDAY",
                "date": "AUGUST 2029",
                "value": "$3000",

                "start_date": "24 AUGUST 2029",
                "end_date": "26 AUGUST 2029",

                "message": "Have a wonderful birthday! We hope you enjoy your present! You deserve it!",

                "to": "Flavia",
                "from": "Mom and Dad"

            },
            "created_at": null,
            "upload_date": "2024-10-20T13:13:03",
            "process_date": "2024-10-20T00:00:00",
            "process_time": null,
            "assign_to": null,
            "feedback": null,
            "approval": null,
            "processed_file_name": null,
            "process_file_url": null,
            "pdf_status": null,
            "file_location_for_preview": null
        },

        {
            "id": 47,
            "file_original_name": "gift_coupon2.pdf",
            "file_url": "https://formrecognizer12345.blob.core.windows.net/form-recognizer/optira_documents/demo/voucher/giftVoucher2.pdf",
            "tag": "voucher",
            "blob_location": "optira_documents/Demo/voucher/giftVoucher2.pdf",
            "processed": 1,
            "container_name": null,
            "useremail": "demo@thirdeyedata.ai",
            "output": {
                "title": "10% off",
                "description": "Enjoy your dinner in ABC Restaurant",

                "start_date": "24 JANUARY 2022",
                "end_date": "31 JANUARY 2022",

                "location": "P 153/1, Scheme 7 M, C I T Road, Kankurgachi, Kolkata - 700054 (Hudco Junction)",
                "recipient_name": "JHON DEY"

            },
            "created_at": null,
            "upload_date": "2024-10-21T18:03:03",
            "process_date": "2024-10-21T00:00:00",
            "process_time": null,
            "assign_to": null,
            "feedback": null,
            "approval": null,
            "processed_file_name": null,
            "process_file_url": null,
            "pdf_status": null,
            "file_location_for_preview": null
        },

        {
            "id": 48,
            "file_original_name": "gift_coupon3.pdf",
            "file_url": "https://formrecognizer12345.blob.core.windows.net/form-recognizer/optira_documents/demo/voucher/giftVoucher3.pdf",
            "tag": "voucher",
            "blob_location": "optira_documents/Demo/voucher/giftVoucher3.pdf",
            "processed": 1,
            "container_name": null,
            "useremail": "demo@thirdeyedata.ai",
            "output": {

                "voucher_value": "$50",
                "ticket_code": "00-00-01",
                "store_address": "123 Anywhere St., Any City",
                "contact": "123-456-7890",
                "usage": "FOR USE IN-STORE ONLY",
                "start_date": "2025-12-05",
                "end_date": "2025-12-24",
                "store_name": "The Story Name"

            },
            "created_at": null,
            "upload_date": "2024-10-22T20:13:03",
            "process_date": "2024-10-22T00:00:00",
            "process_time": null,
            "assign_to": null,
            "feedback": null,
            "approval": null,
            "processed_file_name": null,
            "process_file_url": null,
            "pdf_status": null,
            "file_location_for_preview": null
        },

    ],
    "message": "File Information Fetched Successfully",
    "status_code": 200
}

export const receiptFileInfo: { status: boolean, data: FileDetails[], message: string, status_code: number } = {
    "status": true,
    "data": [
        {
            "id": 31,
            "file_original_name": "receipt1.pdf",
            "file_url": "https://formrecognizer12345.blob.core.windows.net/form-recognizer/optira_documents/Demo/receipt/receipt1.pdf",
            "tag": "receipt",
            "blob_location": "optira_documents/Demo/receipt/receipt1",
            "processed": 1,
            "container_name": null,
            "useremail": "demo@thirdeyedata.ai",
            "output": {
                "company_name": "Berghotel Grosse Scheidegg",
                "company_address": "3818 Grindelwald",
                "company_owner": "Familie R. Müller",
                "receipt_number": "4572",
                "date": "2007-07-30",
                "time": "13:29:17",
                "table": "7/01",
                "items": [
                    {
                        "name": "Latte Macchiato",
                        "units": 2,
                        "unit_price": 4.50,
                        "total_price": 9.00
                    },
                    {
                        "name": "Gloki",
                        "units": 1,
                        "unit_price": 5.00,
                        "total_price": 5.00
                    },
                    {
                        "name": "Schweinschnitzel",
                        "units": 1,
                        "unit_price": 22.00,
                        "total_price": 22.00
                    },
                    {
                        "name": "Chässpätzli",
                        "units": 1,
                        "unit_price": 18.50,
                        "total_price": 18.50
                    }
                ],
                "total": {
                    "currency": "CHF",
                    "amount": 54.50,
                    "tax_percentage": 7.6,
                    "tax_amount": 3.85,
                    "equivalent_in_euro": 36.33
                },
                "served_by": "Ursula",
                "contact": {
                    "phone": "033 853 67 16",
                    "fax": "033 853 67 19",
                    "email": "grossescheidegg@bluewin.ch"
                },
                "tax_number": "430 234"
            },
            "created_at": null,
            "upload_date": "2024-10-20T19:18:43",
            "process_date": "2024-10-20T00:00:00",
            "process_time": null,
            "assign_to": null,
            "feedback": null,
            "approval": null,
            "processed_file_name": null,
            "process_file_url": null,
            "pdf_status": null,
            "file_location_for_preview": null
        },
        {
            "id": 32,
            "file_original_name": "receipt2.pdf",
            "file_url": "https://formrecognizer12345.blob.core.windows.net/form-recognizer/optira_documents/Demo/invoice/receipt2.jpg",
            "tag": "invoice",
            "blob_location": "optira_documents/Demo/invoice/receipt2.jpg",
            "processed": 1,
            "container_name": null,
            "useremail": "demo@thirdeyedata.ai",
            "output": {
                "company_name": "The Sand Dollar",
                "company_address": "100 Chopin Plaza, Miami, FL 33131",
                "receipt_number": null,
                "date": "2019-06-13",
                "time": "11:29 AM",
                "items": [
                    {
                        "name": "SM Calamari",
                        "units": 1,
                        "unit_price": 11.98,
                        "total_price": 11.98
                    },
                    {
                        "name": "Lobster Roll",
                        "units": 1,
                        "unit_price": 25.00,
                        "total_price": 25.00
                    },
                    {
                        "name": "Kale Salad - Shrimp",
                        "units": 1,
                        "unit_price": 18.00,
                        "total_price": 18.00
                    },
                    {
                        "name": "Snapper Entree",
                        "units": 1,
                        "unit_price": 28.00,
                        "total_price": 28.00
                    }
                ],
                "total": {
                    "currency": "USD",
                    "amount": 82.98,
                    "payment_method": "AMEX 83911"
                },
                "served_by": null,
                "contact": null,
                "tax_number": null
            },
            "created_at": null,
            "upload_date": "2024-10-21T15:08:44",
            "process_date": "2024-10-21T00:00:00",
            "process_time": null,
            "assign_to": null,
            "feedback": null,
            "approval": null,
            "processed_file_name": null,
            "process_file_url": null,
            "pdf_status": null,
            "file_location_for_preview": null
        },
        {
            "id": 33,
            "file_original_name": "receipt3.pdf",
            "file_url": "https://formrecognizer12345.blob.core.windows.net/form-recognizer/optira_documents/Demo/receipt/20241025_190841_HVAC-Invoice-Template-TemplateLab.com_.pdf",
            "tag": "invoice",
            "blob_location": "optira_documents/Demo/invoice/receipt3.pdf",
            "processed": 1,
            "container_name": null,
            "useremail": "demo@thirdeyedata.ai",
            "output": {

                "restaurant": "Hotel Restaurant and Bar",
                "address": "1016 6th Ave, New York, NY",
                "phone": "650-309-1992",
                "date": "09/25/2020",
                "time": "12:54 PM",
                "terminal": "2",
                "items": [
                    {
                        "name": "Hendrick Gin & Tonic",
                        "quantity": 1,
                        "price": 10.50
                    },
                    {
                        "name": "Ginger Mule",
                        "quantity": 1,
                        "price": 9.50
                    },
                    {
                        "name": "Glass Camus Zin",
                        "quantity": 1,
                        "price": 24.00
                    },
                    {
                        "name": "Titos Vodka Soda",
                        "quantity": 1,
                        "price": 12.00
                    }
                ],
                "subtotal": 56.00,
                "tax": 4.76,
                "total": 60.76

            },
            "created_at": null,
            "upload_date": "2024-10-22T17:08:44",
            "process_date": "2024-10-22T00:00:00",
            "process_time": null,
            "assign_to": null,
            "feedback": null,
            "approval": null,
            "processed_file_name": null,
            "process_file_url": null,
            "pdf_status": null,
            "file_location_for_preview": null
        },

    ],
    "message": "File Information Fetched Successfully",
    "status_code": 200
}

export const faxFileInfo: { status: boolean, data: FileDetails[], message: string, status_code: number } = {
    "status": true,
    "data": [
        {
            "id": 31,
            "file_original_name": "fax_invoice1.pdf",
            "file_url": "https://formrecognizer12345.blob.core.windows.net/form-recognizer/optira_documents/Demo/fax/fax_invoice1.pdf",
            "tag": "fax",
            "blob_location": "optira_documents/Demo/fax/fax_invoice1",
            "processed": 1,
            "container_name": null,
            "useremail": "demo@thirdeyedata.ai",
            "output": {
                "invoice_number": "C99998",
                "invoice_date": "1/19/11",
                "customer_name": "ALEX MAIDEN",
                "customer_address": "14655 KENSINGTON RD., HAGLIEF CITY, WA 99999",
                "items": [
                    {
                        "quantity": 50,
                        "description": "1 TB 89891A REDUCER KA 899888 8\" TO 4\"",
                        "unit_price": 8.76,
                        "amount": 438.00
                    },
                    {
                        "quantity": 4,
                        "description": "4\" ABRASION BLOCK KA POWDERLOCK4 4\" POWDER LOCK",
                        "unit_price": 15.00,
                        "amount": 60.00
                    }
                ],
                "tax": 46.07,
                "total": 544.07
            },
            "created_at": null,
            "upload_date": "2024-10-25T19:08:43",
            "process_date": "2024-10-25T00:00:00",
            "process_time": null,
            "assign_to": null,
            "feedback": null,
            "approval": null,
            "processed_file_name": null,
            "process_file_url": null,
            "pdf_status": null,
            "file_location_for_preview": null
        },
        // {
        //     "id": 32,
        //     "file_original_name": "receipt2.pdf",
        //     "file_url": "https://formrecognizer12345.blob.core.windows.net/form-recognizer/optira_documents/Demo/invoice/receipt2.jpg",
        //     "tag": "invoice",
        //     "blob_location": "optira_documents/Demo/invoice/receipt2.jpg",
        //     "processed": 1,
        //     "container_name": null,
        //     "useremail": "demo@thirdeyedata.ai",
        //     "output": {
        //         "company_name": "The Sand Dollar",
        //         "company_address": "100 Chopin Plaza, Miami, FL 33131",
        //         "receipt_number": null,
        //         "date": "2019-06-13",
        //         "time": "11:29 AM",
        //         "items": [
        //             {
        //                 "name": "SM Calamari",
        //                 "units": 1,
        //                 "unit_price": 11.98,
        //                 "total_price": 11.98
        //             },
        //             {
        //                 "name": "Lobster Roll",
        //                 "units": 1,
        //                 "unit_price": 25.00,
        //                 "total_price": 25.00
        //             },
        //             {
        //                 "name": "Kale Salad - Shrimp",
        //                 "units": 1,
        //                 "unit_price": 18.00,
        //                 "total_price": 18.00
        //             },
        //             {
        //                 "name": "Snapper Entree",
        //                 "units": 1,
        //                 "unit_price": 28.00,
        //                 "total_price": 28.00
        //             }
        //         ],
        //         "total": {
        //             "currency": "USD",
        //             "amount": 82.98,
        //             "payment_method": "AMEX 83911"
        //         },
        //         "served_by": null,
        //         "contact": null,
        //         "tax_number": null
        //     },
        //     "created_at": null,
        //     "upload_date": "2024-11-25T19:08:44",
        //     "process_date": "2024-11-26T00:00:00",
        //     "process_time": null,
        //     "assign_to": null,
        //     "feedback": null,
        //     "approval": null,
        //     "processed_file_name": null,
        //     "process_file_url": null,
        //     "pdf_status": null,
        //     "file_location_for_preview": null
        // },
        // {
        //     "id": 33,
        //     "file_original_name": "receipt3.pdf",
        //     "file_url": "https://formrecognizer12345.blob.core.windows.net/form-recognizer/optira_documents/Demo/receipt/20241025_190841_HVAC-Invoice-Template-TemplateLab.com_.pdf",
        //     "tag": "invoice",
        //     "blob_location": "optira_documents/Demo/invoice/receipt3.pdf",
        //     "processed": 1,
        //     "container_name": null,
        //     "useremail": "demo@thirdeyedata.ai",
        //     "output": {

        //         "restaurant": "Hotel Restaurant and Bar",
        //         "address": "1016 6th Ave, New York, NY",
        //         "phone": "650-309-1992",
        //         "date": "09/25/2020",
        //         "time": "12:54 PM",
        //         "terminal": "2",
        //         "items": [
        //             {
        //                 "name": "Hendrick Gin & Tonic",
        //                 "quantity": 1,
        //                 "price": 10.50
        //             },
        //             {
        //                 "name": "Ginger Mule",
        //                 "quantity": 1,
        //                 "price": 9.50
        //             },
        //             {
        //                 "name": "Glass Camus Zin",
        //                 "quantity": 1,
        //                 "price": 24.00
        //             },
        //             {
        //                 "name": "Titos Vodka Soda",
        //                 "quantity": 1,
        //                 "price": 12.00
        //             }
        //         ],
        //         "subtotal": 56.00,
        //         "tax": 4.76,
        //         "total": 60.76

        //     },
        //     "created_at": null,
        //     "upload_date": "2024-02-22T19:08:44",
        //     "process_date": "2024-02-24T00:00:00",
        //     "process_time": null,
        //     "assign_to": null,
        //     "feedback": null,
        //     "approval": null,
        //     "processed_file_name": null,
        //     "process_file_url": null,
        //     "pdf_status": null,
        //     "file_location_for_preview": null
        // },

    ],
    "message": "File Information Fetched Successfully",
    "status_code": 200
}