import React, { useState } from 'react';
import { utils, writeFile } from "xlsx";
import { KTIcon } from '../../../_metronic/helpers';

interface ButtonRowProps {
    resultNew: Record<string, any>;
    responseJson: any; // Define type for responseJson as needed
    setResultView: React.Dispatch<React.SetStateAction<string>>;
    resultView: string | undefined; // Update resultView type
    tables: Array<Array<MyObject>>
    toggleState: () => void;
    preview: boolean;
    tab: string;
    // Add more props as needed
}

interface MyObject {
    [key: string]: string[];
};


export const ButtonRow: React.FC<ButtonRowProps> = ({
    tables,
    preview,
    resultNew,
    responseJson,
    setResultView,
    resultView,
    toggleState,
    tab
}) => {
    const [closeModal, setCloseModal] = useState<boolean>(false);
    const [exportFormat, setExportFormat] = useState('')

    const handleToggle = () => {
        toggleState(); // Toggle the state in the parent component
    };

    const handleExport = () => {
        if (exportFormat == 'snowflake') {
            // // api call
            // setTimeout(() => {
            //     alert("Data successfully inserted into snowflake")
            // }, 2000);
        } else if (exportFormat == 'mysql') {
            alert("This feature not Supported in Trial Plan")
        } else if (exportFormat == 'crm') {
            alert("This feature not Supported in Trial Plan")
        } else if (exportFormat == 'erp') {
            alert("This feature not Supported in Trial Plan")
        } else {
            alert("Please select valid Database")
        }

    }

    const handleBulkProcess = () => {
        alert("Bulk Processing is not Supported in Trial Plan")
    }

    return (
        <div className="row">
            <div
                className="d-flex justify-content-between my-1"
                data-kt-user-table-toolbar="base"
            >
                <div className="px-5">
                    {
                        !preview &&
                        <>
                            <button
                                type="button"
                                className="btn btn-primary me-3 my-3 w-180px "
                                // data-bs-toggle="modal"
                                // data-bs-target="#kt_modal_assosiate_student"
                                onClick={(e) => {
                                    alert("This feature is unavailable in the demo version")
                                    return
                                }
                                }
                            >
                                <KTIcon iconName="plus" className="fs-3" />
                                Upload Document

                            </button>

                            <button type="button"
                                className="btn btn-info me-3 my-3 w-180px "
                                // data-bs-toggle="modal"
                                onClick={(e) => {
                                    alert("This feature is unavailable in the demo version.")
                                    return
                                }}
                            >
                                Import From External Source
                            </button>
                        </>
                    }
                    {
                        preview && <button
                            type="button"
                            className="btn btn-primary me-3 my-3 w-180px "
                            onClick={handleToggle}
                        >
                            Back to Document Library View
                        </button>
                    }
                </div>
                <div>
                    {tab != "processed" &&
                        <button
                            type="button"
                            className="btn btn-primary me-3 my-3 w-200px"
                            onClick={(e) => {
                                alert("This feature is unavailable in the demo version.")
                                return
                            }}
                        >
                            <p className="fs-1 fw-bolder pb-0 mb-0">
                                <KTIcon iconName="gear" className="fs-1" /> Process
                            </p>
                        </button>
                    }

                    {(preview && Object.keys(resultNew).length !== 0) && <>
                        {
                            responseJson && <>
                                {
                                    resultView == "default" ?
                                        <button
                                            type="button"
                                            className="btn btn-primary me-3 my-3 w-180px"
                                            onClick={() => setResultView("json")}
                                        >
                                            <KTIcon iconName="data" className="fs-1" />
                                            JSON Result
                                        </button> :
                                        <button
                                            type="button"
                                            className="btn btn-primary me-3 my-3 w-180px"
                                            onClick={() => setResultView("default")}
                                        >
                                            <KTIcon iconName="menu" className="fs-1" />
                                            Table Result
                                        </button>
                                }
                            </>
                        }
                    </>}
                    {/* <button
                        type="button"
                        className='btn btn-primary me-3 my-3 w-200px'
                        data-kt-menu-trigger="click"
                        data-kt-menu-placement="bottom-end"
                    >

                        <KTIcon iconName='arrow-down' className='fs-2' /> Download
                    </button> */}

                    <div
                        className="menu menu-sub menu-sub-dropdown w-200px w-md-180px"
                        data-kt-menu="true"
                    >
                        <div className="px-7 py-5" data-kt-user-table-filter="form">
                            <div className="mb-10">
                                <label className="form-label fs-6 fw-bold">Database:</label>
                                <select
                                    className="form-select form-select-solid fw-bolder"
                                    data-kt-select2="true"
                                    data-placeholder="Select option"
                                    data-allow-clear="true"
                                    data-kt-user-table-filter="role"
                                    data-hide-search="true"
                                    value={exportFormat}
                                    onChange={(e) => {
                                        setExportFormat(e.target.value);
                                    }}
                                >
                                    <option value="mysql">MySql</option>
                                    <option value="erp">ERP</option>
                                    <option value="crm">CRM</option>
                                    {/* <option value="txt">TXT</option> */}
                                </select>
                            </div>
                            <div className="d-flex justify-content-end">
                                <button
                                    type="button"
                                    className="btn btn-success fw-bold px-6"
                                    data-kt-menu-dismiss="true"
                                    data-kt-user-table-filter="filter"
                                    onClick={handleExport}
                                >
                                    Confirm
                                </button>
                            </div>
                        </div>
                    </div>
                    <button
                        type="button"
                        className='btn btn-primary me-3 my-3 w-200px'
                        // data-kt-menu-trigger="click"
                        // data-kt-menu-placement="bottom-end"
                        onClick={(e) => {
                            alert("This feature is unavailable in the demo version.")
                            return
                        }}
                    >

                        <KTIcon iconName='exit-up' className='fs-2' /> Export
                    </button>
                    {/* {Object.keys(result).length === 0 ? null : ( */}
                    <button
                        className="btn btn-success me-3 my-3 w-150px"
                         id="kt_drawer_chat_toggle"
                        // onClick={(e) => {
                        //     alert("This feature is unavailable in the demo version.")
                        //     return
                        // }}
                    >
                        <KTIcon iconName="message-text-2" className="fs-1" />
                        Chat
                    </button>
                    {/* )} */}
                </div>
            </div>
        </div>
    );
};

